import React, {useRef, useState} from "react"
import {useParams} from "react-router-dom"
import {Box, fade} from "@material-ui/core"
import {CommentOutlined, AssignmentOutlined} from "@material-ui/icons"
import styled from "styled-components"

import Layout from "../../Layout"
import CarParkHeader from "../CarParkHeader"
import CarParkPageInsightCard from "../CarParkPageInsightCard"
import {Loader} from "../../../components/Loader"
import EmissionsProducedGraph from "../Graphs/emissions/graph"
import AirPollutionGraph from "../Graphs/pollution/graph"
import useCarParkData from "../useCarParkData"
import CarParkHighlights from "../CarParkHighlights/CarParkHighlights"
import theme, {COLORS} from "../../../styles/theme"
import DateSelector from "../../../components/DateSelector/DateSelector"
import {
  GraphAreaContainer as Container,
  GraphRow as Row,
  GraphColumn as Column,
} from "../../../components/graphs/util/containers"
import {mockAirIndexData, toxicEmissionsData, emissionsSaved} from "../mockData"
import {
  BubbleChart,
  HorizontalBarChart,
  RichTooltip,
} from "../../../components/graphs"
import GraphContainer from "../../../components/graphs/common/GraphContainer"
import AirIndexGraph from "./AirIndexGraph/AirIndexGraph"
import moment from "moment"

const InsightsContainer = styled.div`
  display: flex;
  width: 100%;
  & > div {
    flex-basis: 0;
    flex-grow: 1;
    margin: 10px;
  }
`

const colors = [
  "#225ee8",
  "#9c9cea",
  "#4ED9DE",
  "#6665dd",
  "#7ea1f1",
  "#4679ec",
]

const EmissionsSaved: React.FC = () => {
  const tooltipRef = useRef()

  return (
    <GraphContainer
      info="This graph displays the number of vehicles that have entered the car park for a selected date."
      lastUpdated={new Date().toISOString()}
      title="Emissions Saved"
    >
      <RichTooltip ref={tooltipRef} />
      <HorizontalBarChart
        data={emissionsSaved}
        options={{
          legend: [
            {colour: COLORS.blue, text: "Today", type: "dot"},
            {colour: COLORS.aqua, text: "Now", type: "dot"},
            {
              colour: fade("#000", 0.25),
              text: "Last month",
              type: "dot",
            },
          ],
          getX1: (d) => d.today,
          getX2: (d) => d.lastMonth,
          margin: {bottom: 60, left: 80, right: 10, top: 20},
          setTooltipValue: (d) => ({
            rows: [
              {text: moment(new Date()).format("MMM, D YYYY"), icon: "date"},
              {text: moment(d.hourOfDay, "H").format("hA"), icon: "time"},
              {text: `${d.today}% emissions saved`, icon: "eco"},
            ],
            trend: {
              adjustment: 5,
              since: "last week",
            },
          }),
          shouldHighlightBarFunction: (d) =>
            d.hourOfDay === new Date().getHours(),
          skinnyBars: true,
          tooltipRef,
          xAxisLabel: "Percentage (%)",
          yAxisLabel: "Time",
        }}
      />
    </GraphContainer>
  )
}

export default function CarParkPageSustainability() {
  const {carParkId} = useParams()
  const [selectedDate] = useState(new Date())
  const {loading, data} = useCarParkData({selectedDate, carParkId})
  const airIndexTooltipRef = useRef()

  if (loading || !data) return <Loader />

  const {carpark} = data

  return (
    <Layout fixedWidth>
      <CarParkHeader carParkId={carParkId} currentPage="sustainability" />
      <CarParkHighlights
        image={carpark.image}
        address={carpark.siteName}
        capacity={carpark.capacity}
        currentUsage={(carpark.currentOccupancy / carpark.capacity) * 100}
        lastUpdatedAt={new Date().toISOString()}
        name={carpark.carParkName}
      />
      <InsightsContainer>
        <CarParkPageInsightCard
          icon={AssignmentOutlined}
          text="Hey, So far today today you've received 20% less visitors than your
        average over the last 60 days. However, people are staying for 35%
        longer."
          title="Performance summary"
        />
        <CarParkPageInsightCard
          icon={CommentOutlined}
          text="Did you know 610 electric vehicles have visited your car park in the last 60 days. You may want to
            consider installing an electric vehicle charging point."
          title="NexusRecommends®"
        />
      </InsightsContainer>
      <Box
        alignItems="center"
        bgcolor={theme.palette.secondary.light}
        border={`1px solid ${theme.palette.border.main}`}
        display="flex"
        justifyContent="center"
        p={7}
      >
        <DateSelector currentDate={new Date()} />
      </Box>
      <Box pt={5}>
        <Container>
          <Row>
            <Column>
              <GraphContainer
                info="This graph displays the number of vehicles that have entered the car park for a selected date."
                lastUpdated={new Date().toISOString()}
                title="Emissions Produced"
              >
                <EmissionsProducedGraph />
              </GraphContainer>
            </Column>
            <Column>
              <GraphContainer
                info="This graph displays the number of vehicles that have entered the car park for a selected date."
                lastUpdated={new Date().toISOString()}
                title="Air Pollution"
              >
                <AirPollutionGraph />
              </GraphContainer>
            </Column>
          </Row>
          <Row height={450}>
            <Column>
              <EmissionsSaved />
            </Column>
            <Column>
              <GraphContainer
                info="This graph displays the number of vehicles that have entered the car park for a selected date."
                lastUpdated={new Date().toISOString()}
                title="Air Index"
              >
                <RichTooltip ref={airIndexTooltipRef} />
                <AirIndexGraph
                  tooltipRef={airIndexTooltipRef}
                  airIndex={mockAirIndexData}
                />
              </GraphContainer>
            </Column>

          </Row>
          <Row height={500}>
            <Column>
              <GraphContainer
                info="This graph displays the number of vehicles that have entered the car park for a selected date."
                lastUpdated={new Date().toISOString()}
                title="Emissions Standards"
              >
                <BubbleChart
                  data={toxicEmissionsData}
                  options={{
                    colors,
                    padding: -20,
                    getRadius: (d: any) => d.value,
                    background: "transparent",
                    fontSizeScale: 8.5,
                  }}
                />
              </GraphContainer>
            </Column>
            <Column></Column>
          </Row>
        </Container>
      </Box>
    </Layout>
  )
}

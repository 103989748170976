import React from "react"
import {Box} from "@material-ui/core"

import Layout from "../Layout"

const ChartsPage = () => (
  <Layout currentPage="Charts">
    <Box />
  </Layout>
)

export default ChartsPage

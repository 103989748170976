import React from "react"
import styled from "styled-components"
import {Box, Tooltip, Typography} from "@material-ui/core"
import {InfoOutlined} from "@material-ui/icons"

import LiveDataBadge from "../../LiveDataBadge/LiveDataBadge"
import theme from "../../../styles/theme"

export const GraphWrapper = styled.div<{padding: string}>`
  box-sizing: border-box;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 18px 20px ${(props) => props.padding} 20px;
`

const InfoTooltip: React.FC<{text: string}> = ({text}) => (
  <Tooltip placement="top" title={text}>
    <InfoOutlined
      htmlColor={theme.palette.text.secondary}
      style={{
        cursor: "pointer",
        display: "inline-flex",
        verticalAlign: "middle",
      }}
    />
  </Tooltip>
)

const GraphContainer: React.FC<{
  info?: string
  lastUpdated: string
  title: string
  vehicle?: boolean
}> = ({children, info, lastUpdated, title, vehicle}) => (
  <GraphWrapper padding={vehicle ? "0px" : "18px"}>
    <Box display="flex" justifyContent="space-between">
      <Typography
        color="textPrimary"
        component="h2"
        variant="h4"
        style={!vehicle ? {marginBottom: theme.spacing(5)} : null}
      >
        {title} {info && <InfoTooltip text={info} />}
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <LiveDataBadge lastUpdatedAt={lastUpdated} />
      </Box>
    </Box>
    <Box flexGrow={1}>{children}</Box>
  </GraphWrapper>
)

export default GraphContainer

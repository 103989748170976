import {createStyles, makeStyles, Theme} from "@material-ui/core"
import {SidebarProps} from "./Sidebar"

export default makeStyles((theme: Theme) =>
  createStyles({
    anchor: {
      color: "inherit",
      "&:hover": {
        textDecoration: "none",
      },
    },
    drawer: {
      backgroundColor: theme.palette.primary.main,
      width: ({drawerWidth}) => drawerWidth,
    },
    drawerRoot: {
      textAlign: "center",
      transition: "width 0.1s",
      width: ({drawerWidth, drawerOpen, closedDrawerWidth}: SidebarProps) =>
        drawerOpen ? drawerWidth : closedDrawerWidth,
    },
    listItem: {
      marginBottom: theme.spacing(3),
    },
    listItemIcon: {
      justifyContent: "center",
      width: "100%",
    },
    listItemSelected: {
      borderLeft: `${theme.spacing(1)}px ${theme.palette.info.main} solid`,
      paddingLeft: theme.spacing(3),
      backgroundColor: theme.palette.primary.main,
    },
    logoContainer: {
      padding: theme.spacing(5),
    },
  })
)

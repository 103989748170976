import {createStyles, makeStyles, Theme} from "@material-ui/core"

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderBottom: `1px solid ${theme.palette.border.main}`,
      display: "flex",
      padding: theme.spacing(8, 4),
      width: "100%",
      alignItems: "center",
      boxSizing: "border-box",
      textDecoration: "none",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2, 6),
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    inlineIcon: {
      display: "inline-flex",
      verticalAlign: "middle",
    },
    image: {
      backgroundColor: "black",
      borderRadius: "50%",
      height: theme.spacing(14),
      minWidth: theme.spacing(14),
    },
    statistics: {
      alignItems: "flex-end",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    statItem: {
      alignItems: "center",
      display: "flex",
      padding: theme.spacing(1, 0, 1, 0),
    },
  })
)

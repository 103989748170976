import React, {useState} from "react"
import {useParams} from "react-router-dom"
import {Box} from "@material-ui/core"
import {CommentOutlined, AssignmentOutlined} from "@material-ui/icons"

import Layout from "../../Layout"
import CarParkHeader from "../CarParkHeader"
import useCarParkData from "../useCarParkData"
import {Loader} from "../../../components/Loader"
import CarParkHighlights from "../CarParkHighlights/CarParkHighlights"
import DateSelector from "../../../components/DateSelector/DateSelector"
import theme from "../../../styles/theme"
import CarParkPageInsightCard from "../CarParkPageInsightCard"
import {
  vehicleFuelTypeData,
  vehicleAgeData,
  engineSizeData,
  colourData,
  vehicleTypeData,
} from "../mockData"
import {BubbleChart} from "../../../components/graphs"
import {
  GraphArea,
  GridBoxContainer,
  GridBoxContent,
  InsightsContainer,
} from "./CarParkPageVehicles.styles"
import GraphContainer from "../../../components/graphs/common/GraphContainer"
import {
  EngineSizeChart,
  VehicleAgeChart,
  VehicleColourChart,
  VehicleMakeChart,
  VehicleModelChart,
} from "./graphs"

const GridBox: React.FC<{
  gridArea: string
  aspectRatio?: number
  borders?: {top?: boolean; bottom?: boolean; left?: boolean; right?: boolean}
}> = ({gridArea, aspectRatio = 7 / 8, children, borders}) => {
  return (
    <GridBoxContainer borders={borders} gridArea={gridArea}>
      <Box mt={`${aspectRatio * 100}%`} />
      <GridBoxContent>
        <Box flexGrow={1}>{children}</Box>
      </GridBoxContent>
    </GridBoxContainer>
  )
}

export default function CarParkPageVehicles() {
  const {carParkId} = useParams<{carParkId: string}>()
  const [selectedDate] = useState(new Date())
  const {loading, data} = useCarParkData({selectedDate, carParkId})

  if (loading || !data) {
    return (
      <React.Fragment>
        <Loader />
      </React.Fragment>
    )
  }

  const {breakdown, carpark} = data

  // const hybridTotal = breakdown.vehicles.fuel.reduce(function (
  //   previousValue: any,
  //   currentValue: any
  // ): any {
  //   return currentValue.value < 10000
  //     ? previousValue + currentValue.value
  //     : null
  // },
  // 0)

  // const fuel = {
  //   children: breakdown.vehicles.fuel
  //     .map(({key, value}: any) => {
  //       return value > 10000
  //         ? {
  //             label: key,
  //             value,
  //           }
  //         : {
  //             label: "Hybrid",
  //             value: hybridTotal,
  //           }
  //     })
  //     .slice(0, 3),
  //   label: "",
  //   value: 0,
  // }

  const bubbleChartColors = [
    "#225ee8",
    "#ffca2e",
    "#4ed9de",
    "#6665dd",
    "#adabed",
    "#7ea1f1",
  ]

  const make = breakdown.vehicles.make.map(({key, value}: any, i: number) => ({
    id: i,
    label: key,
    value,
  }))

  const model = breakdown.vehicles.model.map(
    ({key, value}: any, i: number) => ({
      id: i,
      label: key,
      today: value,
      lastMonth: (value * 5) / 4,
    })
  )
  // const type = breakdown.vehicles.type.map(({key, value}: any, i: number) => ({
  //   id: i,
  //   label: key,
  //   value,
  // }))

  return (
    <Layout fixedWidth>
      <CarParkHeader carParkId={carParkId} currentPage="vehicles" />
      <CarParkHighlights
        image={carpark.image}
        address={carpark.siteName}
        capacity={carpark.capacity}
        currentUsage={(carpark.currentOccupancy / carpark.capacity) * 100}
        lastUpdatedAt={new Date().toISOString()}
        name={carpark.carParkName}
      />
      <InsightsContainer>
        <CarParkPageInsightCard
          icon={AssignmentOutlined}
          text="Hey, So far today today you've received 20% less visitors than your
        average over the last 60 days. However, people are staying for 35%
        longer."
          title="Performance summary"
        />
        <CarParkPageInsightCard
          icon={CommentOutlined}
          text="Did you know 610 electric vehicles have visited your car park in the last 60 days. You may want to
            consider installing an electric vehicle charging point."
          title="NexusRecommends®"
        />
      </InsightsContainer>
      <Box
        alignItems="center"
        bgcolor={theme.palette.secondary.light}
        border={`1px solid ${theme.palette.border.main}`}
        display="flex"
        justifyContent="center"
        p={7}
      >
        <DateSelector currentDate={new Date()} />
      </Box>
      <GraphArea>
        <GridBox gridArea="brand" borders={{right: true, bottom: true}}>
          <VehicleMakeChart data={make} />
        </GridBox>
        <GridBox gridArea="model" borders={{bottom: true}}>
          <GraphContainer
            info="This graph displays the number of vehicles that have entered the car park for a selected date."
            lastUpdated={new Date().toISOString()}
            title="Vehicle Model"
          >
            <VehicleModelChart data={model.slice(0, 10)} />
          </GraphContainer>
        </GridBox>
        <GridBox gridArea="vehicleAge" borders={{right: true, bottom: true}}>
          <VehicleAgeChart
            innerData={vehicleAgeData}
            outerData={vehicleAgeData}
          />
        </GridBox>
        <GridBox gridArea="engineSize" borders={{right: true, bottom: true}}>
          <EngineSizeChart
            innerData={engineSizeData.slice(0, 5)}
            outerData={engineSizeData.slice(0, 3)}
          />
        </GridBox>
        <GridBox gridArea="color">
          <GraphContainer
            info="This graph displays the number of vehicles that have entered the car park for a selected date."
            lastUpdated={new Date().toISOString()}
            title="Colour"
          >
            <VehicleColourChart data={colourData} />
          </GraphContainer>
        </GridBox>
        <GridBox gridArea="vehicleType" borders={{right: true}}>
          <GraphContainer
            info="This graph displays the number of vehicles that have entered the car park for a selected date."
            lastUpdated={new Date().toISOString()}
            title="Vehicle Type"
          >
            <BubbleChart
              data={vehicleTypeData}
              options={{
                colors: bubbleChartColors,
                padding: -30,
                getRadius: (d: any) => d.value,
                background: "transparent",
                vehicle: true,
                fontSizeScale: 17,
              }}
            />
          </GraphContainer>
        </GridBox>
        <GridBox gridArea="fuelType" borders={{right: true}}>
          <GraphContainer
            info="This graph displays the number of vehicles that have entered the car park for a selected date."
            lastUpdated={new Date().toISOString()}
            title="Fuel Type"
            vehicle
          >
            <BubbleChart
              data={vehicleFuelTypeData}
              options={{
                colors: bubbleChartColors,
                padding: -10,
                getRadius: (d: any) => d.value,
                background: "transparent",
                vehicle: true,
                fontSizeScale: 8.5,
              }}
            />
          </GraphContainer>
        </GridBox>
      </GraphArea>
    </Layout>
  )
}

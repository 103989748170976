import * as d3 from "d3"
import {DoubleDountChartOptions} from "../types"
import {getTotal} from "../util/functions"
import moment from "moment"

const drawDonutChart = (
  selection: any,
  data: {inner: any; outer: any},
  container: {width: number; height: number},
  options: DoubleDountChartOptions
) => {
  selection.selectAll("*").remove()

  const offset = options.compact ? 40 : 65
  const width = container.width
  const height = container.height - offset

  const radius = height / 2.4
  const outerRadius = height / 2

  const donutWidthValue = options.donutWidth //This is the size of the hole in the middle
  const domainValue = options.domain

  const color = d3
    .scaleLinear<string>()
    .domain(domainValue)
    .range(["#6665dd", "#adabed", "#346aea"])
    .interpolate(d3.interpolateRgb)

  const colorGrey = d3
    .scaleLinear<string>()
    .domain(domainValue)
    .range(["#5D5D5D", "#C5C5C5", "#F1F1F1"])

  const innerPie = d3
    .pie()
    .sort(null)
    .value((d: any) => d.value)

  const outerPie = d3
    .pie()
    .sort(null)
    .value((d: any) => d.value)

  const innerArc = d3
    .arc()
    .innerRadius(donutWidthValue)
    .outerRadius(radius + (options.compact ? 5 : 20))

  const outerArc = d3
    .arc()
    .innerRadius(radius + donutWidthValue)
    .outerRadius(outerRadius)

  const angle = (d: any) => {
    var a = ((d.startAngle + d.endAngle) * 90) / Math.PI - 90
    return a > 90 ? a - 180 : a
  }

  const outerAngle = (d: any) => {
    return ((d.startAngle + d.endAngle) * 90) / Math.PI
  }

  //------------------------------------------------------------------------------------------------------------------
  const innerArcs = selection
    .selectAll("g.inner-slice")
    // Associate the generated pie data (an array of arcs, each having startAngle,
    // endAngle and value properties)
    .data(innerPie(data.inner))
    // This will create <g> elements for every "extra" data element that should be associated
    // with a selection. The result is creating a <g> for every object in the data array
    .enter()
    // Create a group to hold each slice (we will have a <path> and a <text>
    // element associated with each slice)
    .append("svg:g")
    .attr("class", "inner-slice")
    .attr(
      "transform",
      "translate(" +
        width / 2 +
        "," +
        (height / 2 + (options.compact ? 20 : 40)) +
        ")"
    )

  innerArcs
    .append("svg:path")
    //set the color for each slice to be chosen from the color function defined above
    .attr("fill", (d: any, i: any) => color(i))
    //this creates the actual SVG path using the associated data (pie) with the arc drawing function
    .attr("d", innerArc)
  innerArcs
    .append("svg:path")
    //set the color for each slice to be chosen from the color function defined above
    .attr("fill", (d: any, i: any) => color(i))
    //this creates the actual SVG path using the associated data (pie) with the arc drawing function
    .attr("d", innerArc)
    .on("mouseover", (a, i, el) => {
      outerArcs
        .selectAll("g.outer-slice path")
        .filter(function (d, i) {
          return d.data.label === a.data.label
        })
        .attr("fill", color(i))

      if (!options || !options.tooltipRef) return

      const containerXY = selection.node().getBoundingClientRect()
      const rectXY = (el[i] as Element).getBoundingClientRect()
      options.tooltipRef.current.setData({
        shouldShow: true,
        value: options?.setInnerTooltipValue && options.setInnerTooltipValue(a),
        x: rectXY.x - containerXY.x + rectXY.width / 2 - 15,
        y: rectXY.y - containerXY.y + rectXY.height / 2 + 10,
      })
    })
    .on("mouseout", (a) => {
      outerArcs
        .selectAll("g.outer-slice path")
        .filter(function (d, i) {
          return d.data.label === a.data.label
        })
        .attr("fill", function () {
          return this.getAttribute("data-original-fill")
        })

      if (!options || !options.tooltipRef) return
      options.tooltipRef.current.setData({shouldShow: false})
    })

  const innerText = innerArcs
    .append("svg:text")
    .attr("dy", ".35em")
    .attr("text-anchor", "middle")
    .attr("transform", function (d: any) {
      //set the label's origin to the center of the arc
      //we have to make sure to set these before calling arc.centroid
      d.outerRadius = radius // Set Outer Coordinate
      d.innerRadius = radius / 2 // Set Inner Coordinate
      return (
        "translate(" +
        innerArc.centroid(d) +
        ")rotate(" +
        ((options.labels || {}).rotateInnerLabels ? outerAngle(d) : angle(d)) +
        ")"
      )
    })
    .style("fill", "White")
    .style("font", "13px")
    .text(function (d: any) {
      return options.percentages
        ? d.data.label +
            " " +
            Math.round((d.data.value / getTotal(data.inner)) * 100) +
            "%"
        : d.data.label
    })

  options.labels &&
    innerText
      .append("svg:tspan")
      .text(options.labels.age ? " yr." : options.labels.size ? " L" : "")

  //------------------------------------------------------------------------------------------------------------------
  const outerArcs = selection
    .selectAll("g.outer-slice")
    .data(outerPie(data.outer))
    .enter()
    .append("svg:g")
    .attr("class", "outer-slice")
    .attr(
      "transform",
      "translate(" +
        width / 2 +
        "," +
        (height / 2 + (options.compact ? 20 : 40)) +
        ")"
    )

  outerArcs
    .append("svg:path")
    //set the color for each slice to be chosen from the color function defined above
    .attr("fill", (d: any, i: any) => colorGrey(i))
    .attr("data-original-fill", (d: any, i: any) => colorGrey(i))
    //this creates the actual SVG path using the associated data (pie) with the arc drawing function
    .attr("d", outerArc)
    .on("mouseover", (d, i, el) => {
      if (!options || !options.tooltipRef) return

      const containerXY = selection.node().getBoundingClientRect()
      const rectXY = (el[i] as Element).getBoundingClientRect()
      options.tooltipRef.current.setData({
        shouldShow: true,
        value: options?.setOuterTooltipValue && options.setOuterTooltipValue(d),
        x:
          rectXY.x -
          containerXY.x +
          rectXY.width / 2 -
          (options?.compact ? 0 : 10),
        y:
          rectXY.y -
          containerXY.y +
          rectXY.height +
          (options?.compact ? 30 : 0),
      })
    })
    .on("mouseout", () => {
      if (!options || !options.tooltipRef) return
      options.tooltipRef.current.setData({shouldShow: false})
    })

  const outerText = outerArcs
    .append("svg:text")
    .attr("dy", ".35em")
    .attr("text-anchor", "middle")
    .attr("transform", function (d: any) {
      //set the label's origin to the center of the arc
      //we have to make sure to set these before calling arc.centroid
      d.outerRadius = radius // Set Outer Coordinate
      d.innerRadius = radius / 2 // Set Inner Coordinate
      return (
        "translate(" + outerArc.centroid(d) + ")rotate(" + outerAngle(d) + ")"
      )
    })
    .style("fill", "#fff")
    .style("font", "13px")
    .text(function (d: any) {
      return options.percentages
        ? " " + Math.round((d.data.value / getTotal(data.inner)) * 100) + "%"
        : d.data.label
    })

  options.labels &&
    outerText
      .append("svg:tspan")
      .text(options.labels.age ? " yr." : options.labels.size ? " L" : "")

  !options.compact &&
    selection
      .append("text")
      .attr("y", 10)
      .attr("x", width / 2 - 22)
      .text("Last Month")

  const centerText = selection
    .append("text")
    .attr("y", width / 2 - offset + (options.compact ? 0 : 13))
    .attr("x", width / 2 - offset / 4)

  centerText
    .append("tspan")
    .attr("text-anchor", "middle")
    .attr("x", "50%")
    .text("Today")

  centerText
    .append("tspan")
    .attr("text-anchor", "middle")
    .attr("x", "50%")
    .attr("dy", "15")
    .text(moment().format("MMM do"))

  //------------------------------------------------------------------------------------------------------------------
}

export default drawDonutChart

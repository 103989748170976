import * as d3 from "d3"
import React, {useRef, useEffect} from "react"
import useComponentSize from "@rehooks/component-size"
import styled from "styled-components"

import {GraphOptions} from "../types"

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  svg {
    width: 100%;
    height: 100%;
  }
`

export interface D3WrapperProps {
  draw: (
    selection: any,
    data: any,
    containerSize: {width: number; height: number},
    options?: GraphOptions
  ) => void
  data: any
  options?: GraphOptions
}

export default function D3Wrapper({draw, data, options}: D3WrapperProps) {
  const divRef = useRef(null)
  const svgRef = useRef(null)
  const divSize = useComponentSize(divRef)

  useEffect(() => {
    if (!svgRef.current || !divSize.width || !divSize.height) return
    const selection = d3.select(svgRef.current)
    draw(selection, data, divSize, options)
  }, [draw, data, divSize, options])

  return (
    <Wrapper ref={divRef}>
      <svg ref={svgRef}></svg>
    </Wrapper>
  )
}

import React from "react"
import {Box} from "@material-ui/core"

import {BulletPoint, BulletText} from "./OccupancyChart.styles"
import {COLORS} from "./utils"

const Bullet: React.FC<{color: string}> = ({color, children}) => {
  return (
    <Box mr={4} p={1}>
      <BulletPoint color={color} />
      <BulletText>{children}</BulletText>
    </Box>
  )
}

interface LegendItem {
  color?: string
  percentage: number
  text: string
}

const Legend: React.FC<{items: LegendItem[]}> = ({items}) => (
  <Box display="flex" flexWrap="wrap" pb={2} pt={2}>
    {items.map((item, i) => (
      <Bullet color={item.color || COLORS[i] || COLORS[i % items.length]}>
        {item.text} {`(${item.percentage}%)`}
      </Bullet>
    ))}
  </Box>
)

export default Legend

/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import * as d3 from "d3"
import {formatDistanceToNow} from "date-fns"
import React, {useState, useRef, useEffect} from "react"
import useComponentSize from "@rehooks/component-size"
import {
  createStyles,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core"

import D3Wrapper from "../../../../components/graphs/common/D3Wrapper"
import carImg from "./car.png"
import Tooltip from "../../../../components/graphs/common/Tooltip/Tooltip"
import DriveInAnimation from "./DriveInAnimation"
import {
  CarParkInnerContainer,
  CarParkOuterContainer,
  DriveInWrapper,
} from "./OccupancyChart.styles"
import {sampleEvery} from "./utils"
import {useOccupanceMode} from "./hooks"
import Legend from "./Legend"

const BAY_RATIO = 4 / 3
const BAY_WIDTH = 34
const BAY_HEIGHT = BAY_WIDTH * BAY_RATIO

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      padding: theme.spacing(2),
    },
  })
)

const drawCarParkOverview = (
  selection: d3.Selection<SVGElement, any, any, any>,
  {capacity, cars}: {capacity: number; cars: any[]},
  container: {width: number; height: number},
  {
    fill,
    onMouseOver,
    tooltipRef,
  }: {
    fill: (car: any, i: number) => string
    onMouseOver: (car: any) => any
    tooltipRef: any
  }
) => {
  const data = ([] as any[]).concat(
    cars,
    new Array(Math.max(0, capacity - cars.length)).fill(null)
  )
  const baysPerRow = Math.floor(container.width / BAY_WIDTH)

  const bays = selection
    .selectAll("g")
    .data(data)
    .join("g")
    .attr("transform", (d, i) => {
      const x = (i % baysPerRow) * BAY_WIDTH
      const y = Math.floor(i / baysPerRow) * BAY_HEIGHT
      return `translate(${x},${y})`
    })

  bays.selectAll("rect").remove()
  bays.selectAll("image").remove()

  bays
    .append("rect")
    .style("fill", (d, i) => {
      if (d === null) return "#ccc"
      return fill(d, i)
    })
    .attr("width", BAY_WIDTH - 4)
    .attr("height", BAY_HEIGHT - 4)
    .attr("rx", 4)
    .attr("ry", 4)

  bays
    .append("image")
    .filter((d) => d !== null)
    .attr("href", carImg)
    .attr("width", BAY_WIDTH - 16)
    .attr("height", BAY_HEIGHT - 16)
    .attr(
      "transform",
      `translate(${BAY_WIDTH - 10}, ${BAY_HEIGHT - 10}) rotate(180)`
    )
    .on("mouseenter", (d, i) => {
      onMouseOver(d)
      if (!tooltipRef) return
      tooltipRef.current.setData({
        shouldShow: true,
        value: (
          <span>
            {d.type && `${d?.fuel} ${d?.type}, ${d?.make} ${d?.model}`}
            {d.type && <br />}
            Arrived {formatDistanceToNow(new Date(d.arrivedAt))} ago
          </span>
        ),
        x: (i % baysPerRow) * BAY_WIDTH,
        y: Math.floor(i / baysPerRow) * BAY_HEIGHT,
      })
    })
    .on("mouseleave", () => {
      if (!tooltipRef) return
      tooltipRef.current.setData({shouldShow: false})
    })
}

const CarParkOccupancyViz: React.FC<{
  carpark: {capacity: number}
  occupancy: {fuel: string; pastAppearances: any[]; arrivedAt: Date}[]
}> = ({carpark, occupancy}) => {
  const classes = useStyles()
  const ref = useRef(null)
  const tooltipRef = useRef()
  const {width: containerWidth} = useComponentSize(ref)
  const [{height, width}, setSize] = useState({
    height: 300,
    width: containerWidth || 500,
  })
  const [mode, setMode] = useState<string>("arrivalTime")

  const {fill, sortScore, legendItems} = useOccupanceMode({
    mode,
    cars: occupancy,
  })
  const sampleFactor = Math.floor(carpark.capacity / 200)
  occupancy = occupancy.slice().sort((a, b) => sortScore(b) - sortScore(a))
  occupancy = sampleEvery(occupancy, sampleFactor)
  const capacitySampled = Math.ceil(carpark.capacity / sampleFactor)

  useEffect(() => {
    const baysPerRow = Math.floor(containerWidth / BAY_WIDTH)
    const numRows = Math.ceil(capacitySampled / baysPerRow)
    setSize({height: numRows * BAY_HEIGHT, width: baysPerRow * BAY_WIDTH})
  }, [containerWidth, capacitySampled])

  return (
    <React.Fragment>
      <FormControl variant="outlined">
        <Select
          classes={{root: classes.select}}
          onChange={(e) => setMode(e.target.value as string)}
          value={mode}
        >
          <MenuItem value="arrivalTime">
            <Typography variant="body2">Arrival Time</Typography>
          </MenuItem>
          <MenuItem value="electric">
            <Typography variant="body2">Electric</Typography>
          </MenuItem>
        </Select>
      </FormControl>
      <Legend items={legendItems} />
      <CarParkOuterContainer>
        <CarParkInnerContainer>
          <DriveInWrapper top={100}>
            <DriveInAnimation direction="in" />
          </DriveInWrapper>
          <DriveInWrapper top={300}>
            <DriveInAnimation direction="out" />
          </DriveInWrapper>
          <div ref={ref}>
            <div
              css={css`
                height: ${height}px;
                width: ${width}px;
              `}
            >
              <Tooltip ref={tooltipRef} height={40} width={230} />
              <D3Wrapper
                draw={(selection, data, container) =>
                  drawCarParkOverview(selection, data, container, {
                    fill,
                    onMouseOver: (car) => {},
                    tooltipRef,
                  })
                }
                data={{capacity: capacitySampled, cars: occupancy}}
              />
            </div>
          </div>
        </CarParkInnerContainer>
      </CarParkOuterContainer>
    </React.Fragment>
  )
}

export default CarParkOccupancyViz

/** @jsx jsx */
import * as d3 from "d3"
import {BubbleChartOptions} from "../types"
import {getTotal} from "../util/functions"

const drawBubbleChart = (
  selection: d3.Selection<SVGElement, any, any, any>,
  data: {
    children: {label: string; value: number}[]
    label: string
    value: number
  },
  container: {width: number; height: number},
  options: BubbleChartOptions
) => {
  const width = container.width,
    height = container.height

  const color = d3
    .scaleOrdinal<string>()
    .domain(options.colors)
    .range(options.colors)

  const packLayout = d3.pack().size([width, height]).padding(options.padding)

  const rootNode = d3.hierarchy(data).sum((d) => d.value)
  const range: [number, number] = d3.extent(data.children.map((d) => d.value))
  const scale = d3.scaleLinear().domain(range).range([100, 150])
  packLayout(rootNode)

  data.children.forEach((marker) => {
    const size = scale(marker.value)
    const el = document.createElement("div")
    el.style.height = `${size}px`
    el.style.width = `${size}px`

    const svg = d3
      .select(el)
      .append("svg")
      .attr("height", size)
      .attr("width", size)

    svg.append("g")

    selection.selectAll("circle").remove()
    selection
      .selectAll("circle")
      .data(rootNode.descendants())
      .enter()
      .append("circle")
      .attr("cx", (d: any) => d.x)
      .attr("cy", (d: any) => d.y)
      .attr("r", (d: any) => options.getRadius(d))
      .attr("fill", (d: any) =>
        d.height === 0 ? color(d.data.value) : options.background
      )

    selection.selectAll("text").remove()
    selection
      .selectAll("circles")
      .data(rootNode.descendants())
      .enter()
      .append("text")
      .style("text-anchor", "middle")
      .attr("font-size", function (d: any) {
        var line = marker.label
        var len = line.substring(0, scale(marker.value) / options.fontSizeScale)
          .length
        var size = scale(marker.value) / options.fontSizeScale
        size *= 10 / len
        size += 1
        return Math.round(size) + "px"
      })
      .attr("fill", "#fff")
      .attr("dx", (d: any) => d.x)
      .attr("dy", (d: any) => d.y)
      .text((d: any) =>
        d.data.label
          ? d.data.label.substring(0, size / options.fontSizeScale)
          : null
      )

    const getPercentage = (d) => {
      if (
        Math.round((d.data.value / getTotal(data.children)) * 100) > 0 &&
        options.vehicle
      ) {
        return (
          Math.round((d.data.value / getTotal(data.children)) * 100) +
          "%" +
          " " +
          "vehicles"
        )
      } else if (
        Math.round((d.data.value / getTotal(data.children)) * 100) > 0
      ) {
        return Math.round((d.data.value / getTotal(data.children)) * 100) + "%"
      } else {
        return null
      }
    }

    selection
      .selectAll("circles")
      .data(rootNode.descendants())
      .enter()
      .append("text")
      .append("tspan")
      .style("text-anchor", "middle")
      .attr("font-size", function (d: any) {
        var line = "15%"
        var len = line.substring(0, scale(d.data.value) / 30).length
        var size = scale(d.data.value) / 30
        size *= 10 / len
        size += 1
        return Math.round(size) + "px"
      })
      .attr("fill", "#fff")
      .attr("dx", (d: any) => d.x)
      .attr("dy", (d: any) => d.y + 17)
      .text((d: any) => getPercentage(d))

    selection
      .selectAll("circles")
      .data(rootNode.descendants())
      .enter()
      .append("text")
      .append("tspan")
      .style("text-anchor", "middle")
      .attr("font-size", function (d: any) {
        var line = "5% since last week"
        var len = line.substring(0, scale(d.data.value) / 6).length
        var size = scale(d.data.value) / 6
        size *= 7 / len
        size += 1
        return Math.round(size) + "px"
      })
      .attr("fill", "#fff")
      .attr("dx", (d: any) => d.x)
      .attr("dy", (d: any) => d.y + 27)
      .text((d: any) => (d.height === 0 ? "+5% vs last week" : null))
  })
}

export default drawBubbleChart

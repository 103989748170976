export default [
  {
    date: new Date("2020-09-14T02:41:00.312Z"),
    eventName: "1",
    value: [
      {type: "a", value: 20},
      {type: "b", value: 25},
      {type: "c", value: 30},
    ],
    type: "number_reading",
    path: "/event/96/info",
  },
  {
    date: new Date("2020-09-14T02:46:00.312Z"),
    eventName: "2",
    value: [
      {type: "a", value: 19},
      {type: "b", value: 21},
      {type: "c", value: 30},
    ],
    type: "number_reading",
    path: "/event/95/info",
  },
  {
    date: new Date("2020-09-14T02:51:00.312Z"),
    eventName: "3",
    value: [
      {type: "a", value: 15},
      {type: "b", value: 22},
      {type: "c", value: 34},
    ],
    type: "number_reading",
    path: "/event/94/info",
  },
  {
    date: new Date("2020-09-14T02:56:00.312Z"),
    eventName: "4",
    value: [
      {type: "a", value: 12},
      {type: "b", value: 17},
      {type: "c", value: 33},
    ],
    type: "number_reading",
    path: "/event/93/info",
  },
  {
    date: new Date("2020-09-14T03:01:00.312Z"),
    eventName: "5",
    value: [
      {type: "a", value: 15},
      {type: "b", value: 15},
      {type: "c", value: 36},
    ],
    type: "number_reading",
    path: "/event/92/info",
  },
  {
    date: new Date("2020-09-14T03:06:00.312Z"),
    eventName: "6",
    value: [
      {type: "a", value: 15},
      {type: "b", value: 17},
      {type: "c", value: 39},
    ],
    type: "number_reading",
    path: "/event/91/info",
  },
  {
    date: new Date("2020-09-14T03:11:00.312Z"),
    eventName: "7",
    value: [
      {type: "a", value: 17},
      {type: "b", value: 16},
      {type: "c", value: 37},
    ],
    type: "number_reading",
    path: "/event/90/info",
  },
  {
    date: new Date("2020-09-14T03:16:00.312Z"),
    eventName: "8",
    value: [
      {type: "a", value: 13},
      {type: "b", value: 12},
      {type: "c", value: 38},
    ],
    type: "number_reading",
    path: "/event/89/info",
  },
  {
    date: new Date("2020-09-14T03:21:00.312Z"),
    eventName: "9",
    value: [
      {type: "a", value: 11},
      {type: "b", value: 13},
      {type: "c", value: 41},
    ],
    type: "number_reading",
    path: "/event/88/info",
  },
  {
    date: new Date("2020-09-14T03:26:00.312Z"),
    eventName: "10",
    value: [
      {type: "a", value: 7},
      {type: "b", value: 11},
      {type: "c", value: 36},
    ],
    type: "number_reading",
    path: "/event/87/info",
  },
  {
    date: new Date("2020-09-14T03:31:00.312Z"),
    eventName: "11",
    value: [
      {type: "a", value: 9},
      {type: "b", value: 6},
      {type: "c", value: 38},
    ],
    type: "number_reading",
    path: "/event/86/info",
  },
  {
    date: new Date("2020-09-14T03:36:00.312Z"),
    eventName: "12",
    value: [
      {type: "a", value: 9},
      {type: "b", value: 10},
      {type: "c", value: 41},
    ],
    type: "number_reading",
    path: "/event/85/info",
  },
  {
    date: new Date("2020-09-14T03:41:00.312Z"),
    eventName: "13",
    value: [
      {type: "a", value: 6},
      {type: "b", value: 14},
      {type: "c", value: 36},
    ],
    type: "number_reading",
    path: "/event/84/info",
  },
  {
    date: new Date("2020-09-14T03:46:00.312Z"),
    eventName: "14",
    value: [
      {type: "a", value: 3},
      {type: "b", value: 12},
      {type: "c", value: 39},
    ],
    type: "number_reading",
    path: "/event/83/info",
  },
  {
    date: new Date("2020-09-14T03:51:00.312Z"),
    eventName: "15",
    value: [
      {type: "a", value: 3},
      {type: "b", value: 8},
      {type: "c", value: 35},
    ],
    type: "number_reading",
    path: "/event/82/info",
  },
  {
    date: new Date("2020-09-14T03:56:00.312Z"),
    eventName: "16",
    value: [
      {type: "a", value: 4},
      {type: "b", value: 12},
      {type: "c", value: 34},
    ],
    type: "number_reading",
    path: "/event/81/info",
  },
  {
    date: new Date("2020-09-14T04:01:00.312Z"),
    eventName: "17",
    value: [
      {type: "a", value: 6},
      {type: "b", value: 9},
      {type: "c", value: 38},
    ],
    type: "number_reading",
    path: "/event/80/info",
  },
  {
    date: new Date("2020-09-14T04:06:00.313Z"),
    eventName: "18",
    value: [
      {type: "a", value: 1},
      {type: "b", value: 8},
      {type: "c", value: 38},
    ],
    type: "number_reading",
    path: "/event/79/info",
  },
  {
    date: new Date("2020-09-14T04:11:00.313Z"),
    eventName: "19",
    value: [
      {type: "a", value: 4},
      {type: "b", value: 3},
      {type: "c", value: 40},
    ],
    type: "number_reading",
    path: "/event/78/info",
  },
  {
    date: new Date("2020-09-14T04:16:00.313Z"),
    eventName: "20",
    value: [
      {type: "a", value: 4},
      {type: "b", value: 3},
      {type: "c", value: 43},
    ],
    type: "number_reading",
    path: "/event/77/info",
  },
  {
    date: new Date("2020-09-14T04:21:00.313Z"),
    eventName: "21",
    value: [
      {type: "a", value: 6},
      {type: "b", value: 7},
      {type: "c", value: 46},
    ],
    type: "number_reading",
    path: "/event/76/info",
  },
  {
    date: new Date("2020-09-14T04:26:00.313Z"),
    eventName: "22",
    value: [
      {type: "a", value: 10},
      {type: "b", value: 11},
      {type: "c", value: 47},
    ],
    type: "number_reading",
    path: "/event/75/info",
  },
  {
    date: new Date("2020-09-14T04:31:00.313Z"),
    eventName: "23",
    value: [
      {type: "a", value: 13},
      {type: "b", value: 11},
      {type: "c", value: 50},
    ],
    type: "number_reading",
    path: "/event/74/info",
  },
  {
    date: new Date("2020-09-14T04:36:00.313Z"),
    eventName: "24",
    value: [
      {type: "a", value: 11},
      {type: "b", value: 12},
      {type: "c", value: 51},
    ],
    type: "number_reading",
    path: "/event/73/info",
  },
  {
    date: new Date("2020-09-14T04:41:00.313Z"),
    eventName: "25",
    value: [
      {type: "a", value: 6},
      {type: "b", value: 10},
      {type: "c", value: 48},
    ],
    type: "number_reading",
    path: "/event/72/info",
  },
  {
    date: new Date("2020-09-14T04:46:00.313Z"),
    eventName: "26",
    value: [
      {type: "a", value: 1},
      {type: "b", value: 5},
      {type: "c", value: 50},
    ],
    type: "number_reading",
    path: "/event/71/info",
  },
  {
    date: new Date("2020-09-14T04:51:00.313Z"),
    eventName: "27",
    value: [
      {type: "a", value: 4},
      {type: "b", value: 6},
      {type: "c", value: 46},
    ],
    type: "number_reading",
    path: "/event/70/info",
  },
  {
    date: new Date("2020-09-14T04:56:00.313Z"),
    eventName: "28",
    value: [
      {type: "a", value: 2},
      {type: "b", value: 2},
      {type: "c", value: 49},
    ],
    type: "number_reading",
    path: "/event/69/info",
  },
  {
    date: new Date("2020-09-14T05:01:00.313Z"),
    eventName: "29",
    value: [
      {type: "a", value: 1},
      {type: "b", value: 0},
      {type: "c", value: 47},
    ],
    type: "number_reading",
    path: "/event/68/info",
  },
  {
    date: new Date("2020-09-14T05:06:00.313Z"),
    eventName: "30",
    value: [
      {type: "a", value: 0},
      {type: "b", value: 2},
      {type: "c", value: 46},
    ],
    type: "number_reading",
    path: "/event/67/info",
  },
  {
    date: new Date("2020-09-14T05:11:00.313Z"),
    eventName: "31",
    value: [
      {type: "a", value: 0},
      {type: "b", value: 5},
      {type: "c", value: 44},
    ],
    type: "number_reading",
    path: "/event/66/info",
  },
  {
    date: new Date("2020-09-14T05:16:00.313Z"),
    eventName: "32",
    value: [
      {type: "a", value: 2},
      {type: "b", value: 5},
      {type: "c", value: 41},
    ],
    type: "number_reading",
    path: "/event/65/info",
  },
  {
    date: new Date("2020-09-14T05:21:00.313Z"),
    eventName: "33",
    value: [
      {type: "a", value: 3},
      {type: "b", value: 9},
      {type: "c", value: 44},
    ],
    type: "number_reading",
    path: "/event/64/info",
  },
  {
    date: new Date("2020-09-14T05:26:00.313Z"),
    eventName: "34",
    value: [
      {type: "a", value: 2},
      {type: "b", value: 12},
      {type: "c", value: 39},
    ],
    type: "number_reading",
    path: "/event/63/info",
  },
  {
    date: new Date("2020-09-14T05:31:00.313Z"),
    eventName: "35",
    value: [
      {type: "a", value: 5},
      {type: "b", value: 11},
      {type: "c", value: 43},
    ],
    type: "number_reading",
    path: "/event/62/info",
  },
  {
    date: new Date("2020-09-14T05:36:00.313Z"),
    eventName: "36",
    value: [
      {type: "a", value: 8},
      {type: "b", value: 11},
      {type: "c", value: 47},
    ],
    type: "number_reading",
    path: "/event/61/info",
  },
  {
    date: new Date("2020-09-14T05:41:00.313Z"),
    eventName: "37",
    value: [
      {type: "a", value: 4},
      {type: "b", value: 14},
      {type: "c", value: 42},
    ],
    type: "number_reading",
    path: "/event/60/info",
  },
  {
    date: new Date("2020-09-14T05:46:00.313Z"),
    eventName: "38",
    value: [
      {type: "a", value: 4},
      {type: "b", value: 14},
      {type: "c", value: 38},
    ],
    type: "number_reading",
    path: "/event/59/info",
  },
  {
    date: new Date("2020-09-14T05:51:00.313Z"),
    eventName: "39",
    value: [
      {type: "a", value: 4},
      {type: "b", value: 13},
      {type: "c", value: 41},
    ],
    type: "number_reading",
    path: "/event/58/info",
  },
  {
    date: new Date("2020-09-14T05:56:00.313Z"),
    eventName: "40",
    value: [
      {type: "a", value: 1},
      {type: "b", value: 17},
      {type: "c", value: 41},
    ],
    type: "number_reading",
    path: "/event/57/info",
  },
  {
    date: new Date("2020-09-14T06:01:00.313Z"),
    eventName: "41",
    value: [
      {type: "a", value: 5},
      {type: "b", value: 19},
      {type: "c", value: 38},
    ],
    type: "number_reading",
    path: "/event/56/info",
  },
  {
    date: new Date("2020-09-14T06:06:00.313Z"),
    eventName: "42",
    value: [
      {type: "a", value: 3},
      {type: "b", value: 18},
      {type: "c", value: 33},
    ],
    type: "number_reading",
    path: "/event/55/info",
  },
  {
    date: new Date("2020-09-14T06:11:00.313Z"),
    eventName: "43",
    value: [
      {type: "a", value: 7},
      {type: "b", value: 17},
      {type: "c", value: 33},
    ],
    type: "number_reading",
    path: "/event/54/info",
  },
  {
    date: new Date("2020-09-14T06:16:00.313Z"),
    eventName: "44",
    value: [
      {type: "a", value: 5},
      {type: "b", value: 14},
      {type: "c", value: 35},
    ],
    type: "number_reading",
    path: "/event/53/info",
  },
  {
    date: new Date("2020-09-14T06:21:00.313Z"),
    eventName: "45",
    value: [
      {type: "a", value: 9},
      {type: "b", value: 17},
      {type: "c", value: 31},
    ],
    type: "number_reading",
    path: "/event/52/info",
  },
  {
    date: new Date("2020-09-14T06:26:00.313Z"),
    eventName: "46",
    value: [
      {type: "a", value: 6},
      {type: "b", value: 14},
      {type: "c", value: 32},
    ],
    type: "number_reading",
    path: "/event/51/info",
  },
  {
    date: new Date("2020-09-14T06:31:00.313Z"),
    eventName: "47",
    value: [
      {type: "a", value: 9},
      {type: "b", value: 11},
      {type: "c", value: 32},
    ],
    type: "number_reading",
    path: "/event/50/info",
  },
  {
    date: new Date("2020-09-14T06:36:00.313Z"),
    eventName: "48",
    value: [
      {type: "a", value: 10},
      {type: "b", value: 6},
      {type: "c", value: 34},
    ],
    type: "number_reading",
    path: "/event/49/info",
  },
  {
    date: new Date("2020-09-14T06:41:00.313Z"),
    eventName: "49",
    value: [
      {type: "a", value: 13},
      {type: "b", value: 8},
      {type: "c", value: 29},
    ],
    type: "number_reading",
    path: "/event/48/info",
  },
  {
    date: new Date("2020-09-14T06:46:00.313Z"),
    eventName: "50",
    value: [
      {type: "a", value: 11},
      {type: "b", value: 4},
      {type: "c", value: 33},
    ],
    type: "number_reading",
    path: "/event/47/info",
  },
  {
    date: new Date("2020-09-14T06:51:00.313Z"),
    eventName: "51",
    value: [
      {type: "a", value: 15},
      {type: "b", value: 8},
      {type: "c", value: 33},
    ],
    type: "number_reading",
    path: "/event/46/info",
  },
  {
    date: new Date("2020-09-14T06:56:00.313Z"),
    eventName: "52",
    value: [
      {type: "a", value: 17},
      {type: "b", value: 6},
      {type: "c", value: 31},
    ],
    type: "number_reading",
    path: "/event/45/info",
  },
  {
    date: new Date("2020-09-14T07:01:00.313Z"),
    eventName: "53",
    value: [
      {type: "a", value: 12},
      {type: "b", value: 2},
      {type: "c", value: 27},
    ],
    type: "number_reading",
    path: "/event/44/info",
  },
  {
    date: new Date("2020-09-14T07:06:00.313Z"),
    eventName: "54",
    value: [
      {type: "a", value: 12},
      {type: "b", value: 5},
      {type: "c", value: 22},
    ],
    type: "number_reading",
    path: "/event/43/info",
  },
  {
    date: new Date("2020-09-14T07:11:00.313Z"),
    eventName: "55",
    value: [
      {type: "a", value: 12},
      {type: "b", value: 6},
      {type: "c", value: 23},
    ],
    type: "number_reading",
    path: "/event/42/info",
  },
  {
    date: new Date("2020-09-14T07:16:00.313Z"),
    eventName: "56",
    value: [
      {type: "a", value: 13},
      {type: "b", value: 7},
      {type: "c", value: 24},
    ],
    type: "number_reading",
    path: "/event/41/info",
  },
  {
    date: new Date("2020-09-14T07:21:00.313Z"),
    eventName: "57",
    value: [
      {type: "a", value: 11},
      {type: "b", value: 10},
      {type: "c", value: 24},
    ],
    type: "number_reading",
    path: "/event/40/info",
  },
  {
    date: new Date("2020-09-14T07:26:00.313Z"),
    eventName: "58",
    value: [
      {type: "a", value: 7},
      {type: "b", value: 11},
      {type: "c", value: 24},
    ],
    type: "number_reading",
    path: "/event/39/info",
  },
  {
    date: new Date("2020-09-14T07:31:00.313Z"),
    eventName: "59",
    value: [
      {type: "a", value: 8},
      {type: "b", value: 10},
      {type: "c", value: 25},
    ],
    type: "number_reading",
    path: "/event/38/info",
  },
  {
    date: new Date("2020-09-14T07:36:00.313Z"),
    eventName: "60",
    value: [
      {type: "a", value: 9},
      {type: "b", value: 12},
      {type: "c", value: 20},
    ],
    type: "number_reading",
    path: "/event/37/info",
  },
  {
    date: new Date("2020-09-14T07:41:00.313Z"),
    eventName: "61",
    value: [
      {type: "a", value: 6},
      {type: "b", value: 11},
      {type: "c", value: 15},
    ],
    type: "number_reading",
    path: "/event/36/info",
  },
  {
    date: new Date("2020-09-14T07:46:00.313Z"),
    eventName: "62",
    value: [
      {type: "a", value: 8},
      {type: "b", value: 11},
      {type: "c", value: 13},
    ],
    type: "number_reading",
    path: "/event/35/info",
  },
  {
    date: new Date("2020-09-14T07:51:00.314Z"),
    eventName: "63",
    value: [
      {type: "a", value: 9},
      {type: "b", value: 10},
      {type: "c", value: 14},
    ],
    type: "number_reading",
    path: "/event/34/info",
  },
  {
    date: new Date("2020-09-14T07:56:00.314Z"),
    eventName: "64",
    value: [
      {type: "a", value: 4},
      {type: "b", value: 6},
      {type: "c", value: 10},
    ],
    type: "number_reading",
    path: "/event/33/info",
  },
  {
    date: new Date("2020-09-14T08:01:00.314Z"),
    eventName: "65",
    value: [
      {type: "a", value: 6},
      {type: "b", value: 5},
      {type: "c", value: 11},
    ],
    type: "number_reading",
    path: "/event/32/info",
  },
  {
    date: new Date("2020-09-14T08:06:00.314Z"),
    eventName: "66",
    value: [
      {type: "a", value: 1},
      {type: "b", value: 4},
      {type: "c", value: 11},
    ],
    type: "number_reading",
    path: "/event/31/info",
  },
  {
    date: new Date("2020-09-14T08:11:00.314Z"),
    eventName: "67",
    value: [
      {type: "a", value: 1},
      {type: "b", value: 6},
      {type: "c", value: 7},
    ],
    type: "number_reading",
    path: "/event/30/info",
  },
  {
    date: new Date("2020-09-14T08:16:00.314Z"),
    eventName: "68",
    value: [
      {type: "a", value: 1},
      {type: "b", value: 10},
      {type: "c", value: 9},
    ],
    type: "number_reading",
    path: "/event/29/info",
  },
  {
    date: new Date("2020-09-14T08:21:00.314Z"),
    eventName: "69",
    value: [
      {type: "a", value: 0},
      {type: "b", value: 6},
      {type: "c", value: 7},
    ],
    type: "number_reading",
    path: "/event/28/info",
  },
  {
    date: new Date("2020-09-14T08:26:00.314Z"),
    eventName: "70",
    value: [
      {type: "a", value: 2},
      {type: "b", value: 5},
      {type: "c", value: 11},
    ],
    type: "number_reading",
    path: "/event/27/info",
  },
  {
    date: new Date("2020-09-14T08:31:00.314Z"),
    eventName: "71",
    value: [
      {type: "a", value: 6},
      {type: "b", value: 0},
      {type: "c", value: 13},
    ],
    type: "number_reading",
    path: "/event/26/info",
  },
  {
    date: new Date("2020-09-14T08:36:00.314Z"),
    eventName: "72",
    value: [
      {type: "a", value: 10},
      {type: "b", value: 4},
      {type: "c", value: 13},
    ],
    type: "number_reading",
    path: "/event/25/info",
  },
  {
    date: new Date("2020-09-14T08:41:00.314Z"),
    eventName: "73",
    value: [
      {type: "a", value: 14},
      {type: "b", value: 8},
      {type: "c", value: 11},
    ],
    type: "number_reading",
    path: "/event/24/info",
  },
  {
    date: new Date("2020-09-14T08:46:00.314Z"),
    eventName: "74",
    value: [
      {type: "a", value: 18},
      {type: "b", value: 7},
      {type: "c", value: 8},
    ],
    type: "number_reading",
    path: "/event/23/info",
  },
  {
    date: new Date("2020-09-14T08:51:00.314Z"),
    eventName: "75",
    value: [
      {type: "a", value: 15},
      {type: "b", value: 7},
      {type: "c", value: 11},
    ],
    type: "number_reading",
    path: "/event/22/info",
  },
  {
    date: new Date("2020-09-14T08:56:00.314Z"),
    eventName: "76",
    value: [
      {type: "a", value: 19},
      {type: "b", value: 5},
      {type: "c", value: 15},
    ],
    type: "number_reading",
    path: "/event/21/info",
  },
  {
    date: new Date("2020-09-14T09:01:00.314Z"),
    eventName: "77",
    value: [
      {type: "a", value: 17},
      {type: "b", value: 3},
      {type: "c", value: 10},
    ],
    type: "number_reading",
    path: "/event/20/info",
  },
  {
    date: new Date("2020-09-14T09:06:00.314Z"),
    eventName: "78",
    value: [
      {type: "a", value: 18},
      {type: "b", value: 2},
      {type: "c", value: 12},
    ],
    type: "number_reading",
    path: "/event/19/info",
  },
  {
    date: new Date("2020-09-14T09:11:00.314Z"),
    eventName: "79",
    value: [
      {type: "a", value: 19},
      {type: "b", value: 1},
      {type: "c", value: 16},
    ],
    type: "number_reading",
    path: "/event/18/info",
  },
  {
    date: new Date("2020-09-14T09:16:00.314Z"),
    eventName: "80",
    value: [
      {type: "a", value: 18},
      {type: "b", value: 1},
      {type: "c", value: 11},
    ],
    type: "number_reading",
    path: "/event/17/info",
  },
  {
    date: new Date("2020-09-14T09:21:00.314Z"),
    eventName: "81",
    value: [
      {type: "a", value: 17},
      {type: "b", value: 0},
      {type: "c", value: 15},
    ],
    type: "number_reading",
    path: "/event/16/info",
  },
  {
    date: new Date("2020-09-14T09:26:00.314Z"),
    eventName: "82",
    value: [
      {type: "a", value: 18},
      {type: "b", value: 0},
      {type: "c", value: 18},
    ],
    type: "number_reading",
    path: "/event/15/info",
  },
  {
    date: new Date("2020-09-14T09:31:00.314Z"),
    eventName: "83",
    value: [
      {type: "a", value: 20},
      {type: "b", value: 0},
      {type: "c", value: 19},
    ],
    type: "number_reading",
    path: "/event/14/info",
  },
  {
    date: new Date("2020-09-14T09:36:00.314Z"),
    eventName: "84",
    value: [
      {type: "a", value: 15},
      {type: "b", value: 2},
      {type: "c", value: 18},
    ],
    type: "number_reading",
    path: "/event/13/info",
  },
  {
    date: new Date("2020-09-14T09:41:00.314Z"),
    eventName: "85",
    value: [
      {type: "a", value: 15},
      {type: "b", value: 2},
      {type: "c", value: 22},
    ],
    type: "number_reading",
    path: "/event/12/info",
  },
  {
    date: new Date("2020-09-14T09:46:00.314Z"),
    eventName: "86",
    value: [
      {type: "a", value: 14},
      {type: "b", value: 5},
      {type: "c", value: 22},
    ],
    type: "number_reading",
    path: "/event/11/info",
  },
  {
    date: new Date("2020-09-14T09:51:00.314Z"),
    eventName: "87",
    value: [
      {type: "a", value: 15},
      {type: "b", value: 7},
      {type: "c", value: 22},
    ],
    type: "number_reading",
    path: "/event/10/info",
  },
  {
    date: new Date("2020-09-14T09:56:00.314Z"),
    eventName: "88",
    value: [
      {type: "a", value: null},
      {type: "b", value: 7},
      {type: "c", value: 22},
    ],
    type: "number_reading",
    path: "/event/9/info",
  },
  {
    date: new Date("2020-09-14T10:01:00.314Z"),
    eventName: "89",
    value: [
      {type: "a", value: null},
      {type: "b", value: 4},
      {type: "c", value: 20},
    ],
    type: "number_reading",
    path: "/event/8/info",
  },
  {
    date: new Date("2020-09-14T10:06:00.314Z"),
    eventName: "90",
    value: [
      {type: "a", value: null},
      {type: "b", value: 2},
      {type: "c", value: 21},
    ],
    type: "number_reading",
    path: "/event/7/info",
  },
  {
    date: new Date("2020-09-14T10:11:00.314Z"),
    eventName: "91",
    value: [
      {type: "a", value: null},
      {type: "b", value: 3},
      {type: "c", value: 19},
    ],
    type: "number_reading",
    path: "/event/6/info",
  },
  {
    date: new Date("2020-09-14T10:16:00.314Z"),
    eventName: "92",
    value: [
      {type: "a", value: null},
      {type: "b", value: 1},
      {type: "c", value: 14},
    ],
    type: "number_reading",
    path: "/event/5/info",
  },
  {
    date: new Date("2020-09-14T10:21:00.314Z"),
    eventName: "93",
    value: [
      {type: "a", value: null},
      {type: "b", value: 1},
      {type: "c", value: 13},
    ],
    type: "number_reading",
    path: "/event/4/info",
  },
  {
    date: new Date("2020-09-14T10:26:00.314Z"),
    eventName: "94",
    value: [
      {type: "a", value: null},
      {type: "b", value: 2},
      {type: "c", value: 15},
    ],
    type: "number_reading",
    path: "/event/3/info",
  },
  {
    date: new Date("2020-09-14T10:31:00.314Z"),
    eventName: "95",
    value: [
      {type: "a", value: null},
      {type: "b", value: 0},
      {type: "c", value: 15},
    ],
    type: "number_reading",
    path: "/event/2/info",
  },
  {
    date: new Date("2020-09-14T10:36:00.314Z"),
    eventName: "96",
    value: [
      {type: "a", value: null},
      {type: "b", value: 2},
      {type: "c", value: 14},
    ],
    type: "number_reading",
    path: "/event/1/info",
  },
]

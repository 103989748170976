import React, {useEffect, useRef, useState, RefObject} from "react"
import mapboxgl, {Map} from "mapbox-gl"
import {Box, createStyles, makeStyles} from "@material-ui/core"
import {COLORS} from "../../../../styles/theme"
import * as d3 from "d3"

const useStyles = makeStyles((theme) =>
  createStyles({
    cardRef: {
      width: "100%",
      height: "100%",
      borderRadius: "6px",
    },
    container: {
      boxSizing: "border-box",
      backgroundColor: theme.palette.secondary.light,
      height: "100%",
      padding: 0,
    },
    marker: {
      backgroundColor: COLORS.purple,
      border: `${theme.spacing(0.5)}px solid ${theme.palette.secondary.main}`,
      borderRadius: "50%",
    },
    popup: {
      padding: 0,
    },
  })
)

interface VisitorsOriginsMapProps {
  initialLatitude: number
  initialLongitude: number
  initialZoom: number
}

// With help from the article at https://sparkgeo.com/blog/build-a-react-mapboxgl-component-with-hooks/
const VisitorsOriginsMap: React.FC<VisitorsOriginsMapProps> = ({
  initialLatitude,
  initialLongitude,
  initialZoom,
}) => {
  const classes = useStyles()
  const [map, setMap] = useState<Map | undefined>()
  const mapContainer = useRef<HTMLDivElement>(null)
  const locationGeoData = [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-1.9336705, 52.4774169],
      },
      properties: {
        name: "Birmingham",
        value: 545,
        trend: 5,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-0.2416789, 51.5285582],
      },
      properties: {
        name: "London",
        value: 367,
        trend: 5,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [0.868628, 51.8861098],
      },
      properties: {
        name: "Colchester",
        value: 257,
        trend: 5,
      },
    },
  ]

  const range: [number, number] = d3.extent(
    locationGeoData.map((d) => d.properties.value)
  )

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string
    const initializeMap = ({
      setMap,
      mapContainer,
    }: {
      setMap: React.Dispatch<React.SetStateAction<Map | undefined>>
      mapContainer: RefObject<HTMLDivElement>
    }) => {
      if (!mapContainer.current) return
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
        center: [initialLongitude, initialLatitude],
        zoom: initialZoom,
      })

      map.addControl(new mapboxgl.NavigationControl())

      const scale = d3.scaleLinear().domain(range).range([100, 150])
      const colourSet = d3
        .scaleOrdinal()
        .range(["#3B63E0", "#A09FE5", "#EDBA42"])
        .domain(["Birmingham", "London", "Colchester"])

      locationGeoData.forEach((marker) => {
        const size = scale(marker.properties.value)
        const colour: any = colourSet(marker.properties.name)
        const el = document.createElement("div")
        el.style.height = `${size}px`
        el.style.width = `${size}px`

        const svg = d3
          .select(el)
          .append("svg")
          .attr("height", size)
          .attr("width", size)

        const g = svg.append("g")

        g.append("circle")
          .attr("cx", size / 2)
          .attr("cy", size / 2)
          .attr("r", size / 2)
          .attr("fill", colour)

        // cityname
        g.append("text")
          .attr("dy", scale(marker.properties.value) / 2.5)
          .attr("dx", scale(marker.properties.value) / 2)
          .attr("fill", "#fff")
          .attr("text-anchor", "middle")
          .style("font-weight", "bold")
          .style("font-size", function (d: any) {
            var line = marker.properties.name
            var len = line.substring(0, scale(marker.properties.value) / 7.5)
              .length
            var size = scale(marker.properties.value) / 7.5
            size *= 10 / len
            size += 1
            return Math.round(size) + "px"
          })
          .text((d) => marker.properties.name.substring(0, size / 7.5))

        // vehicvles
        g.append("text")
          .attr("dy", scale(marker.properties.value) / 2 + 5)
          .attr("dx", scale(marker.properties.value) / 2)
          .attr("fill", "#fff")
          .attr("text-anchor", "middle")
          .style("font-weight", "bold")
          .style("font-size", function (d: any) {
            var line = `${marker.properties.value} vehicles`
            var len = line.substring(0, scale(marker.properties.value) / 8)
              .length
            var size = scale(marker.properties.value) / 8
            size *= 10 / len
            size += 1
            return Math.round(size) + "px"
          })
          .text((d) =>
            `${marker.properties.value} vehicles`.substring(0, size / 8)
          )

        // trend
        g.append("text")
          .attr("dy", scale(marker.properties.value) / 1.4)
          .attr("dx", scale(marker.properties.value) / 2)
          .attr("fill", "#fff")
          .attr("text-anchor", "middle")
          .style("font-size", function (d: any) {
            var line = `${marker.properties.trend}% since last week`
            var len = line.substring(0, scale(marker.properties.value) / 6)
              .length
            var size = scale(marker.properties.value) / 6
            size *= 10 / len
            size += 1
            return Math.round(size) + "px"
          })
          .text((d) =>
            `${marker.properties.trend > 0 ? "+" : "-"}${
              marker.properties.trend
            }% vs last week`.substring(0, size / 6)
          )

        new mapboxgl.Marker(el)
          .setLngLat({
            lng: marker.geometry.coordinates[0],
            lat: marker.geometry.coordinates[1],
          })
          .addTo(map)
      })

      new mapboxgl.Marker().setLngLat([-0.7751537, 52.038137]).addTo(map)

      map.flyTo({
        center: [-0.7751537, 52.038137],
      })

      map.on("load", () => {
        setMap(map)
        map.resize()
      })
    }

    if (!map) initializeMap({setMap, mapContainer})
  }, [
    locationGeoData,
    classes,
    initialLatitude,
    initialLongitude,
    initialZoom,
    map,
    range,
  ])

  if (!locationGeoData.length) return null

  return (
    <Box className={classes.container}>
      <div ref={mapContainer} className={classes.cardRef} />
    </Box>
  )
}

export default VisitorsOriginsMap

import React from "react"
import D3Wrapper from "../../../../components/graphs/common/D3Wrapper"
import moment from "moment"
import draw from "./draw"
import {
  Slot,
  Occupancy,
  DateGroupedOccupancyValues,
  DateGroupedOccupancy,
  PopularTimesChartProps,
} from "./types"

const hourSlots = Array.from(Array(24 - 6).keys())
  .map((i) => i + 6)
  .map((hour) => `${hour}`.padStart(2, "0") + `:00`)

const emptySlots: () => Slot[] = () =>
  hourSlots.map((hour) => ({
    hour,
    occupancy: 0,
  }))

const fillSlots = (occupancies: Occupancy[]): Slot[] => {
  return emptySlots().map((slot) => {
    return {
      hour: slot.hour,
      occupancy: occupancies
        .filter((o) => moment(o.hour).format("HH:00") === slot.hour)
        .reduce((total, o) => total + o.value, 0),
    }
  })
}

const project = (occupancy: Occupancy[]): DateGroupedOccupancyValues => {
  const today = moment()
  const thisDayLastWeek = moment().subtract(1, "week")
  const thisDayLastMonth = moment().subtract(1, "month")

  const trended: DateGroupedOccupancy = occupancy.reduce(
    (memo, occupancy) => {
      const current = moment(occupancy.hour)
      if (current.isSame(today, "day")) {
        memo.today = [...memo.today, occupancy]
      } else if (current.isSame(thisDayLastWeek, "day")) {
        memo.lastWeek = [...memo.lastWeek, occupancy]
      } else if (current.isSame(thisDayLastMonth, "day")) {
        memo.lastMonth = [...memo.lastMonth, occupancy]
      }
      return memo
    },
    {
      today: [],
      lastWeek: [],
      lastMonth: [],
    }
  )

  return {
    today: fillSlots(trended.today),
    lastWeek: fillSlots(trended.lastWeek),
    lastMonth: fillSlots(trended.lastMonth),
  }
}

const PopularTimesChart: React.FC<PopularTimesChartProps> = ({
  occupancy,
  tooltipRef,
}) => {
  // converts raw occupancy entries into projected data for today, last week and last month
  const data = project(occupancy)
  return (
    <D3Wrapper
      draw={draw}
      data={data}
      options={{
        margin: {top: 20, right: 20, bottom: 50, left: 60},
        tooltipRef: tooltipRef,
        tooltipIcon: "time",
        xAxisLabel: "Time",
        yAxisLabel: "Number of vehicles",
      }}
    />
  )
}

export default PopularTimesChart

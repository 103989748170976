import {COLORS} from "../../../styles/theme"

export const getGraphColor = (index: number): string => {
  switch (index) {
    case 0:
      return COLORS.blue
    case 1:
      return "#CCC"
    case 2:
      return "#86889E"
    default:
      return COLORS.blue
  }
}

export const getRndInteger = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min)) + min

export const getTotal = (data: any[]): number =>
  [...data].reduce(function (previousValue, currentValue) {
    return previousValue + currentValue.value
  }, 0)

import React from "react"
import styled from "styled-components"
import {AppBar, Typography} from "@material-ui/core"
import theme from "../../styles/theme"

export const StyledAppBar = styled(
  ({drawerWidth, drawerOpen, closedDrawerWidth, ...rest}) => (
    <AppBar {...rest} />
  )
)`
  && {
    justify-content: center;
    padding-left: ${({drawerWidth, drawerOpen, closedDrawerWidth}) =>
      drawerOpen ? drawerWidth : closedDrawerWidth}px;
    border-bottom: 1px solid ${theme.palette.border.main};
    height: ${({height}) => height}px;
  }
`

export const SearchBox = styled.div`
  && {
    display: flex;
    align-items: center;
    padding: ${theme.spacing(0, 2, 0, 2)};

    .MuiSvgIcon-root {
      padding-right: ${theme.spacing(2)}px;
    }
  }
`

export const RightAlign = styled.span`
  margin-left: auto;
`

export const Username = styled(Typography)`
  padding: 0 ${theme.spacing(2)}px;
`

export const ProfileDropdownContainer = styled.span`
  border-right: 1px solid ${theme.palette.border.main};
  padding-right: ${theme.spacing(4)}px;
  margin-right: ${theme.spacing(4)}px;
`

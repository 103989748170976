import styled from "styled-components"

export const BulletPoint = styled.span<{
  color: string
  marginLeft?: boolean
}>`
  display: inline-block;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  ${(props) => props.marginLeft && "margin-left: 30px"};
  margin-right: 15px;
  vertical-align: middle;
  background: ${(props) => props.color};
`

export const BulletText = styled.span`
  vertical-align: middle;
`

export const CarParkInnerContainer = styled.div`
  position: relative;
  padding: 20px;
  padding-right: 50px;
  border: 4px solid #bbb;
  border-radius: 10px;
`

export const CarParkOuterContainer = styled.div`
  background: #f5f5f5;
  padding: 20px;
  margin-top: 10px;
  padding-right: 70px;
  border-radius: 10px;
`

export const DriveInWrapper = styled.div<{top: number}>`
  position: absolute;
  top: ${(props) => props.top}px;
  right: -4px;
  border-top: 4px solid #bbb;
  border-bottom: 4px solid #bbb;
  background: #f5f5f5;
  height: 60px;
  width: 30px;
`

/** @jsx jsx */
import * as d3 from "d3"
import {getTotal} from "../util/functions"
import {BaseDrawFunctionOptions} from "../types"

function wrapText(text: any, width: number) {
  text.each(function (this: any) {
    const text = d3.select(this)
    const words = text.text().split(/\s+/).reverse()
    let word
    let line = [] as any[]
    let lineNumber = 0
    const lineHeight = 1.1 // ems
    const x = text.attr("x")
    const y = text.attr("y")
    const dy = 0 //parseFloat(text.attr("dy")),
    let tspan = text
      .text(null)
      .append("tspan")
      .attr("x", x)
      .attr("y", y)
      .attr("dy", dy + "em")
    while ((word = words.pop())) {
      line.push(word)
      tspan.text(line.join(" "))
      const node = tspan.node()
      if (!node) continue
      if (node.getComputedTextLength() > width) {
        line.pop()
        tspan.text(line.join(" "))
        line = [word]
        tspan = text
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .attr("dx", "0.5em")
          .text(word)
      }
    }
  })
}

const colors = ["#6665dd", "#225ee8", "#feaf01", "#4ed9de"]
const grays = ["#5B5B5B", "#737373", "#B4B4B4", "#B0B0B0"]

const drawTreemap = (
  selection: d3.Selection<SVGElement, any, any, any>,
  data: any,
  container: {width: number; height: number},
  options: BaseDrawFunctionOptions
) => {

  selection.selectAll("*").remove()

  const trendPadding = options.dataTrends ? 25 : 0

  data = options.dataTrends
    ? {
        today: data.today
          .filter((d) => d.value > getTotal(data.today) * 0.025)
          .sort((a, b) => b.value - a.value),
        lastMonth: data.lastMonth
          .filter((d) => d.value > getTotal(data.lastMonth) * 0.025)
          .sort((a, b) => b.value - a.value),
      }
    : data
        .filter((d) => d.value > getTotal(data) * 0.025)
        .sort((a, b) => b.value - a.value)

  const tree = d3
    .hierarchy({
      children: options.dataTrends ? data.today : data,
      value: 0,
    })
    .sum((d) => d.value ** 0.5)

  const lastMonthTree =
    options.dataTrends &&
    d3
      .hierarchy({
        children: data.lastMonth,
        value: 0,
      })
      .sum((d) => d.value ** 0.5)

  d3
    .treemap()
    .size([
      container.width - trendPadding,
      options.dataTrends ? container.height * 0.66 : container.height,
    ])
    .padding(2)(tree)

  options.dataTrends &&
    d3
      .treemap()
      .size([
        container.width - trendPadding,
        options.dataTrends ? container.height * 0.33 : container.height,
      ])
      .padding(2)(lastMonthTree)

  selection
    .selectAll("rect.today")
    .data(tree.leaves())
    .enter()
    .append("rect")
    .attr("class", "today")
    .attr("x", function (d: any) {
      return d.x0 + trendPadding
    })
    .attr("y", function (d: any) {
      return d.y0
    })
    .attr("width", function (d: any) {
      return d.x1 - d.x0
    })
    .attr("height", function (d: any) {
      return d.y1 - d.y0 //- (options.dataTrends ? (container.height*0.66) : 0)
    })
    .style("fill", function (d: any, i): any {
      return colors[
        Math.floor(
          i *
            (colors.length /
              (options.dataTrends ? data.today.length : data.length))
        )
      ]
    })

  options.dataTrends &&
    selection
      .selectAll("rect.lastMonth")
      .data(lastMonthTree.leaves())
      .enter()
      .append("rect")
      .attr("class", "lastMonth")
      .attr("x", function (d: any) {
        return d.x0 + trendPadding
      })
      .attr("y", function (d: any) {
        return container.height * 0.66 //d.y0
      })
      .attr("width", function (d: any) {
        return d.x1 - d.x0
      })
      .attr("height", function (d: any) {
        return d.y1 - d.y0 //- (options.dataTrends ? (container.height*0.66) : 0)
      })
      .style("fill", function (d: any, i): any {
        return grays[Math.floor(i * (grays.length / data.lastMonth.length))]
      })

  selection
    .selectAll("text.today")
    .data(tree.leaves())
    .enter()
    .append("text")
    .attr("class", "today")
    .attr("x", function (d: any) {
      return d.x0 + 5 + trendPadding
    })
    .attr("y", function (d: any) {
      return d.y0 + 20
    })
    .attr("dy", "0.8em")
    .attr("dx", "0.5em")
    .text(function (d: any) {
      return d.data.label
    })
    .attr("font-size", "13px")
    .attr("fill", "white")
    .call(wrapText, 75)
    .append("tspan")
    .attr("font-size", "18px")
    .attr("x", function (d: any) {
      return d.x0 + 5 + trendPadding
    })
    .attr("y", function (d: any) {
      return d.y0 + 35
    })
    .attr("dy", "1em")
    .attr("dx", "0.4em")
    .text(function (d: any) {
      return (
        Math.round(
          (d.data.value / getTotal(options.dataTrends ? data.today : data)) *
            100
        ) + "%"
      )
    })
    .append("tspan")
    .attr("font-size", "12px")
    .attr("x", function (d: any) {
      return d.x0 + 5 + trendPadding
    })
    .attr("y", function (d: any) {
      return d.y0 + 70
    })
    .attr("dy", "1em")
    .attr("dx", "0.4em")
    .text(function (d: any) {
      return options.dataTrends ? "+5% vs last week" : ""
    })

  options.dataTrends &&
    selection
      .selectAll("text.lastMonth")
      .data(lastMonthTree.leaves())
      .enter()
      .append("text")
      .attr("class", "lastMonth")
      .attr("x", function (d: any) {
        return d.x0 + 5 + trendPadding
      })
      .attr("y", function (d: any) {
        return container.height * 0.66 + 20
      })
      .attr("dy", "0.8em")
      .attr("dx", "0.5em")
      .text(function (d: any) {
        return d.data.label
      })
      .attr("font-size", "13px")
      .attr("fill", "white")
      .call(wrapText, 75)
      .append("tspan")
      .attr("font-size", "18px")
      .attr("x", function (d: any) {
        return d.x0 + 5 + trendPadding
      })
      .attr("y", function (d: any) {
        return container.height * 0.66 + 35
      })
      .attr("dy", "1em")
      .attr("dx", "0.4em")
      .text(function (d: any) {
        return (
          Math.round(
            (d.data.value / getTotal(options.dataTrends ? data.today : data)) *
              100
          ) + "%"
        )
      })

  if (options.dataTrends) {
    selection.append("text")
      .text("Today")
      .attr("font-size", "12px")
      .attr("x", 100)
      .attr("transform", "rotate(-90,120,100)")

    selection.append("text")
      .text("Last Month")
      .attr("font-size", "12px")
      .attr("x", -80)
      .attr("transform", "rotate(-90,120,100)")
  }
}

export default drawTreemap

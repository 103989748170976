import {createStyles, makeStyles, Theme} from "@material-ui/core"
import {fade} from "@material-ui/core/styles/colorManipulator"

import {COLORS} from "../../styles/theme"

export type ColourOptions = "blue" | "purple" | "yellow"

const getIconContainerColour = (colour: string) => fade(colour, 0.1)

export const getHexColourFromProp = (colour: ColourOptions) => COLORS[colour]

export default makeStyles((theme: Theme) =>
  createStyles({
    card: {
      alignItems: "center",
      borderLeft: `1px solid ${theme.palette.border.main}`,
      borderRadius: 0,
      boxSizing: "border-box",
      display: "flex",
      height: "100%",
      padding: theme.spacing(5),
    },
    iconContainer: {
      alignItems: "center",
      borderRadius: theme.spacing(4.5),
      display: "flex",
      justifyContent: "center",
      minHeight: theme.spacing(16),
      minWidth: theme.spacing(16),
    },
    blue: {
      backgroundColor: getIconContainerColour(COLORS.blue),
    },
    purple: {
      backgroundColor: getIconContainerColour(COLORS.purple),
    },
    yellow: {
      backgroundColor: getIconContainerColour(COLORS.yellow),
    },
    title: {
      fontSize: "15px",
    },
    value: {
      fontSize: "3.4rem",
      fontWeight: theme.typography.fontWeightLight,
    },
  })
)

import React from "react"

import Graph from "./graph"
import {LineChartOptions, SupportedTooltipIcons} from "../types"

export interface LineChartProps {
  currency?: boolean
  data: any
  id: string
  options: LineChartOptions
  tooltipIcon: SupportedTooltipIcons
}

const LineChart: React.FC<LineChartProps> = ({data, id, options}) => {
  return (
    <>
      {!!data.length && (
        <Graph data={data} field="value" options={options} id={id} />
      )}
    </>
  )
}

export default LineChart

import React, {useEffect, useState, useRef} from "react"
import {Box, Typography} from "@material-ui/core"

import {formatDistanceToNow} from "date-fns/esm"

import useComponentSize from "@rehooks/component-size"
import {
  Image,
  InnerContainer,
  List,
  ListItem,
  OuterContainer,
  Scrollable,
  Title,
} from "./LatestVisits.styles"
import LiveDataBadge from "../../../components/LiveDataBadge/LiveDataBadge"

export interface LatestVisitsProps {
  lastUpdated: string
  occupancy: any[]
}

const LatestVisitItem: React.FC<{car: any}> = React.memo(({car}) => (
  <ListItem>
    <Box display="flex" p={4} width="100%" boxSizing="border-box">
      <Box alignItems="center" display="flex">
        <Image
          backgroundImage={`url("/car_logos/${car.make
            .toLowerCase()
            .replace(/ /g, "-")}")`}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        justifyContent="center"
        p={2}
      >
        <Typography color="textPrimary" variant="body2">
          {car.make} {car.model}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {car.fuel} {car.type}
        </Typography>
      </Box>
      <Box alignItems="center" display="flex" p={2}>
        <Typography color="textSecondary" variant="body2">
          {formatDistanceToNow(car.arrivedAt)} ago
        </Typography>
      </Box>
    </Box>
  </ListItem>
))

const LatestVisits: React.FC<LatestVisitsProps> = React.memo(
  ({lastUpdated, occupancy}) => {
    const [, setNonce] = useState(0)
    const containerRef = useRef(null)
    const containerSize = useComponentSize(containerRef)

    useEffect(() => {
      // refresh every 10 minutes
      const interval = setInterval(() => {
        setNonce(Math.random())
      }, 60 * 1000)
      return () => clearInterval(interval)
    }, [])

    return (
      <OuterContainer ref={containerRef}>
        <InnerContainer>
          <Title>
            <Typography variant="subtitle2">Latest arrivals</Typography>
            <LiveDataBadge lastUpdatedAt={lastUpdated} />
          </Title>
          <Scrollable>
            <List>
              {occupancy.slice(0, 30).map((car: any, i: number) => (
                <LatestVisitItem key={i} car={car} />
              ))}
            </List>
          </Scrollable>
        </InnerContainer>
      </OuterContainer>
    )
  }
)

export default LatestVisits

import {startOfDay, subDays, addHours} from "date-fns"

export const addOrdinalSuffix = (i: number): string => {
  const j = i % 10
  const k = i % 100
  if (j === 1 && k !== 11) {
    return i + "st"
  }
  if (j === 2 && k !== 12) {
    return i + "nd"
  }
  if (j === 3 && k !== 13) {
    return i + "rd"
  }
  return i + "th"
}

export const fillMissingDates = (data: {hour: Date; value: number}[]) => {
  let hour = startOfDay(subDays(new Date(), 60))
  let result = []
  let i = 0
  while (i < data.length) {
    if (data[i].hour <= hour) {
      result.push(data[i])
      i++
    } else {
      result.push({hour, value: 0})
    }
    hour = addHours(hour, 1)
  }
  return result
}

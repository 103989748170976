import React from "react"
import {List, ListItem, Paper} from "@material-ui/core"

import LocationListItem from "../LocationListItem/LocationListItem"
import useStyles from "./LocationList.styles"

export interface LocationListProps {
  locations: {
    carParkId: string
    address: string
    capacity: number
    currentUsage: number
    image?: string
    lastUpdatedAt: string
    name: string
  }[]
}

const LocationList: React.FC<LocationListProps> = ({locations}) => {
  const classes = useStyles()

  return (
    <Paper classes={{root: classes.listContainer}}>
      <List disablePadding>
        {locations.map((location, i) => (
          <ListItem
            key={`locationListItem_${i}`}
            classes={{root: classes.listItem}}
            disableGutters
          >
            <LocationListItem {...location} />
          </ListItem>
        ))}
      </List>
    </Paper>
  )
}

export default LocationList

import React from "react"
import {
  Box,
  createStyles,
  makeStyles,
  SvgIconTypeMap,
  Theme,
  Typography,
} from "@material-ui/core"
import {COLORS} from "../../styles/theme"
import {OverridableComponent} from "@material-ui/core/OverridableComponent"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: theme.spacing(1.25),
      backgroundColor: theme.palette.secondary.light,
      padding: theme.spacing(5, 10),
    },
    inlineIcon: {
      display: "inline-flex",
      marginRight: theme.spacing(1),
      verticalAlign: "middle",
    },
  })
)

interface CarParkPageInsightCardProps {
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>
  text: string
  title: string
}

const CarParkPageInsightCard: React.FC<CarParkPageInsightCardProps> = ({
  icon: Icon,
  text,
  title,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Typography color="textPrimary" gutterBottom variant="h4">
        {Icon && (
          <Icon className={classes.inlineIcon} htmlColor={COLORS.purple} />
        )}
        {title}
      </Typography>
      <Typography color="textSecondary" paragraph variant="subtitle1">
        {text}
      </Typography>
    </Box>
  )
}

export default CarParkPageInsightCard

import React, {useRef} from "react"
import moment from "moment"

import LineGraph from "../../../../components/graphs/LineChart/LineChart"
import {COLORS} from "../../../../styles/theme"
import revenueData from "../revenue/revenueData"
import {RichTooltip} from "../../../../components/graphs"

export default () => {
  const tooltipRef = useRef()

  return (
    <>
      <RichTooltip ref={tooltipRef} />
      <LineGraph
        data={revenueData}
        id="pollution"
        options={{
          legend: [
            {colour: COLORS.blue, text: "Today", type: "dot"},
            {colour: "#CCCCCC", text: "Last week", type: "dot"},
            {colour: "#86889E", text: "Last month", type: "dot"},
          ],
          margin: {top: 20},
          setTooltipValue: (d) => ({
            rows: [
              {text: moment(new Date()).format("MMM, D YYYY"), icon: "date"},
              {text: `${d.value[0].value} AQI`, icon: "eco"},
            ],
            trend: {
              adjustment: 5,
              since: "last week",
            },
          }),
          tooltipRef,
          xAxisLabel: "Time",
          yAxisLabel: "AQI",
        }}
        tooltipIcon="index"
      />
    </>
  )
}

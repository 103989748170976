import React from "react"

import D3Wrapper from "../common/D3Wrapper"
import {BarChartOptions} from "../types"
import draw from "./draw"

export interface VerticalBarChartProps {
  data: any[] | { today: any[], lastWeek: any[], lastMonth: [] }
  options: BarChartOptions
}

const VerticalBarChart: React.FC<VerticalBarChartProps> = ({data, options}) => (
  <D3Wrapper draw={draw} data={data} options={options} />
)

export default VerticalBarChart

import {intervalToDuration, parseISO} from "date-fns"

import theme from "../styles/theme"

export const getIntervalToNow = (date: Date) =>
  intervalToDuration({
    start: date,
    end: new Date(),
  })

// TODO: Potentially use https://date-fns.org/v1.29.0/docs/distanceInWordsStrict
export const generateIntervalString = (dateString: string): string => {
  const date = parseISO(dateString)
  const {days, hours, minutes, months, years} = getIntervalToNow(date)

  if (years && years > 0) return `${years} year`
  if (months && months > 0) return `${months} month`
  if (days && days > 0) return `${days} day`
  if (hours && hours > 0) return `${hours} hour`
  if (minutes && minutes > 1) return `${minutes} min`

  return "Live"
}

export const getTimeIconColour = (lastUpdatedAtString: string): string => {
  const lastUpdatedAt = parseISO(lastUpdatedAtString)
  const {days, hours, minutes, months, years} = getIntervalToNow(lastUpdatedAt)

  if (days || months || years) return theme.palette.warning.main
  if (hours || (minutes && minutes > 1)) return theme.palette.text.secondary
  return theme.palette.success.main
}

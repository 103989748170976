import React from "react"

import D3Wrapper from "../common/D3Wrapper"
import {BaseDrawFunctionOptions} from "../types"
import draw from "./draw"

export interface TreeMapProps {
  data: any
  options?: BaseDrawFunctionOptions
}

const TreeMap: React.FC<TreeMapProps> = ({data, options}) => (
  <D3Wrapper draw={draw} data={data} options={options} />
)

export default TreeMap

import React, {useRef, useEffect, useState} from "react"
import * as d3 from "d3"
import {Box} from "@material-ui/core"

import {drawLegend, getMargins} from "../utils"
import useResizeObserver from "../util/resize"
import {getGraphColor} from "../util/functions"
import theme from "../../../styles/theme"
import {Container} from "./LineChart.styles"

import "./style.css"

export default ({data, field, id, options}) => {
  const firstItemVal = data[0][field]

  // set min/max values
  const maxDate = new Date(
    Math.max.apply(
      Math,
      data.map((item) => item.date)
    )
  )
  const minDate = new Date(
    Math.min.apply(
      Math,
      data.map((item) => item.date)
    )
  )

  // setState
  const [selected, setSelected] = useState(null)
  const [currentZoomState, setCurrentZoomState] = useState()

  const svgRef = useRef()
  const wrapperRef = useRef()
  const xDomainRef = useRef([minDate, maxDate])
  const dimensions = useResizeObserver(wrapperRef)

  // set before useEffect runs
  const {width, height} = dimensions ||
    (svgRef && svgRef.current && svgRef.current.getBoundingClientRect()) || {
      width: 700,
      height: 520,
    }
  const margins = getMargins(options)

  const svgHeight = svgRef.current ? svgRef.current.clientHeight : height

  useEffect(() => {
    const svg = d3.select(svgRef.current)
    let svgContent = svg.select(`.${id}`)

    // Make sure ZOOM comes first in order for Y re-scalling to work!!
    if (currentZoomState) {
      const newXScale = currentZoomState.rescaleX(xScale)
      xDomainRef.current = newXScale.domain()
      xScale.domain(newXScale.domain())
    }

    // Set svg sizes
    const xAxis = d3
      .axisBottom(xScale)
      .ticks(7)
      .tickSize(0)
      .tickPadding(8)
      .tickFormat(
        d3.timeFormat(
          maxDate - minDate > 2 * 24 * 60 * 60 * 1000 ? "%b %d" : "%H:%M"
        )
      )
    const yAxis = d3.axisLeft(yScale).tickSize(0).tickPadding(8).ticks(5)

    svg
      .select(".y-axis")
      .call(yAxis)
      .style("font", `12px ${theme.typography.fontFamily}`)
      .style("color", theme.palette.text.secondary)

    svg
      .select(".x-axis")
      .attr("transform", `translate(0, ${svgHeight})`)
      .call(xAxis)
      .style("font", `12px ${theme.typography.fontFamily}`)
      .style("color", theme.palette.text.secondary)

    // set Gridlines
    svg.selectAll(".grid").remove()
    // add the X gridlines
    svgContent
      .append("g")
      .classed(".x-axis", true)
      .classed("grid", true)
      .attr("transform", `translate(0, ${svgHeight})`)
      .call(d3.axisBottom(xScale).ticks(10).tickSize(-svgHeight).tickFormat(""))

    // add the Y gridlines
    svgContent
      .append("g")
      .classed(".y-axis", true)
      .classed("grid", true)
      .call(d3.axisLeft(yScale).ticks(5).tickSize(-width).tickFormat(""))

    // remove old dots and lines
    svg.selectAll(`.dots`).remove()
    svg.selectAll(`.areas`).remove()
    svg.selectAll(`.line`).remove()

    firstItemVal.forEach((_, index) => {
      const singleLineData = data
        .map((item) => ({
          date: item.date,
          value: item.value[index].value,
        }))
        .filter((item) => item.value !== null)
      const color = getGraphColor(index)
      svgContent
        .selectAll(`.line${index}`)
        .data([singleLineData])
        .join("path")
        .attr("class", "line")
        .attr("stroke", color)
        .attr("fill", "none")
        .attr("stroke-width", index === 0 ? 2 : 1)
        .attr(
          "d",
          d3
            .line()
            .x((d) => xScale(d.date))
            .y((d) => yScale(d.value))
        )

      svgContent
        .selectAll(`.smallDot${index}`)
        .data(data)
        .join("circle")
        .attr("class", (_, i) => `value${index}Dot${i} dots`)
        .attr("stroke", "transparent")
        .attr("r", 4)
        .attr("fill", "transparent")
        .attr("cx", (d) => xScale(d.date))
        .attr("cy", (d) => yScale(d[field][index].value))

      svgContent
        .selectAll(`.dotArea${index}`)
        .data(data)
        .join("circle")
        .attr("class", (_, i) => `value${i}Area areas`)
        .attr("r", 15)
        .attr("fill", "transparent")
        .attr("x", (_, i) => xScale(i))
        .attr("y", (d) => yScale(d[field][index].value))
        .attr("cx", (d) => xScale(d.date))
        .attr("cy", (d) => yScale(d[field][index].value))
        .on("mouseover", (d, i, el) => {
          if (index !== 0) return

          d3.select(`.${id}`).selectAll(`.dots`).style("fill", "transparent")
          d3.select(this).style("cursor", "pointer")
          d3.select(`.${id}`)
            .select(`.value${index}Dot${i}`)
            .style("fill", color)
          setSelected(d)

          if (!options || !options.tooltipRef) return

          const containerXY = svg.node().getBoundingClientRect()
          const rectXY = el[i].getBoundingClientRect()

          options.tooltipRef.current.setData({
            shouldShow: true,
            value: (options?.setTooltipValue && options.setTooltipValue(d)) || {
              rows: [{text: `${d.today} Vehicles`, icon: "car"}],
              trend: {
                adjustment: 5,
                since: "last week",
              },
            },
            x: rectXY.x - containerXY.x + rectXY.width - 20,
            y: rectXY.y - containerXY.y + 30,
          })
        })
        .on("mouseout", () => {
          d3.select(`.${id}`).selectAll(`.dots`).style("fill", "transparent")
          if (!options || !options.tooltipRef) return
          options.tooltipRef.current.setData({shouldShow: false})
        })
    })

    const zoomBehavior = d3
      .zoom()
      .scaleExtent([1, 5])
      .translateExtent([
        [0, 0],
        [width, height],
      ])
      .on("zoom", () => {
        const zoomState = d3.zoomTransform(svg.node())
        setCurrentZoomState(zoomState)
      })

    svg.call(zoomBehavior)

    // x-axis value labels
    svg
      .select(".x-axis")
      .selectAll("text")
      .style("text-anchor", "middle")
      .attr("dy", "1em")

    {
      options?.legend && drawLegend(options.legend, svg, height, margins)
    }
  }, [currentZoomState, data, dimensions, selected])

  const [minStamp, maxStamp] = [minDate, maxDate].map(
    (date) => date.getTime() / 1000
  )
  const xDomain = [minStamp, maxStamp].map((d) => new Date(d * 1000))
  const xScale = d3.scaleTime().domain(xDomain).range([0, width])

  const maxVal = Math.max.apply(
    Math,
    data.map((item) =>
      Math.max.apply(
        Math,
        item[field].map((i) => i.value)
      )
    )
  )

  const yScale = d3
    .scaleLinear()
    .domain([0, maxVal])
    .range([svgHeight, 0])
    .nice()

  return (
    <Container
      onMouseLeave={() => {
        setSelected(null)
      }}
    >
      <Box boxSizing="border-box" height="100%">
        <div ref={wrapperRef} style={{height: "100%"}}>
          <svg
            ref={svgRef}
            style={{
              overflow: "visible",
            }}
            className="graph"
          >
            <defs>
              <clipPath id={id}>
                <rect x="0" y="0" width={width} height={height} />
              </clipPath>
            </defs>
            <g className={id} clipPath={`url(#${id})`}></g>
            <g className="x-axis" />
            <g className="y-axis" />
            <text
              textAnchor="middle"
              x={width / 2}
              y={height + 40}
              style={{
                font: `12px ${theme.typography.fontFamily}`,
                color: theme.palette.text.secondary,
              }}
            >
              {options?.xAxisLabel || null}
            </text>
            <text
              textAnchor="middle"
              y={-40}
              x={-height / 2}
              transform="rotate(-90)"
              style={{
                font: `12px ${theme.typography.fontFamily}`,
                color: theme.palette.text.secondary,
              }}
            >
              {options?.yAxisLabel || null}
            </text>
          </svg>
        </div>
      </Box>
    </Container>
  )
}

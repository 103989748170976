import React from "react"
import {Box} from "@material-ui/core"
import clsx from "clsx"

import useStyles from "./CurrentUsageBar.styles"

export interface CurrentUsageBarProps {
  className?: string
  currentUsage: number
}

const CurrentUsageBar: React.FC<CurrentUsageBarProps> = ({
  className,
  currentUsage,
}) => {
  const classes = useStyles({currentUsage})
  return (
    <>
      <Box className={clsx(classes.background, className)}>
        <Box className={classes.foreground} />
      </Box>
    </>
  )
}

export default CurrentUsageBar

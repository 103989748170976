import React, {useState, useRef} from "react"
import {useParams} from "react-router-dom"
import {Box} from "@material-ui/core"
import {AssignmentOutlined, CommentOutlined} from "@material-ui/icons"
import {getDay, getHours} from "date-fns"

import Layout from "../../Layout"
import CarParkOccupancyViz from "./OccupancyChart/OccupancyChart"
import CarParkHeader from "../CarParkHeader"
import useCarParkData from "../useCarParkData"
import LatestVisits from "../LatestVisits/LatestVisits"
import CarParkPageInsightCard from "../CarParkPageInsightCard"
import CarParkHighlights from "../CarParkHighlights/CarParkHighlights"
import {Loader} from "../../../components/Loader"
import theme from "../../../styles/theme"
import DateSelector from "../../../components/DateSelector/DateSelector"
import {FunnelChart, HeatMap, RichTooltip} from "../../../components/graphs"
import {InsightsContainer, MultiItemRow} from "./CarParkPageOverview.styles"
import {fillMissingDates} from "./utils"
import {frequencyOfVisits} from "../mockData"
import GraphContainer from "../../../components/graphs/common/GraphContainer"
import PopularTimesChart from "./PopularTimesChart/PopularTimesChart"
import VisitorsOriginsMap from "./VisitorsOriginsMap/VisitorsOriginsMap"

const PopularTimesSection: React.FC<{
  breakdown: {
    occupancy: {hour: Date; value: number}[]
    movement: {hour: Date; value: number}[]
  }
}> = ({breakdown}) => {
  const occupancy = fillMissingDates(breakdown.occupancy)
  const weekdayTotals = new Array(7).fill(0)
  const weekdayCounts = new Array(7).fill(0)
  const hourTotals = new Array(24).fill(0)
  const hourCounts = new Array(24).fill(0)
  const tooltipRef = useRef()
  const heatmapTooltipRef = useRef()

  occupancy.forEach(({hour, value}) => {
    const i = getDay(hour)
    weekdayTotals[i] += value
    weekdayCounts[i] += 1
    const j = getHours(hour)
    hourTotals[j] += value
    hourCounts[j] += 1
  })

  return (
    <Box>
      <Box borderBottom="1px solid #CCC" display="flex" height={400}>
        <GraphContainer
          info="This graph displays the number of vehicles that have entered the car park for a selected date."
          lastUpdated={new Date().toISOString()}
          title="Occupancy Heatmap"
        >
          <RichTooltip ref={heatmapTooltipRef} />
          <HeatMap
            data={occupancy}
            options={{
              tooltipRef: heatmapTooltipRef,
              xAxisLabel: "Day",
              yAxisLabel: "Time",
            }}
          />
        </GraphContainer>
      </Box>
      <Box borderBottom="1px solid #CCC" display="flex" height={500}>
        <GraphContainer
          info="This graph displays the number of vehicles that have entered the car park for a selected date."
          lastUpdated={new Date().toISOString()}
          title="Popular Times"
        >
          <RichTooltip ref={tooltipRef} />
          <PopularTimesChart occupancy={occupancy} tooltipRef={tooltipRef} />
        </GraphContainer>
      </Box>
    </Box>
  )
}

export default function CarParkPageOverview() {
  const {carParkId} = useParams<{carParkId: string}>()
  const [selectedDate] = useState(new Date())
  const {loading, data} = useCarParkData({selectedDate, carParkId})

  if (loading || !data) return <Loader />

  const {carpark, occupancy, breakdown} = data

  return (
    <Layout fixedWidth>
      <CarParkHeader carParkId={carParkId} currentPage="overview" />
      <CarParkHighlights
        image={carpark.image}
        address={carpark.siteName}
        capacity={carpark.capacity}
        currentUsage={(carpark.currentOccupancy / carpark.capacity) * 100}
        lastUpdatedAt={new Date().toISOString()}
        name={carpark.carParkName}
      />
      <InsightsContainer>
        <CarParkPageInsightCard
          icon={AssignmentOutlined}
          text="Hey, So far today today you've received 20% less visitors than your
        average over the last 60 days. However, people are staying for 35%
        longer."
          title="Performance summary"
        />
        <CarParkPageInsightCard
          icon={CommentOutlined}
          text="Did you know 610 electric vehicles have visited your car park in the last 60 days. You may want to
            consider installing an electric vehicle charging point."
          title="NexusRecommends®"
        />
      </InsightsContainer>
      <Box display="flex">
        <Box
          borderTop="1px solid #CCC"
          flexBasis={0}
          flexGrow={1}
          gridArea="liveView"
        >
          <GraphContainer
            info="This graph displays the number of vehicles that have entered the car park for a selected date."
            lastUpdated={new Date().toISOString()}
            title="Current Occupancy"
          >
            <CarParkOccupancyViz carpark={carpark} occupancy={occupancy} />
          </GraphContainer>
        </Box>
        <Box width={400}>
          <LatestVisits
            lastUpdated={new Date().toISOString()}
            occupancy={occupancy.filter((c: any) => c.type).reverse()}
          />
        </Box>
      </Box>
      <Box
        alignItems="center"
        bgcolor={theme.palette.secondary.light}
        border={`1px solid ${theme.palette.border.main}`}
        display="flex"
        justifyContent="center"
        p={7}
      >
        <DateSelector currentDate={new Date()} />
      </Box>
      <PopularTimesSection breakdown={breakdown} />
      <MultiItemRow>
        <Box borderRight="1px solid #CCC">
          <GraphContainer
            info="This graph displays the number of vehicles that have entered the car park for a selected date."
            lastUpdated={new Date().toISOString()}
            title="Frequency of Visits"
          >
            <FunnelChart data={frequencyOfVisits} />
          </GraphContainer>
        </Box>
        <Box>
          <GraphContainer
            info="Visitors have been to these locations"
            lastUpdated={new Date().toISOString()}
            title="Visitors have been to these locations"
          >
            <VisitorsOriginsMap
              initialLatitude={51.67}
              initialLongitude={-1.64}
              initialZoom={6}
            />
          </GraphContainer>
        </Box>
      </MultiItemRow>
    </Layout>
  )
}

import React from "react"
import {ThemeProvider, Button} from "@material-ui/core"
import {BrowserRouter as Router, Switch, Route} from "react-router-dom"

import theme from "./styles/theme"
import MapPage from "./pages/MapPage/MapPage"
import CarParkPageOverview from "./pages/CarParkPage/CarParkPageOverview/CarParkPageOverview"
import CarParkPageRevenue from "./pages/CarParkPage/CarParkPageRevenue/CarParkPageRevenue"
import CarParkPageSustainability from "./pages/CarParkPage/CarParkPageSustainability/CarParkPageSustainability"
import CarParkPageVehicles from "./pages/CarParkPage/CarParkPageVehicles/CarParkPageVehicles"
import ChartsPage from "./pages/ChartsPage/ChartsPage"
import SettingsPage from "./pages/SettingsPage/SettingsPage"

const {PUBLIC_URL} = process.env

const Login = () => {
  return (
    <div className="center-page">
      <Button
        component="a"
        href={`${PUBLIC_URL}/api/auth/login`}
        variant="contained"
        color="primary"
      >
        Login
      </Button>
    </div>
  )
}

const App = () => (
  <Router>
    <ThemeProvider theme={theme}>
      <div className="App">
        <Switch>
          <Route path={`${PUBLIC_URL || "/"}`} exact>
            <Login />
          </Route>
          <Route path={`${PUBLIC_URL}/dashboard`}>
            <MapPage />
          </Route>
          <Route path={`${PUBLIC_URL}/charts`}>
            <ChartsPage />
          </Route>
          <Route path={`${PUBLIC_URL}/settings`}>
            <SettingsPage />
          </Route>
          <Route exact path={`${PUBLIC_URL}/carpark/:carParkId`}>
            <CarParkPageOverview />
          </Route>
          <Route path={`${PUBLIC_URL}/carpark/:carParkId/revenue`}>
            <CarParkPageRevenue />
          </Route>
          <Route path={`${PUBLIC_URL}/carpark/:carParkId/sustainability`}>
            <CarParkPageSustainability />
          </Route>
          <Route path={`${PUBLIC_URL}/carpark/:carParkId/vehicles`}>
            <CarParkPageVehicles />
          </Route>
        </Switch>
      </div>
    </ThemeProvider>
  </Router>
)

export default App

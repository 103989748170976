import React from "react"
import clsx from "clsx"
import {Box, Card, SvgIconTypeMap, Typography} from "@material-ui/core"
import {OverridableComponent} from "@material-ui/core/OverridableComponent"
import useStyles, {
  ColourOptions,
  getHexColourFromProp,
} from "./OverviewCard.styles"

export interface OverviewCardProps {
  colour: ColourOptions
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
  title: string
  value: string | number
}

const OverviewCard: React.FC<OverviewCardProps> = ({
  colour,
  icon: Icon,
  title,
  value,
}) => {
  const classes = useStyles()

  return (
    <Card classes={{root: classes.card}} elevation={0}>
      <Box className={clsx(classes.iconContainer, classes[colour])} mr={2}>
        <Icon htmlColor={getHexColourFromProp(colour)} />
      </Box>
      <Box flexDirection="column">
        <Typography className={classes.title} variant="overline">
          {title}
        </Typography>
        <Typography className={classes.value}>{value}</Typography>
      </Box>
    </Card>
  )
}

export default OverviewCard

import {isLessThanXHoursAgo} from "../../../../utils/functions"

export const COLORS = ["#3fbcd8", "#359cdd", "#2c7fe2", "#225ee8", "#4d62e1"]

// null
// Diesel
// Diesel/Hybrid Electric
// Electric
// Petrol
// Petrol/Bio-Ethanol
// Petrol/CNG
// Petrol/Hybrid Electric
// Petrol/LPG
export const getElectric = (input: string) => {
  if (!input) return "NO"
  if (input === "Electric") return "YES"
  if (input.includes("/")) return "HYBRID"
  return "NO"
}

const getCarPercentageByFilter = (
  cars: any[],
  filterFunc: (car: any) => boolean
) => {
  const filteredCars = cars.filter((car) => filterFunc(car))
  const percentage = (filteredCars.length / cars.length) * 100
  return Math.round(percentage)
}

export const getArrivalTimeLegendData = (cars: any[]) => {
  return [
    {
      percentage: getCarPercentageByFilter(cars, (car) =>
        isLessThanXHoursAgo(car.arrivedAt, 1)
      ),
      text: "Arrived less than an hour ago",
    },
    {
      percentage: getCarPercentageByFilter(
        cars,
        (car) =>
          !isLessThanXHoursAgo(car.arrivedAt, 1) &&
          isLessThanXHoursAgo(car.arrivedAt, 6)
      ),
      text: "Arrived less than 6 hours ago",
    },
    {
      percentage: getCarPercentageByFilter(
        cars,
        (car) =>
          !isLessThanXHoursAgo(car.arrivedAt, 6) &&
          isLessThanXHoursAgo(car.arrivedAt, 24)
      ),
      text: "Arrived less than 24 hours ago",
    },
    {
      percentage: getCarPercentageByFilter(
        cars,
        (car) =>
          !isLessThanXHoursAgo(car.arrivedAt, 24) &&
          isLessThanXHoursAgo(car.arrivedAt, 48)
      ),
      text: "Arrived less than 48 hours ago",
    },
    {
      percentage: getCarPercentageByFilter(
        cars,
        (car) => !isLessThanXHoursAgo(car.arrivedAt, 48)
      ),
      text: "Arrived over 48 hours ago",
    },
  ]
}

export const getElectricLegendData = (cars: any) => [
  {
    color: "limeGreen",
    percentage: getCarPercentageByFilter(
      cars,
      (car) => getElectric(car.fuel) === "YES"
    ),
    text: "Electric",
  },
  {
    color: "lightGreen",
    percentage: getCarPercentageByFilter(
      cars,
      (car) => getElectric(car.fuel) === "HYBRID"
    ),
    text: "Hybrid",
  },
  {
    color: "hsl(200, 65%, 50%)",
    percentage: getCarPercentageByFilter(
      cars,
      (car) => getElectric(car.fuel) === "NO"
    ),
    text: "Petrol / Diesel",
  },
]

export const sampleEvery = <T extends unknown>(arr: T[], n: number): T[] => {
  const next = [] as T[]
  for (let i = 0; i < arr.length; i += n) {
    next.push(arr[i])
  }
  return next
}

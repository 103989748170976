import * as d3 from "d3"
import theme, {COLORS} from "../../../styles/theme"
import {HorizontalBarChartOptions} from "../types"
import {drawLegend, getMargins} from "../utils"

const getBarColour = (d: any, options: HorizontalBarChartOptions): string => {
  if (!options || !options.shouldHighlightBarFunction) return COLORS.blue
  return options.shouldHighlightBarFunction(d) ? COLORS.aqua : COLORS.blue
}

const drawHorizontalBar: (
  selection: d3.Selection<SVGElement, any, any, any>,
  data: any[],
  container: {width: number; height: number},
  options: HorizontalBarChartOptions
) => void = (selection, data, container, options) => {
  const margins = getMargins(options)
  const width = container.width - margins.left - margins.right
  const height = container.height - margins.top - margins.bottom

  const {getX1, getX2} = options

  const xDomain = [
    Math.min(d3.min(data, getX1), d3.min(data, getX2)),
    Math.max(d3.max(data, getX1), d3.max(data, getX2)),
  ]
  const yDomain = data.map((d) => d.label)

  const xScale = d3
    .scaleLinear()
    .domain(xDomain)
    .range([margins.left, margins.left + width])
    .nice()

  const yScale = d3.scaleBand().domain(yDomain).range([0, height]).padding(0.5)

  const xAxis = d3.axisBottom(xScale).tickSize(0).tickPadding(13)
  const xAxisGrid = d3
    .axisBottom(xScale)
    .tickFormat(() => "")
    .tickSize(height)
  const yAxis = d3.axisLeft(yScale).tickSize(0).tickPadding(17)

  selection.selectAll("g.axis").remove()
  selection.selectAll("g.axis-grid").remove()
  selection.selectAll("text.x-label").remove()
  selection.selectAll("text.y-label").remove()

  // x axis
  selection
    .append("g")
    .style("font", `12px ${theme.typography.fontFamily}`)
    .style("color", theme.palette.text.secondary)
    .attr("class", "axis")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis)

  // y axis
  selection
    .append("g")
    .style("font", `12px ${theme.typography.fontFamily}`)
    .style("color", theme.palette.text.secondary)
    .attr("class", "axis")
    .attr("transform", "translate(" + margins.left + ",0)")
    .call(yAxis)

  // append the rectangles for the bar chart
  selection
    .selectAll(".bar1")
    .data(data)
    .enter()
    .append("rect")
    .attr("rx", options && options.skinnyBars ? theme.spacing(1) : 0)
    .attr("class", "bar1")
    .attr("fill", (d) => getBarColour(d, options))
    .attr("y", (d) => yScale(d.label) || NaN)
    .attr("height", yScale.bandwidth() / 2 - 2)
    .attr("x", margins.left)
    .attr("width", (d) => xScale(getX1(d)) - margins.left)
    .on("mouseover", (d, i, el) => {
      if (!options || !options.tooltipRef) return

      const containerXY = selection.node().getBoundingClientRect()
      const rectXY = (el[i] as Element).getBoundingClientRect()

      options.tooltipRef.current.setData({
        shouldShow: true,
        value: options?.setTooltipValue && options.setTooltipValue(d),
        x: rectXY.x - containerXY.x + rectXY.width - 20,
        y: rectXY.y - containerXY.y + 10,
      })
    })
    .on("mouseout", () => {
      if (!options || !options.tooltipRef) return
      options.tooltipRef.current.setData({shouldShow: false})
    })

  selection
    .selectAll(".bar2")
    .data(data)
    .enter()
    .append("rect")
    .attr("rx", options && options.skinnyBars ? theme.spacing(1) : 0)
    .attr("class", "bar2")
    .attr("fill", "#000")
    .attr("fill-opacity", "25%")
    .attr("y", (d) => yScale(d.label) + yScale.bandwidth() / 2 || NaN)
    .attr("height", yScale.bandwidth() / 2 - 2)
    .attr("x", margins.left)
    .attr("width", (d) => xScale(getX2(d)) - margins.left)

  // x axis grid lines
  selection
    .append("g")
    .style("color", theme.palette.border.main)
    .lower() // render the grid first so that everything else is painted above
    .attr("class", "x axis-grid")
    .call(xAxisGrid)

  selection.select("g.axis-grid > .domain").style("display", "none")

  options &&
    options.xAxisLabel &&
    selection
      .append("text")
      .attr("class", "x-label")
      .attr("text-anchor", "middle")
      .attr("x", width / 2 + margins.left)
      .attr("y", height + margins.top + 20)
      .text(options.xAxisLabel)
      .style("font", `12px ${theme.typography.fontFamily}`)
      .style("color", theme.palette.text.secondary)

  options &&
    options.yAxisLabel &&
    selection
      .append("text")
      .attr("class", "y-label")
      .attr("text-anchor", "middle")
      .attr("y", 20)
      .attr("x", -height / 2)
      .attr("transform", "rotate(-90)")
      .text(options.yAxisLabel)
      .style("font", `12px ${theme.typography.fontFamily}`)
      .style("color", theme.palette.text.secondary)

  if (options?.xTicksPerLabel && options?.xTicksPerLabel > 1) {
    d3.selectAll(".x.axis .tick text").each(function (_, i) {
      if (i % options.xTicksPerLabel !== 0) d3.select(this).remove()
    })
  }

  options?.legend && drawLegend(options.legend, selection, height, margins)
}

export default drawHorizontalBar

import differenceInMinutes from "date-fns/differenceInMinutes"

import {
  COLORS,
  getArrivalTimeLegendData,
  getElectric,
  getElectricLegendData,
} from "./utils"

export const useOccupanceMode = ({mode, cars}: {mode: string; cars: any[]}) => {
  switch (mode) {
    default:
    case "electric": {
      return {
        fill: (car: any) => {
          return {
            YES: "limegreen",
            HYBRID: "lightGreen",
            NO: "hsl(200, 65%, 50%)",
          }[getElectric(car.fuel)]
        },
        sortScore: (car: any) => {
          return {
            YES: 2,
            HYBRID: 1,
            NO: 0,
          }[getElectric(car.fuel)]
        },
        legendItems: getElectricLegendData(cars),
      }
    }
    case "arrivalTime": {
      return {
        fill: (car: any, i: number) => {
          const diff = differenceInMinutes(new Date(), car.arrivedAt)
          if (diff < 60) return COLORS[0]
          if (diff < 6 * 60) return COLORS[1]
          if (diff < 24 * 60) return COLORS[2]
          if (diff < 48 * 60) return COLORS[3]
          return COLORS[4]
        },
        sortScore: (car: any) => {
          return car.arrivedAt
        },
        legendItems: getArrivalTimeLegendData(cars),
      }
    }
  }
}

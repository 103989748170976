import React from "react"
import {Grid} from "@material-ui/core"
import {BarChart, PieChart, Schedule} from "@material-ui/icons"

import OverviewCard from "../../components/OverviewCard/OverviewCard"

const OverviewSection: React.FC<{
  totals: {
    totalVisits: number
    averageStay: number
    revenue: number
  }
}> = ({totals}) => (
  <Grid container>
    <Grid item xs={4}>
      <OverviewCard
        colour="blue"
        icon={Schedule}
        title="Visits today"
        value={totals.totalVisits
          .toFixed(0)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      />
    </Grid>
    <Grid item xs={4}>
      <OverviewCard
        colour="yellow"
        icon={PieChart}
        title="Average duration"
        value={totals.averageStay.toFixed(0) + " hours"}
      />
    </Grid>
    <Grid item xs={4}>
      <OverviewCard
        colour="purple"
        icon={BarChart}
        title="Estimated revenue"
        value={
          "£" + totals.revenue.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
      />
    </Grid>
  </Grid>
)

export default OverviewSection

import {Location} from "../types"

export const locationsToGeoJson = (locations: Location[]) => ({
  type: "FeatureCollection",
  features: locations.map((location) => {
    const {latitude, longitude, ...rest} = location
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [latitude, longitude],
      },
      properties: {...rest},
    }
  }),
})

import styled from "styled-components"

import theme from "../../../styles/theme"

export const Image = styled.div<{backgroundImage: string}>`
  background-color: "#E9E9E9";
  background-image: ${(props) => props.backgroundImage};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #bbb;
  border-radius: 50%;
  height: ${theme.spacing(13.5)}px;
  min-width: ${theme.spacing(13.5)}px;
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const List = styled.ul`
  position: absolute;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
`

export const ListItem = styled.li`
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #ccc;
`

export const OuterContainer = styled.div`
  border: 1px solid #ccc;
  height: 100%;
  position: relative;
`

export const Scrollable = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
`

export const Title = styled.div`
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 28px 20px;
  width: 100%;
`

import React, {useState} from "react"
import {useParams} from "react-router-dom"
import Layout from "../../Layout"
import CarParkHeader from "../CarParkHeader"
import useCarParkData from "../useCarParkData"
import {CommentOutlined, AssignmentOutlined} from "@material-ui/icons"
import {Box} from "@material-ui/core"
import styled from "styled-components"

import CarParkHighlights from "../CarParkHighlights/CarParkHighlights"
import {Loader} from "../../../components/Loader"
import theme from "../../../styles/theme"
import DateSelector from "../../../components/DateSelector/DateSelector"
import {
  GraphAreaContainer as Container,
  GraphRow as Row,
  GraphColumn as Column,
} from "../../../components/graphs/util/containers"
import RevenueGraph from "../Graphs/revenue/graph"
import PcnRevenueGraph from "../Graphs/pcnRevenue/graph"
import CarParkPageInsightCard from "../CarParkPageInsightCard"
import {BubbleChart, TreeMap} from "../../../components/graphs"
import {paymentMethodsData, paymentTypesData} from "../mockData"
import GraphContainer from "../../../components/graphs/common/GraphContainer"

const InsightsContainer = styled.div`
  display: flex;
  width: 100%;
  & > div {
    flex-basis: 0;
    flex-grow: 1;
    margin: 10px;
  }
`

export default function CarParkPageRevenue() {
  const {carParkId} = useParams<{carParkId: string}>()
  const [selectedDate] = useState(new Date())
  const {loading, data: carparkData} = useCarParkData({selectedDate, carParkId})

  if (loading || !carparkData) return <Loader />

  const bubbleChartColors = ["#225ee8", "#9c9cea", "#4ED9DE"]

  const {carpark} = carparkData

  return (
    <Layout fixedWidth>
      <CarParkHeader carParkId={carParkId} currentPage="revenue" />
      <CarParkHighlights
        image={carpark.image}
        address={carpark.siteName}
        capacity={carpark.capacity}
        currentUsage={(carpark.currentOccupancy / carpark.capacity) * 100}
        lastUpdatedAt={new Date().toISOString()}
        name={carpark.carParkName}
      />
      <InsightsContainer>
        <CarParkPageInsightCard
          icon={AssignmentOutlined}
          text="Hey, So far today today you've received 20% less visitors than your
        average over the last 60 days. However, people are staying for 35%
        longer."
          title="Performance summary"
        />
        <CarParkPageInsightCard
          icon={CommentOutlined}
          text="Did you know 610 electric vehicles have visited your car park in the last 60 days. You may want to
            consider installing an electric vehicle charging point."
          title="NexusRecommends®"
        />
      </InsightsContainer>
      <Box
        alignItems="center"
        bgcolor={theme.palette.secondary.light}
        border={`1px solid ${theme.palette.border.main}`}
        display="flex"
        justifyContent="center"
        p={7}
      >
        <DateSelector currentDate={new Date()} />
      </Box>
      <Box p={5}>
        <Container>
          <Row>
            <Column>
              <GraphContainer
                info="This graph displays the number of vehicles that have entered the car park for a selected date."
                lastUpdated={new Date().toISOString()}
                title="Revenue"
              >
                <RevenueGraph />
              </GraphContainer>
            </Column>
            <Column>
              <GraphContainer
                info="This graph displays the number of vehicles that have entered the car park for a selected date."
                lastUpdated={new Date().toISOString()}
                title="PCN Revenue"
              >
                <PcnRevenueGraph />
              </GraphContainer>
            </Column>
          </Row>
          <Row height={400}>
            <Column>
              <GraphContainer
                info="This graph displays the number of vehicles that have entered the car park for a selected date."
                lastUpdated={new Date().toISOString()}
                title="Payment Methods"
              >
                <BubbleChart
                  data={paymentMethodsData}
                  options={{
                    colors: bubbleChartColors,
                    padding: -20,
                    getRadius: (d: any) => d.value,
                    background: "transparent",
                    fontSizeScale: 8.5,
                    vehicle: true,
                  }}
                />
              </GraphContainer>
            </Column>
            <Column>
              <GraphContainer
                info="This graph displays the number of vehicles that have entered the car park for a selected date."
                lastUpdated={new Date().toISOString()}
                title="Payment Types"
              >
                <TreeMap
                  data={{today: paymentTypesData, lastMonth: paymentTypesData}}
                  options={{dataTrends: true}}
                />
              </GraphContainer>
            </Column>
          </Row>
        </Container>
      </Box>
    </Layout>
  )
}

import {format as formatDate, add as addDate, set as setDate} from "date-fns"

export default function midnightFormat(date: Date | string): string {
  if (date instanceof Date) date = formatDate(date, "yyyy-MM-dd")

  const isToday = date === formatDate(new Date(), "yyyy-MM-dd")
  const now = setDate(new Date(), {minutes: 59, seconds: 59, milliseconds: 0})
  const midnight = addDate(new Date(date), {days: 1, seconds: -1})

  return (isToday ? now : midnight).toISOString()
}

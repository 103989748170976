import {Box, IconButton} from "@material-ui/core"
import styled from "styled-components"

import theme from "../../../../styles/theme"

export const ColorButton = styled(IconButton)`
  color: ${theme.palette.secondary.main};
  padding: ${theme.spacing(1.75)}px;
`

export const TooltipContainer = styled(Box)`
  position: relative;
  z-index:99999;
`

export const TooltipContent = styled(Box)`
  border: 1px ${theme.palette.primary.main} solid;
  border-radius: ${theme.spacing(1)}px;
  background-color: ${theme.palette.primary.main};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  color: ${theme.palette.secondary.main};
`

export const TooltipRef = styled.div<any>`
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`

export const TooltipValue = styled.span<any>`
  display: inline-block;
  max-width: ${(props) => props.maxWidth}px;
  vertical-align: middle;
`

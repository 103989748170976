import {Box, IconButton} from "@material-ui/core"
import styled from "styled-components"

import theme from "../../../../styles/theme"

export const ColorButton = styled(IconButton)`
  color: ${theme.palette.secondary.main};
  padding: ${theme.spacing(1.75)}px;
`

export const TrendReport = styled.div<any>`
  padding: ${theme.spacing(1.75)}px;
  border-top: 1px solid #454968;
  font-size: 1.3em;
`

export const TrendValue = styled.span<any>`
  padding: ${theme.spacing(1.75)}px;
  font-size: 1.2em;
`

export const TooltipContainer = styled(Box)`
  position: relative;
  z-index: 99999;
`

export const TooltipContent = styled(Box)`
  border: 1px ${theme.palette.primary.main} solid;
  border-radius: ${theme.spacing(1)}px;
  background-color: ${theme.palette.primary.main};
  width: 150;
  color: ${theme.palette.secondary.main};
  font-size: 0.9em;
  position: relative;
`

export const TooltipRef = styled.div<any>`
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  width: 150px;
`

export const TooltipValue = styled.span<any>`
  display: inline-block;
  max-width: ${(props) => props.maxWidth}px;
  vertical-align: middle;
`

export const TooltipPoint = styled.div`
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background-color: ${theme.palette.primary.main};
  position: absolute;
  left: calc(50% - 5px);
  bottom: -5px;
`

import React, {useImperativeHandle, useState} from "react"

import {
  ColorButton,
  TooltipContainer,
  TooltipContent,
  TooltipRef,
  TooltipValue,
  TrendReport,
  TrendValue,
  TooltipPoint,
} from "./RichTooltip.styles"
import TooltipIcon from "../../../TooltipIcon/TooltipIcon"
import {SupportedTooltipIcons} from "../../types"

interface RichValue {
  rows: {icon: SupportedTooltipIcons; text: string}[]
  trend: {
    adjustment: number
    since: string
  }
}

interface TooltipState {
  shouldShow: boolean
  value: RichValue
  x: number
  y: number
}

const RichTooltip: React.FC<{baseRef: any}> = ({baseRef}) => {
  const [tooltipData, setTooltipData] = useState<TooltipState>({
    shouldShow: false,
    value: {rows: [], trend: null},
    x: 0,
    y: 0,
  })

  useImperativeHandle(baseRef, () => ({
    setData: (data: Partial<TooltipState>) =>
      setTooltipData((prevState: TooltipState) => ({...prevState, ...data})),
  }))

  if (!tooltipData.shouldShow) return null

  const tipHeight = 40
  const tipWidth = 150

  const alignLeft = true
  const alignRight = false
  const alignMiddle = !alignLeft && !alignRight
  const alignBottom = false

  const xPosition = alignMiddle
    ? tooltipData.x - tipWidth / 2
    : alignLeft
    ? tooltipData.x - tipWidth
    : tooltipData.x

  return (
    <TooltipContainer>
      <TooltipRef
        ref={baseRef}
        left={xPosition + tipWidth / 2 + 15}
        top={
          alignBottom
            ? tooltipData.y
            : tooltipData.y -
              tipHeight * (tooltipData.value.rows.length + 1) -
              7
        }
        width={tipWidth}
      >
        <TooltipContent width={150}>
          {tooltipData.value.rows.map((row, i) => {
            return (
              <div key={i} style={{padding: 0, margin: 0}}>
                <ColorButton aria-label="value">
                  <TooltipIcon icon={row.icon} compact={true} />
                </ColorButton>
                <TooltipValue maxWidth={tipWidth - 40}>{row.text}</TooltipValue>
              </div>
            )
          })}
          {tooltipData.value.trend && (
            <TrendReport>
              <TrendValue
                style={{
                  color:
                    tooltipData.value.trend.adjustment > 0 ? "#41d18f" : "red",
                }}
              >
                {tooltipData.value.trend.adjustment > 0 ? "+" : "-"}
                {tooltipData.value.trend.adjustment}%
              </TrendValue>
              since {tooltipData.value.trend.since}
            </TrendReport>
          )}
          <TooltipPoint />
        </TooltipContent>
      </TooltipRef>
    </TooltipContainer>
  )
}

const ForwardRefRichTooltip = React.forwardRef((props, ref) => (
  <RichTooltip baseRef={ref} {...props} />
))

export default ForwardRefRichTooltip

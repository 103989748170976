import styled from "styled-components"

export const InsightsContainer = styled.div`
  display: flex;
  width: 100%;
  & > div {
    flex-basis: 0;
    flex-grow: 1;
    margin: 10px;
  }
`

export const MultiItemRow = styled.div`
  display: flex;

  & > div {
    flex-grow: 1;
    flex-basis: 0;
    height: 400px;
    padding: 20px;
  }
`

import React from "react"
import {Box, Typography} from "@material-ui/core"
import {DirectionsCar, LocationOn} from "@material-ui/icons"

import CurrentUsageBar from "../../../components/CurrentUsageBar/CurrentUsageBar"
import theme from "../../../styles/theme"
import useStyles from "./LeftHandSide.styles"

export interface LeftHandSideProps {
  address: string
  capacity: number
  currentUsage: number
  image?: string
  name: string
}

const LeftHandSide: React.FC<LeftHandSideProps> = ({
  address,
  capacity,
  currentUsage,
  image,
  name,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box
        className={classes.image}
        style={{
          backgroundColor: "#e9e9e9",
          border: "1px solid #bbb",
          backgroundImage: `url("${image}")`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box className={classes.content}>
        <Typography color="textPrimary" variant="h6">
          {name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          <LocationOn className={classes.inlineIcon} fontSize="small" />
          {address}
        </Typography>
      </Box>
      <Box className={classes.statistics}>
        <Box className={classes.statItem}>
          <Typography color="textSecondary" variant="caption">
            {capacity}
          </Typography>
          <DirectionsCar
            className={classes.icon}
            fontSize="small"
            htmlColor={theme.palette.text.secondary}
          />
        </Box>
        <Box className={classes.statItem}>
          <Typography color="textSecondary" variant="caption">{`${Math.round(
            currentUsage
          )}%`}</Typography>
          <CurrentUsageBar
            className={classes.icon}
            currentUsage={currentUsage}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default LeftHandSide

import React from "react"
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  SvgIconTypeMap,
  Theme,
  Typography,
} from "@material-ui/core"
import {
  Accessible,
  ConfirmationNumber,
  DirectionsCar,
  EvStation,
  Lock,
  Schedule,
} from "@material-ui/icons"
import {OverridableComponent} from "@material-ui/core/OverridableComponent"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridRoot: {
      alignItems: "center",
      height: "100%",
      padding: theme.spacing(7, 10),
    },
    gridItem: {
      display: "flex",
      alignItems: "center",
    },
    inlineIcon: {
      display: "inline-flex",
      marginRight: theme.spacing(2),
      verticalAlign: "middle",
    },
  })
)

interface StatGridItemProps {
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
  title: string
  value: number | string
}

const StatGridItem: React.FC<StatGridItemProps> = ({
  icon: Icon,
  title,
  value,
}) => {
  const classes = useStyles()

  return (
    <Grid classes={{root: classes.gridItem}} item xs={4}>
      <Typography color="textSecondary" variant="overline">
        <Icon className={classes.inlineIcon} fontSize="small" />
        {`${title}: ${value}`}
      </Typography>
    </Grid>
  )
}

interface RightHandSideProps {
  capacity: number
  chargingPoints: number
  closeTime: string
  disabledSpaces: number
  openTime: string
  secured: number
  subsidised: number
}

const RightHandSide: React.FC<RightHandSideProps> = ({
  capacity,
  chargingPoints,
  closeTime,
  disabledSpaces,
  openTime,
  secured,
  subsidised,
}) => {
  const classes = useStyles()

  return (
    <Box>
      <Grid classes={{root: classes.gridRoot}} container>
        <Grid container item xs={12}>
          <StatGridItem
            icon={DirectionsCar}
            title="Capacity"
            value={capacity}
          />
          <StatGridItem
            icon={Accessible}
            title="Disabled spaces"
            value={disabledSpaces}
          />
          <StatGridItem
            icon={EvStation}
            title="Charging points"
            value={chargingPoints}
          />
        </Grid>
        <Grid container item xs={12}>
          <StatGridItem
            icon={Schedule}
            title="Opening times"
            value={`${openTime} - ${closeTime}`}
          />
          <StatGridItem icon={Lock} title="Secured/unsecured" value={secured} />
          <StatGridItem
            icon={ConfirmationNumber}
            title="Subsidised parking"
            value={subsidised}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default RightHandSide

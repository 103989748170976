import React from "react"

import D3Wrapper from "../common/D3Wrapper"
import {BubbleChartOptions} from "../types"
import draw from "./draw"

export interface BubbleChartProps {
  data: any
  options: BubbleChartOptions
}

const BubbleChart: React.FC<BubbleChartProps> = ({data, options}) => (
  <D3Wrapper draw={draw} data={data} options={options} />
)

export default BubbleChart

import React, {useState, useEffect, useRef} from "react"
import Calendar from "react-calendar"
import {Box, Typography} from "@material-ui/core"
import {NavigateBefore, NavigateNext} from "@material-ui/icons"
import {format, startOfDay, addDays, subDays} from "date-fns"

import {
  CalendarRef,
  CalendarWrapper,
  Container,
  DateBox,
  LeftButton,
  RightButton,
} from "./DateSelector.styles"

import "react-calendar/dist/Calendar.css"

function useOnClickOutside(
  ref: React.MutableRefObject<any>,
  handler: (value: React.SetStateAction<boolean>) => void
) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }

      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)

      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}

export interface DateSelectorProps {
  currentDate: Date
  onChange?: (date: Date) => void
}

const getDateString = (date: Date) => format(date, "EEEE d, LLL Y")

const DateSelector: React.FC<DateSelectorProps> = ({currentDate}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [value, setValue] = useState(currentDate)
  const wrapperRef = useRef(null)

  useOnClickOutside(wrapperRef, () => setIsExpanded(false))
  const shouldDisableNext = value > startOfDay(new Date())

  return (
    <Container>
      <LeftButton onClick={() => setValue(subDays(value, 1))}>
        <NavigateBefore />
      </LeftButton>
      <Box>
        <DateBox
          onClick={() => {
            if (!isExpanded) setIsExpanded(true)
          }}
        >
          <Typography variant="overline">{getDateString(value)}</Typography>
        </DateBox>
        {isExpanded && (
          <CalendarWrapper>
            <CalendarRef ref={wrapperRef}>
              <Calendar
                onChange={(date) => setValue(date as Date)}
                maxDate={new Date()}
              />
            </CalendarRef>
          </CalendarWrapper>
        )}
      </Box>
      <RightButton
        disabled={shouldDisableNext}
        onClick={() => setValue(addDays(value, 1))}
      >
        <NavigateNext />
      </RightButton>
    </Container>
  )
}

export default DateSelector

import React from "react"

import D3Wrapper from "../common/D3Wrapper"
import {HorizontalBarChartOptions} from "../types"
import draw from "./draw"

export interface HorizontalBarChartProps {
  data: any[]
  options: HorizontalBarChartOptions
}

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({
  data,
  options,
}) => <D3Wrapper draw={draw} data={data} options={options} />

export default HorizontalBarChart

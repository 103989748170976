import React from "react"
import {Link} from "react-router-dom"
import {Drawer, List, ListItem, ListItemIcon} from "@material-ui/core"
import {
  HomeOutlined,
  PieChartOutlined,
  SettingsOutlined,
} from "@material-ui/icons"

import {ReactComponent as Logo} from "../../assets/svgs/logo-white.svg" // TODO: Use webpack/svgr
import useStyles from "./Sidebar.styles"

const buttonProps: any = {
  color: "secondary",
  fontSize: "small",
}
const buttons = [
  {
    text: "Home",
    icon: <HomeOutlined {...buttonProps} />,
    link: `/dashboard`,
  },
  {
    text: "Charts",
    icon: <PieChartOutlined {...buttonProps} />,
    link: "/charts",
  },
  {
    text: "Settings",
    icon: <SettingsOutlined {...buttonProps} />,
    link: "/settings",
  },
]

export interface SidebarProps {
  closedDrawerWidth: number
  currentPage: string
  drawerOpen: boolean
  drawerWidth: number
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Sidebar: React.FC<SidebarProps> = (props) => {
  const classes = useStyles(props)

  const {setDrawerOpen, currentPage} = props

  const handleOpen = () => {
    setDrawerOpen(true)
  }

  const handleClose = () => {
    setDrawerOpen(false)
  }
  return (
    <Drawer
      classes={{paper: classes.drawer, root: classes.drawerRoot}}
      variant="permanent"
      anchor="left"
      onClick={handleOpen}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      <Logo className={classes.logoContainer} />
      <List>
        {buttons.map(({icon, text, link}) => (
          <Link key={text} className={classes.anchor} to={link}>
            <ListItem
              button
              classes={{
                root: classes.listItem,
                selected: classes.listItemSelected,
              }}
              selected={text === currentPage}
            >
              <ListItemIcon classes={{root: classes.listItemIcon}}>
                {icon}
              </ListItemIcon>
            </ListItem>
          </Link>
        ))}
      </List>
    </Drawer>
  )
}

export default Sidebar

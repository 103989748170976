import styled from "styled-components"

export const GraphArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-areas:
    "brand brand model"
    "vehicleAge engineSize color"
    "fuelType vehicleType color";
`

export const GridBoxContainer = styled.div<{
  borders?: {top?: boolean; bottom?: boolean; left?: boolean; right?: boolean}
  gridArea: string
}>`
  grid-area: ${(props) => props.gridArea};
  position: relative;
  ${(props) => !!props.borders?.top && "border-top: 1px solid #ccc"};
  ${(props) => !!props.borders?.bottom && "border-bottom: 1px solid #ccc"};
  ${(props) => !!props.borders?.left && "border-left: 1px solid #ccc"};
  ${(props) => !!props.borders?.right && "border-right: 1px solid #ccc"};
`

export const GridBoxContent = styled.div`
  padding: 15px 25px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`

export const InsightsContainer = styled.div`
  display: flex;
  width: 100%;
  & > div {
    flex-basis: 0;
    flex-grow: 1;
    margin: 10px;
  }
`

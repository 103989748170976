import styled from "styled-components"

export const GraphAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const GraphRow = styled.div<{height?: number}>`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  & > div {
    flex-grow: 1;
    flex-basis: 0;
    height: ${(props) => props.height || 350}px;
    margin: 10px;
    padding: 20px;
  }
`

export const GraphColumn = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  padding: 20px;
`

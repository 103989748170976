import {Box, IconButton} from "@material-ui/core"
import styled from "styled-components"

import theme from "../../styles/theme"

export const CalendarWrapper = styled(Box)`
  position: relative;
`

// Cannot use MUI styled here as Box doesn't support the ref prop
export const CalendarRef = styled.div`
  && {
    position: absolute;
    top: 20px;
    left: -40%;
  }
`

export const Container = styled(Box)`
  align-items: center;
  background-color: ${theme.palette.secondary.main};
  border: 1px solid ${theme.palette.border.main};
  border-radius: ${theme.spacing(7.5)}px;
  display: inline-flex;
`

export const DateBox = styled(Box)`
  cursor: pointer;
  min-height: 100%;
  padding: 0px ${theme.spacing(4)}px;
`

export const LeftButton = styled(IconButton)`
  border-radius: 50% 0 0 50%;
  border-right: 1px solid ${theme.palette.border.main};
  padding: ${theme.spacing(2)}px;
`

export const RightButton = styled(IconButton)`
  border-left: 1px solid ${theme.palette.border.main};
  border-radius: 0 50% 50% 0;
  padding: ${theme.spacing(2)}px;
`

import React, {useRef} from "react"
import {Box, fade} from "@material-ui/core"

import {
  DoubleDonutChart,
  HorizontalBarChart,
  RichTooltip,
  VerticalBarChart,
} from "../../../components/graphs"
import GraphContainer from "../../../components/graphs/common/GraphContainer"
import {COLORS} from "../../../styles/theme"

export const EngineSizeChart: React.FC<{
  innerData: any[]
  outerData: any[]
}> = ({innerData, outerData}) => {
  const tooltipRef = useRef()
  const reducer = (count, item) => count + item.value
  const totalInnerCars = innerData.reduce(reducer, 0)
  const totalOuterCars = outerData.reduce(reducer, 0)

  return (
    <GraphContainer
      info="This graph displays the number of vehicles that have entered the car park for a selected date."
      lastUpdated={new Date().toISOString()}
      title="Engine Size"
    >
      <RichTooltip ref={tooltipRef} />
      <DoubleDonutChart
        innerData={innerData}
        outerData={outerData}
        options={{
          donutWidth: 30,
          domain: [1, 3, 9],
          percentages: false,
          compact: true,
          labels: {age: false, size: true, rotateInnerLabels: true},
          setInnerTooltipValue: (d) => ({
            rows: [
              {
                text: `${d.data.value} vehicles (${Math.round(
                  (d.data.value / totalInnerCars) * 100
                )}%)`,
                icon: "car",
              },
            ],
            trend: {
              adjustment: 5,
              since: "last week",
            },
          }),
          setOuterTooltipValue: (d) => ({
            rows: [
              {
                text: `${d.data.value} vehicles (${Math.round(
                  (d.data.value / totalOuterCars) * 100
                )}%)`,
                icon: "car",
              },
            ],
          }),
          tooltipRef,
        }}
      />
    </GraphContainer>
  )
}

export const VehicleModelChart: React.FC<{data: any[]}> = ({data}) => {
  const tooltipRef = useRef()

  return (
    <Box flexBasis={0} flexGrow={1} height="100%">
      <RichTooltip ref={tooltipRef} />
      <HorizontalBarChart
        data={data}
        options={{
          legend: [
            {colour: COLORS.blue, text: "Today", type: "dot"},
            {colour: fade("#000", 0.25), text: "Last month", type: "dot"},
          ],
          setTooltipValue: (d) => ({
            rows: [{text: `${d.today} Vehicles`, icon: "car"}],
            trend: {
              adjustment: 5,
              since: "last week",
            },
          }),
          getX1: (d) => d.today,
          getX2: (d) => d.lastMonth,
          margin: {bottom: 70, left: 160, right: 20, top: 20},
          skinnyBars: true,
          tooltipRef,
          xAxisLabel: "Number of vehicles",
          xTicksPerLabel: 2,
        }}
      />
    </Box>
  )
}

export const VehicleColourChart: React.FC<{data: any}> = ({data}) => {
  const tooltipRef = useRef()

  const trendedData = {
    today: data,
    lastWeek: data.map((d) => ({label: d.label, value: d.value - 200})),
    lastMonth: data.map((d) => ({label: d.label, value: d.value + 300})),
  }

  return (
    <Box flexBasis={0} flexGrow={1} height="100%">
      <RichTooltip ref={tooltipRef} />
      <VerticalBarChart
        data={trendedData}
        options={{
          margin: {bottom: 40, left: 120, right: 0, top: 20},
          skinnyBars: true,
          tooltipRef,
          xAxisLabel: "Colour",
          yAxisLabel: "Number of vehicles",
          yAxisTickPadding: 4,
          showTrendedLegend: true,
          dataTrends: true,
        }}
      />
    </Box>
  )
}

export const VehicleAgeChart: React.FC<{innerData: any; outerData: any}> = ({
  innerData,
  outerData,
}) => {
  const tooltipRef = useRef()
  const reducer = (count, item) => count + item.value
  const totalInnerCars = innerData.reduce(reducer, 0)
  const totalOuterCars = outerData.reduce(reducer, 0)

  return (
    <GraphContainer
      info="This graph displays the number of vehicles that have entered the car park for a selected date."
      lastUpdated={new Date().toISOString()}
      title="Vehicle Age"
    >
      <RichTooltip ref={tooltipRef} />
      <DoubleDonutChart
        innerData={innerData}
        outerData={outerData}
        options={{
          compact: true,
          domain: [1, 2, 7],
          donutWidth: 30,
          labels: {age: true, size: false, rotateInnerLabels: true},
          percentages: false,
          setInnerTooltipValue: (d) => ({
            rows: [
              {
                text: `${d.data.value} vehicles (${Math.round(
                  (d.data.value / totalInnerCars) * 100
                )}%)`,
                icon: "car",
              },
            ],
            trend: {
              adjustment: 5,
              since: "last week",
            },
          }),
          setOuterTooltipValue: (d) => ({
            rows: [
              {
                text: `${d.data.value} vehicles (${Math.round(
                  (d.data.value / totalOuterCars) * 100
                )}%)`,
                icon: "car",
              },
            ],
          }),
          tooltipRef,
        }}
      />
    </GraphContainer>
  )
}

export const VehicleMakeChart: React.FC<{data: any[]}> = ({data}) => {
  const tooltipRef = useRef()

  return (
    <GraphContainer
      info="This graph displays the number of vehicles that have entered the car park for a selected date."
      lastUpdated={new Date().toISOString()}
      title="Vehicle Make"
    >
      <RichTooltip ref={tooltipRef} />
      <DoubleDonutChart
        innerData={data.slice(0, 20)}
        outerData={data.slice(0, 15)}
        options={{
          donutWidth: 60,
          domain: [1, 6, 20],
          percentages: true,
          setInnerTooltipValue: (d) => ({
            rows: [{text: `${d.data.value} vehicles`, icon: "car"}],
            trend: {
              adjustment: 5,
              since: "last week",
            },
          }),
          setOuterTooltipValue: (d) => ({
            rows: [{text: `${d.data.value} ${d.data.label}`, icon: "car"}],
          }),
          tooltipRef,
        }}
      />
    </GraphContainer>
  )
}

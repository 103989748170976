import {createStyles, makeStyles, Theme} from "@material-ui/core"

export default makeStyles((theme: Theme) =>
  createStyles({
    arrow: {
      alignItems: "center",
      borderLeft: `1px solid ${theme.palette.border.main}`,
      boxSizing: "border-box",
      display: "flex",
      height: "100%",
      padding: theme.spacing(1),
    },
    container: {
      alignItems: "center",
      display: "flex",
      height: theme.spacing(30),
      width: theme.spacing(105),
    },
    content: {
      alignItems: "flex-start",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      height: "100%",
      padding: theme.spacing(6),
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    inlineIcon: {
      display: "inline-flex",
      verticalAlign: "middle",
    },
    image: {
      backgroundColor: "black",
      borderRadius: theme.spacing(2, 0, 0, 2),
      height: "100%",
      minWidth: theme.spacing(24),
    },
    statistics: {
      alignItems: "flex-end",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginRight: theme.spacing(4),
    },
    statItem: {
      alignItems: "center",
      display: "flex",
      padding: theme.spacing(1, 0, 1, 0),
    },
  })
)

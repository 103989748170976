import {sub as subDate} from "date-fns"
import useSWR from "swr"
import midnightFormat from "../../utils/midnightFormat"
const {PUBLIC_URL} = process.env

const fetcher = (
  params: ("carParkId" | "date" | "startDate" | "endDate")[]
) => (url: string, ...data: any[]) => {
  const args = {} as any
  for (const i in data) {
    args[params[i]] = data[i]
  }

  const qs = [] as string[]

  if (args.carParkId) qs.push(`carParkId=${args.carParkId}`)
  if (args.date) qs.push(`date=${args.date}`)
  if (args.startDate) qs.push(`startDate=${args.startDate}`)
  if (args.endDate) qs.push(`endDate=${args.endDate}`)

  const fullUrl = url + "?" + qs.join("&")

  return fetch(fullUrl).then((r) => r.json())
}

export default function useCarParkData({
  carParkId,
  selectedDate,
}: {
  carParkId: string
  selectedDate: Date
}) {
  const dateString = midnightFormat(new Date(selectedDate))

  let {data: allCarparks} = useSWR(
    [`${PUBLIC_URL}/api/carparks/all`, dateString],
    fetcher(["date"])
  )

  let {data: occupancy} = useSWR(
    [`${PUBLIC_URL}/api/occupancy`, carParkId, dateString],
    fetcher(["carParkId", "date"])
  )

  const sixtyDaysAgo = midnightFormat(
    subDate(new Date(selectedDate), {
      days: 60,
    })
  )

  let {data: fuelBreakdown} = useSWR(
    [
      `${PUBLIC_URL}/api/breakdown/vehicles/fuel`,
      carParkId,
      sixtyDaysAgo,
      dateString,
    ],
    fetcher(["carParkId", "startDate", "endDate"])
  )
  let {data: typeBreakdown} = useSWR(
    [
      `${PUBLIC_URL}/api/breakdown/vehicles/type`,
      carParkId,
      sixtyDaysAgo,
      dateString,
    ],
    fetcher(["carParkId", "startDate", "endDate"])
  )
  let {data: makeBreakdown} = useSWR(
    [
      `${PUBLIC_URL}/api/breakdown/vehicles/make`,
      carParkId,
      sixtyDaysAgo,
      dateString,
    ],
    fetcher(["carParkId", "startDate", "endDate"])
  )
  let {data: modelBreakdown} = useSWR(
    [
      `${PUBLIC_URL}/api/breakdown/vehicles/model`,
      carParkId,
      sixtyDaysAgo,
      dateString,
    ],
    fetcher(["carParkId", "startDate", "endDate"])
  )
  let {data: occupancyBreakdown} = useSWR(
    [
      `${PUBLIC_URL}/api/breakdown/occupancy`,
      carParkId,
      sixtyDaysAgo,
      dateString,
    ],
    fetcher(["carParkId", "startDate", "endDate"])
  )
  let {data: movementBreakdown} = useSWR(
    [
      `${PUBLIC_URL}/api/breakdown/movement`,
      carParkId,
      sixtyDaysAgo,
      dateString,
    ],
    fetcher(["carParkId", "startDate", "endDate"])
  )

  if (
    !allCarparks ||
    !fuelBreakdown ||
    !typeBreakdown ||
    !makeBreakdown ||
    !modelBreakdown ||
    !occupancyBreakdown ||
    !movementBreakdown
  ) {
    return {loading: true, data: null}
  }

  if (occupancy && occupancy.data) {
    occupancy = occupancy.data.currentOccupants as any[]
    occupancy.forEach((car: any) => {
      car.arrivedAt = new Date(car.arrivedAt)
      ;(car.pastAppearances || []).forEach((appearance: any) => {
        appearance.entryAt = new Date(appearance.entryAt)
        appearance.exitAt = new Date(appearance.exitAt)
      })
    })
  } else {
    occupancy = []
  }

  const breakdown = {
    vehicles: {
      fuel: fuelBreakdown.data,
      type: typeBreakdown.data,
      make: makeBreakdown.data,
      model: modelBreakdown.data,
    },
    occupancy: occupancyBreakdown.data,
    movement: movementBreakdown.data,
  }

  breakdown.occupancy.forEach((d: any) => {
    d.hour = new Date(d.hour)
  })
  breakdown.movement.forEach((d: any) => {
    d.hour = new Date(d.hour)
  })

  const carpark = allCarparks.data.find((c: any) => c.carParkId === carParkId)

  return {loading: false, data: {carpark, occupancy, breakdown}}
}

import React from "react"

import LeftHandSide from "./LeftHandSide"
import RightHandSide from "./RightHandSide"
import {Box} from "@material-ui/core"
import theme from "../../../styles/theme"

export interface CarParkHighlightsProps {
  image?: string
  address: string
  capacity: number
  currentUsage: number
  imageUrl?: string
  lastUpdatedAt: string
  name: string
}

const CarParkHighlights: React.FC<CarParkHighlightsProps> = ({
  image,
  address,
  capacity,
  currentUsage,
  imageUrl,
  lastUpdatedAt,
  name,
}) => {
  const leftHandProps = {
    image,
    address,
    capacity,
    currentUsage,
    imageUrl,
    lastUpdatedAt,
    name,
  }

  return (
    <Box display="flex" borderBottom={`1px solid ${theme.palette.border.main}`}>
      <LeftHandSide {...leftHandProps} />
      <RightHandSide
        capacity={capacity}
        chargingPoints={12}
        closeTime="22:00"
        disabledSpaces={120}
        openTime="7:00"
        secured={260}
        subsidised={84}
      />
    </Box>
  )
}

export default CarParkHighlights

/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import React, {useEffect, useState} from "react"

import carImg from "./car.png"

const DriveInAnimation: React.FC<{direction: string}> = ({direction}) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const timeouts = [] as any[]

    const driveIn = () => {
      const timeout1 = setTimeout(() => {
        setVisible(true)
        const timeout2 = setTimeout(() => setVisible(false), 2000)
        timeouts.push(timeout2)
        driveIn()
      }, Math.random() * 15000 + 2000)
      timeouts.push(timeout1)
    }

    driveIn()

    return () => timeouts.forEach((t) => clearTimeout(t))
  }, [])

  if (!visible) return null

  return (
    <img
      alt="Car"
      src={carImg}
      css={css`
        position: relative;
        height: 36px;
        width: 20px;
        padding-top: ${direction === "in" ? 25 : 15}px;
        padding-left: 5px;
        filter: brightness(0.4);
        animation-name: ${direction === "in" ? "driveIn" : "driveOut"};
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-duration: 1.6s;
        opacity: 0;

        @keyframes ${direction === "in" ? "driveIn" : "driveOut"} {
          0% {
            transform: translateX(0px)
              rotate(${direction === "in" ? "270deg" : "90deg"});
            opacity: 0;
          }

          50% {
            opacity: 1;
          }

          75% {
            opacity: 1;
          }

          100% {
            transform: translateX(${direction === "in" ? -20 : 20}px)
              rotate(${direction === "in" ? "270deg" : "90deg"});
            opacity: 0;
          }
        }
      `}
    />
  )
}

export default DriveInAnimation

import React, {useEffect, useRef, useState, RefObject} from "react"
import ReactDOMServer from "react-dom/server"
import mapboxgl, {Map} from "mapbox-gl"
import {Box, createStyles, makeStyles, ThemeProvider} from "@material-ui/core"

import MapPopup from "../MapPopup/MapPopup"
import {locationsToGeoJson} from "../../utils/geojson"
import theme, {COLORS} from "../../styles/theme"
import {Location} from "../../types"

import "mapbox-gl/dist/mapbox-gl.css"
import "./mapbox-custom-styles.css"

const useStyles = makeStyles((theme) =>
  createStyles({
    cardRef: {
      width: "100%",
      height: "100%",
      borderRadius: "6px",
    },
    container: {
      boxSizing: "border-box",
      backgroundColor: theme.palette.secondary.light,
      height: "100%",
      padding: theme.spacing(4.5),
    },
    marker: {
      backgroundColor: COLORS.purple,
      border: `${theme.spacing(0.5)}px solid ${theme.palette.secondary.main}`,
      borderRadius: "50%",
      height: theme.spacing(9),
      width: theme.spacing(9),
    },
    popup: {
      padding: 0,
    },
  })
)

interface MapCardProps {
  locations: Location[]
  initialLatitude: number
  initialLongitude: number
  initialZoom: number
}

// With help from the article at https://sparkgeo.com/blog/build-a-react-mapboxgl-component-with-hooks/
const MapCard: React.FC<MapCardProps> = ({
  locations,
  initialLatitude,
  initialLongitude,
  initialZoom,
}) => {
  const classes = useStyles()
  const [map, setMap] = useState<Map | undefined>()
  const mapContainer = useRef<HTMLDivElement>(null)
  const locationGeoData = locationsToGeoJson(locations)

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string
    const initializeMap = ({
      setMap,
      mapContainer,
    }: {
      setMap: React.Dispatch<React.SetStateAction<Map | undefined>>
      mapContainer: RefObject<HTMLDivElement>
    }) => {
      if (!mapContainer.current) return
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
        center: [initialLongitude, initialLatitude],
        zoom: initialZoom,
      })

      map.addControl(new mapboxgl.NavigationControl())

      locationGeoData.features.forEach((marker) => {
        const el = document.createElement("div")
        el.className = classes.marker
        el.innerHTML =
          '<svg style="fill:white;position:relative;top:7px;left:8px;font-size:2rem;width:1em;height:1em;" focusable="false" viewBox="0 0 24 24" color="#86889E" aria-hidden="true"><path d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z"></path></svg>'
        new mapboxgl.Marker(el)
          .setLngLat({
            lng: marker.geometry.coordinates[0],
            lat: marker.geometry.coordinates[1],
          })
          .setPopup(
            new mapboxgl.Popup({closeButton: false, offset: 50}).setHTML(
              ReactDOMServer.renderToStaticMarkup(
                <ThemeProvider theme={theme}>
                  <MapPopup {...marker.properties} />
                </ThemeProvider>
              )
            )
          )
          .addTo(map)
      })

      map.on("load", () => {
        setMap(map)
        map.resize()
      })
    }

    if (!map) initializeMap({setMap, mapContainer})
  }, [
    locationGeoData,
    classes,
    initialLatitude,
    initialLongitude,
    initialZoom,
    map,
  ])

  if (!locations.length) return null

  return (
    <Box className={classes.container}>
      <div ref={mapContainer} className={classes.cardRef} />
    </Box>
  )
}

export default MapCard

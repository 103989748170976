/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core"
import NavBar from "../components/NavBar/NavBar"
import Sidebar from "../components/Sidebar/Sidebar"
import theme from "../styles/theme"
import React from "react"
import useSWR from "swr"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      minHeight: theme.spacing(25),
      height: theme.spacing(25),
    },
    navbar: {
      height: "100%",
    },
  })
)

const fetcher = (url) => fetch(url).then((r) => r.json())

const Layout: React.FC<{currentPage?: string; fixedWidth?: boolean}> = ({
  currentPage = "",
  fixedWidth,
  children,
}) => {
  const classes = useStyles()
  const {data: profile} = useSWR("/api/auth/me", fetcher)

  if (!children) return <React.Fragment></React.Fragment>
  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" height="100vh">
        <Box className={classes.navbar} component="nav">
          <Sidebar
            closedDrawerWidth={0}
            currentPage={currentPage}
            drawerOpen={true}
            drawerWidth={100}
            setDrawerOpen={() => {}}
          />
        </Box>
        <div
          css={css`
            border-radius: 30px;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            ${fixedWidth
              ? `
                @media screen and (min-width: 1400px) {
                  overflow-x: hidden;
                }
              `
              : ""}
          `}
        >
          <Box className={classes.header} component="header">
            <NavBar
              closedDrawerWidth={0}
              drawerOpen={false}
              drawerWidth={0}
              height={100}
              username={profile?.name || "Bobby"}
            />
          </Box>
          <Box
            flexGrow={1}
            margin={fixedWidth ? "0 auto" : undefined}
            maxWidth={fixedWidth ? "1350px" : undefined}
          >
            {children}
          </Box>
        </div>
      </Box>
    </ThemeProvider>
  )
}

export default Layout

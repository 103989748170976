import React from "react"
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from "@material-ui/core"
import {ExpandMore} from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonText: {
      padding: theme.spacing(0, 2),
    },
    container: {
      margin: theme.spacing(0, 2),
      borderRadius: theme.spacing(1.25),
      border: ({hasBorder}: {hasBorder: boolean}) =>
        hasBorder ? `1px solid ${theme.palette.border.main}` : "none",
    },
  })
)

export interface DropdownMenuButtonProps {
  hasBorder?: boolean
  icon: React.FC
  menuItems: any[]
  noExpandIcon?: boolean
  text?: string
}

const DropdownMenuButton: React.FC<DropdownMenuButtonProps> = ({
  text,
  icon,
  menuItems,
  hasBorder = false,
  noExpandIcon,
}) => {
  const classes = useStyles({hasBorder})

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const renderedIcon = React.createElement(icon)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box className={classes.container}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {renderedIcon}
        {text && (
          <Typography className={classes.buttonText} variant="overline">
            {text}
          </Typography>
        )}
        {!noExpandIcon && <ExpandMore fontSize="small" />}
      </Button>
      <Menu
        id="simple-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map(({text, onClick}) => (
          <MenuItem key={text} onClick={onClick}>
            {text}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default DropdownMenuButton

import {ChartWithAxesOptions, LegendItem} from "./types"
import theme from "../../styles/theme"

export const drawLegend: (
  items: LegendItem[],
  selection: any,
  height: any,
  margins: any
) => void = (items, selection, height, margins) => {
  selection.selectAll("g.legend").remove()

  const legend = selection.append("g").attr("class", "legend")
  const legendX = 10
  const legendY = height + margins.top + 45

  items.forEach((item, i) => {
    if (item.type === "dot") {
      legend
        .append("circle")
        .attr("cx", legendX + 100 * i)
        .attr("cy", legendY)
        .attr("r", 6)
        .style("fill", item.colour)
    } else {
      legend
        .append("rect")
        .attr("x", legendX + 100 * i)
        .attr("y", legendY - 2)
        .attr("width", 15)
        .attr("height", 2)
        .style("fill", item.colour)
    }

    legend
      .append("text")
      .attr("x", legendX + 100 * i + 20)
      .attr("y", legendY)
      .text(item.text)
      .style("font", `12px ${theme.typography.fontFamily}`)
      .style("color", theme.palette.text.secondary)
      .attr("alignment-baseline", "middle")
  })
}

export const getMargins = (options?: ChartWithAxesOptions) => {
  const defaultMargins = {top: 0, right: 0, bottom: 0, left: 0}

  if (!options || !options.margin) return defaultMargins
  return {
    bottom: options.margin.bottom || defaultMargins.bottom,
    left: options.margin.left || defaultMargins.left,
    right: options.margin.right || defaultMargins.right,
    top: options.margin.top || defaultMargins.top,
  }
}

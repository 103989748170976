import {createStyles, makeStyles, Theme, fade} from "@material-ui/core"

import {CurrentUsageBarProps} from "./CurrentUsageBar"

export default makeStyles((theme: Theme) =>
  createStyles({
    background: {
      backgroundColor: fade(theme.palette.text.secondary, 0.14),
      borderRadius: theme.spacing(0.375),
      height: theme.spacing(0.75),
      position: "relative",
      width: theme.spacing(10),
    },
    foreground: {
      backgroundColor: ({currentUsage}: CurrentUsageBarProps) =>
        currentUsage > 50
          ? theme.palette.success.main
          : theme.palette.warning.main,
      borderRadius: theme.spacing(0.375),
      height: theme.spacing(0.75),
      left: 0,
      top: 0,
      opacity: 1,
      position: "absolute",
      width: ({currentUsage}: CurrentUsageBarProps) =>
        `${theme.spacing(10) * Math.min(currentUsage / 100.0, 1)}px`,
      zIndex: 1,
    },
  })
)

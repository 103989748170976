/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import React from "react"
import clsx from "clsx"
import {
  createStyles,
  List,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import {Link} from "react-router-dom"
import {COLORS} from "../../styles/theme"
const {PUBLIC_URL} = process.env

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    currentPage: {
      borderBottom: `4px solid ${COLORS.aqua}`,
    },
    linkText: {
      fontWeight: 500,
    },
    list: {
      display: "flex",
    },
    listItem: {
      padding: theme.spacing(4, 8),
      "&.Mui-disabled": {
        opacity: 1,
      },
    },
  })
)

interface LinkItemProps {
  href: string
  isCurrentPage: boolean
  text: string
}

const LinkItem: React.FC<LinkItemProps> = ({href, isCurrentPage, text}) => {
  const classes = useStyles()

  return (
    <ListItem
      button
      classes={{
        root: clsx(classes.listItem, {[classes.currentPage]: isCurrentPage}),
      }}
      component={Link}
      disabled={isCurrentPage}
      to={href}
    >
      <Typography
        className={classes.linkText}
        color={isCurrentPage ? "textPrimary" : "textSecondary"}
        variant="subtitle1"
      >
        {text}
      </Typography>
    </ListItem>
  )
}

export default function CarParkHeader({
  carParkId,
  currentPage,
}: {
  carParkId: string
  currentPage: string
}) {
  const classes = useStyles()

  return (
    <div
      css={css`
        align-items: flex-start;
        background-color: ${COLORS.secondaryLight};
        display: flex;
        @media screen and (min-width: 1400px) {
          margin: 0 -1000px;
          padding: 0 1000px;
        }
      `}
    >
      <List classes={{root: classes.list}} disablePadding>
        <LinkItem
          href={`${PUBLIC_URL}/carpark/${carParkId}`}
          isCurrentPage={currentPage === "overview"}
          text="Overview"
        />
        <LinkItem
          href={`${PUBLIC_URL}/carpark/${carParkId}/vehicles`}
          isCurrentPage={currentPage === "vehicles"}
          text="Vehicles"
        />

        <LinkItem
          href={`${PUBLIC_URL}/carpark/${carParkId}/revenue`}
          isCurrentPage={currentPage === "revenue"}
          text="Revenue"
        />
        <LinkItem
          href={`${PUBLIC_URL}/carpark/${carParkId}/sustainability`}
          isCurrentPage={currentPage === "sustainability"}
          text="Sustainability"
        />
      </List>
    </div>
  )
}

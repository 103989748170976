import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import memoize from "fast-memoize"

const FONT_LIGHT = 300
const FONT_NORMAL = 400
const FONT_MEDIUM = 500
const FONT_BOLD = 700

export const COLORS = {
  primary: "#060B38",
  secondary: "#FFFFFF",
  secondaryLight: "#F3F4F8",
  info: "#6BDDE2",
  infoLight: "#F1F1F1",
  error: "#C81162",
  border: "#D8D8D8",
  purple: "#6665DD",
  blue: "#225EE8",
  yellow: "#FEAF01",
  aqua: "#4ED9DE",
}

const createTheme = memoize(createMuiTheme)
const theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.secondary,
      light: COLORS.secondaryLight,
    },
    info: {
      main: COLORS.info,
      light: COLORS.infoLight,
    },
    error: {
      main: COLORS.error,
    },
    border: {
      main: COLORS.border,
    },
    text: {
      primary: COLORS.primary,
      secondary: "#86889E",
    },
    warning: {
      main: "#F0835C",
    },
    success: {
      main: "#41D18F",
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: [
      "Inter",
      "Playfair Display",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "4rem",
      lineHeight: "8.4rem",
      fontStyle: "normal",
      fontWeight: FONT_BOLD,
    },
    h2: {
      fontSize: "3rem",
      lineHeight: "3rem",
      fontStyle: "normal",
      fontWeight: FONT_BOLD,
    },
    h3: {
      fontSize: "2.4rem",
      fontStyle: "normal",
      fontWeight: FONT_BOLD,
    },
    h4: {
      fontSize: "1.8rem",
      lineHeight: "2rem",
      fontStyle: "normal",
      fontWeight: FONT_MEDIUM,
    },
    h5: {
      fontSize: "1.8rem",
      fontStyle: "normal",
      fontWeight: FONT_BOLD,
    },
    h6: {
      fontSize: "1.5rem",
      fontStyle: "normal",
      fontWeight: FONT_BOLD,
    },
    subtitle1: {
      fontSize: "1.6rem",
      lineHeight: "2.6rem",
      fontStyle: "normal",
      fontWeight: FONT_NORMAL,
    },
    subtitle2: {
      fontSize: "1.8rem",
      fontStyle: "normal",
      fontWeight: FONT_MEDIUM,
    },
    body1: {
      fontSize: "1.8rem",
      lineHeight: "3rem",
      fontStyle: "normal",
      fontWeight: FONT_LIGHT,
    },
    body2: {
      fontSize: "1.4rem",
      fontStyle: "normal",
      fontWeight: FONT_MEDIUM,
    },
    overline: {
      fontSize: "1.5rem",
      fontWeight: FONT_LIGHT,
      fontStyle: "normal",
      textTransform: "unset",
      lineHeight: "normal",
    },
    caption: {
      fontSize: "1.3rem",
      fontStyle: "normal",
      fontWeight: FONT_LIGHT,
    },
    button: {
      textTransform: "none",
    },
    fontWeightLight: FONT_LIGHT,
    fontWeightMedium: FONT_MEDIUM,
    fontWeightBold: FONT_BOLD,
  },
})

export default theme

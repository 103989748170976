import React from "react"

export const Loader = () => (
  <div className="center-page lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

import React, {useEffect, useRef} from "react"
import styled from "styled-components"
import useComponentSize from "@rehooks/component-size"

import draw from "./draw"
import {RichTooltip} from ".."

const Container = styled.div`
  flex-grow: 1;
`

const FunnelChart = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

const FunnelViz: React.FC<{
  data: any[]
}> = ({data}) => {
  const containerRef = useRef(null)
  const funnelRef = useRef(null)
  const tooltipRef = useRef(null)
  const {width} = useComponentSize(containerRef)

  useEffect(() => {
    // let firstTimeVisitors = 0
    // let returningVisitors = 0
    // let frequentVisitors = 0
    // let superVisitors = 0

    // occupancy.forEach((car) => {
    //   const appearances = (car.pastAppearances || []).filter(
    //     (c) => c.carParkId === carParkId
    //   ).length
    //   if (appearances === 0) return firstTimeVisitors++
    //   if (appearances < 5) return returningVisitors++
    //   if (appearances < 20) return frequentVisitors++
    //   superVisitors++
    // })

    // const total =
    //   firstTimeVisitors + returningVisitors + frequentVisitors + superVisitors
    // firstTimeVisitors = Math.floor((firstTimeVisitors / total) * 100)
    // returningVisitors = Math.floor((returningVisitors / total) * 100)
    // frequentVisitors = Math.floor((frequentVisitors / total) * 100)
    // superVisitors = Math.floor((superVisitors / total) * 100)

    // const options = {
    //   width: width - 20,
    //   height: 340,
    //   bottomWidth: 1 / 2,
    //   bottomPinch: 0,
    //   isCurved: false,
    //   curveHeight: 0,
    //   fillType: "solid",
    //   isInverted: false,
    //   hoverEffects: false,
    //   fontSize: "18px",
    // }

    //const funnel = new D3Funnel(data, options)

    draw(data, funnelRef.current as any, {
      componentHeight: 340,
      componentWidth: width,
      tooltipRef,
    })
    //funnel.draw(funnelRef.current as any)
  }, [data, width])

  return (
    <FunnelChart>
      <Container ref={containerRef}>
        <RichTooltip ref={tooltipRef} />
        <div ref={funnelRef}></div>
      </Container>
    </FunnelChart>
  )
}

export default FunnelViz

export const mockAirIndexData = {
  today: [
    {hour: 6, value: 25},
    {hour: 7, value: 100},
    {hour: 8, value: 130},
    {hour: 9, value: 160},
    {hour: 10, value: 105},
    {hour: 11, value: 140},
    {hour: 12, value: 175},
    {hour: 13, value: 250},
    {hour: 14, value: 200},
    {hour: 15, value: 75},
    {hour: 16, value: 100},
    {hour: 17, value: 25},
  ],
  lastWeek: [
    {hour: 6, value: 30},
    {hour: 7, value: 100},
    {hour: 8, value: 110},
    {hour: 9, value: 120},
    {hour: 10, value: 100},
    {hour: 11, value: 100},
    {hour: 12, value: 100},
    {hour: 13, value: 250},
    {hour: 14, value: 350},
    {hour: 15, value: 150},
    {hour: 16, value: 100},
    {hour: 17, value: 15},
    {hour: 18, value: 10},
    {hour: 19, value: 20},
    {hour: 20, value: 10},
    {hour: 21, value: 12},
    {hour: 22, value: 0},
    {hour: 23, value: 0},
  ],
  lastMonth: [
    {hour: 6, value: 20},
    {hour: 7, value: 150},
    {hour: 8, value: 120},
    {hour: 9, value: 120},
    {hour: 10, value: 150},
    {hour: 11, value: 150},
    {hour: 12, value: 150},
    {hour: 13, value: 200},
    {hour: 14, value: 300},
    {hour: 15, value: 100},
    {hour: 16, value: 100},
    {hour: 17, value: 25},
    {hour: 18, value: 50},
    {hour: 19, value: 50},
    {hour: 20, value: 50},
    {hour: 21, value: 75},
    {hour: 22, value: 0},
    {hour: 23, value: 0},
  ]
}

export const toxicEmissionsData = {
  children: [
    {
      label: "Euro 1",
      value: 60,
    },
    {
      label: "Euro 2",
      value: 100,
    },
    {
      label: "Euro 3",
      value: 80,
    },
    {
      label: "Euro 4",
      value: 45,
    },
  ],
  label: "",
  value: 0,
}

export const vehicleFuelTypeData = {
  children: [
    {
      label: "Petrol",
      value: 60,
    },
    {
      label: "Diesel",
      value: 42,
    },
    {
      label: "Hybrid",
      value: 44,
    },
  ],
  label: "",
  value: 0,
}

export const vehicleTypeData = {
  children: [
    {
      label: "Car",
      value: 60,
    },
    {
      label: "LCV",
      value: 43,
    },
  ],
  label: "",
  value: 0,
}

export const vehicleAgeData = [
  {
    label: "0-5",
    value: 500,
  },
  {
    label: "5-10",
    value: 400,
  },
  {
    label: "10-15",
    value: 300,
  },
  {
    label: "15-20",
    value: 200,
  },
  {
    label: "20+",
    value: 180,
  },
]

export const engineSizeData = [
  {
    label: "1.2",
    value: 500,
  },
  {
    label: "1.3",
    value: 350,
  },
  {
    label: "1.4",
    value: 70,
  },
  {
    label: "1.6",
    value: 50,
  },
  {
    label: "1.8",
    value: 100,
  },
  {
    label: "2.4",
    value: 220,
  },
  {
    label: "2.8",
    value: 100,
  },
  {
    label: "3.0",
    value: 150,
  },
  {
    label: "3.2",
    value: 30,
  },
]

export const colourData = [
  {label: "Blue", value: 1200},
  {label: "Black", value: 1350},
  {label: "Red", value: 1200},
  {label: "Silver", value: 1600},
  {label: "White", value: 1450},
  {label: "Green", value: 850},
  {label: "Grey", value: 1450},
  {label: "Other", value: 850},
]

export const emissionsSaved = [
  {hourOfDay: 9, label: "9:00", today: 28, lastMonth: 25},
  {hourOfDay: 10, label: "10:00", today: 38, lastMonth: 40},
  {hourOfDay: 11, label: "11:00", today: 26, lastMonth: 22},
  {hourOfDay: 12, label: "12:00", today: 12, lastMonth: 14},
  {hourOfDay: 13, label: "13:00", today: 42, lastMonth: 35},
  {hourOfDay: 14, label: "14:00", today: 67, lastMonth: 49},
  {hourOfDay: 15, label: "15:00", today: 30, lastMonth: 24},
  {hourOfDay: 16, label: "16:00", today: 28, lastMonth: 34},
]

export const frequencyOfVisits = [
  {
    visitorType: "First-time",
    total: 1500,
    Motorcycle: 100,
    Car: 200,
    HCV: 300,
    LCV: 400,
    PSV: 500,
  },
  {
    visitorType: "Returning",
    total: 1000,
    Motorcycle: 200,
    Car: 300,
    HCV: 300,
    LCV: 100,
    PSV: 100,
  },
  {
    visitorType: "Frequent",
    total: 750,
    Motorcycle: 100,
    Car: 450,
    HCV: 100,
    LCV: 50,
    PSV: 50,
  },
  {
    visitorType: "Super",
    total: 250,
    Motorcycle: 0,
    Car: 150,
    HCV: 50,
    LCV: 50,
    PSV: 0,
  },
]

export const paymentMethodsData = {
  children: [
    {
      label: "App",
      value: 45,
    },
    {
      label: "Pay machines",
      value: 100,
    },
    {
      label: "Prepaid",
      value: 60,
    },
  ],
  label: "",
  value: 0,
}

export const paymentTypesData = [
  {
    label: "Contactless",
    value: 1967,
  },
  {
    label: "Card",
    value: 241,
  },
  {
    label: "Cash",
    value: 192,
  },
]

import React, {useImperativeHandle, useState} from "react"

import {numberWithCommas} from "../../../../utils/functions"
import {
  ColorButton,
  TooltipContainer,
  TooltipContent,
  TooltipRef,
  TooltipValue,
} from "./Tooltip.styles"
import TooltipIcon from "../../../TooltipIcon/TooltipIcon"
import {SupportedTooltipIcons} from "../../types"

interface TooltipProps {
  currency?: boolean
  height?: number
  width?: number
  icon?: SupportedTooltipIcons
}

interface TooltipState {
  shouldShow: boolean
  value: any
  x: number
  y: number
}

const Tooltip: React.FC<TooltipProps & {baseRef: any}> = ({
  width,
  height,
  currency,
  baseRef,
  icon,
}) => {
  const [tooltipData, setTooltipData] = useState<TooltipState>({
    shouldShow: false,
    value: 0,
    x: 0,
    y: 0,
  })

  useImperativeHandle(baseRef, () => ({
    setData: (data: Partial<TooltipState>) =>
      setTooltipData((prevState: TooltipState) => ({...prevState, ...data})),
  }))

  if (!tooltipData.shouldShow) return null

  const tipHeight = height || 40
  const tipWidth = width || 90

  const alignLeft = true
  const alignRight = false
  const alignMiddle = !alignLeft && !alignRight
  const alignBottom = false

  const borderRadius = 4

  const xPosition = alignMiddle
    ? tooltipData.x - tipWidth / 2
    : alignLeft
    ? tooltipData.x - tipWidth
    : tooltipData.x

  return (
    <TooltipContainer>
      <TooltipRef
        ref={baseRef}
        height={tipHeight + borderRadius * 2}
        left={xPosition + tipWidth / 2 + 15}
        top={alignBottom ? tooltipData.y : tooltipData.y - tipHeight - 7}
        width={tipWidth}
      >
        <TooltipContent height={tipHeight} width={tipWidth}>
          <ColorButton aria-label="value">
            <TooltipIcon icon={icon} />
          </ColorButton>
          <TooltipValue maxWidth={tipWidth - 40}>
            {typeof tooltipData.value !== "number"
              ? tooltipData.value
              : currency
              ? `£${numberWithCommas(tooltipData.value, 0)}`
              : numberWithCommas(tooltipData.value, 0)}
          </TooltipValue>
        </TooltipContent>
      </TooltipRef>
    </TooltipContainer>
  )
}

const ForwardRefTooltip = React.forwardRef((props: TooltipProps, ref) => (
  <Tooltip baseRef={ref} {...props} />
))

export default ForwardRefTooltip

import React from "react"
import {Box, Typography} from "@material-ui/core"
import {
  ArrowForwardIos,
  DirectionsCar,
  LocationOn,
  WifiTethering,
} from "@material-ui/icons"

import useStyles from "./MapPopup.styles"
import theme from "../../styles/theme"
import CurrentUsageBar from "../CurrentUsageBar/CurrentUsageBar"
import {
  generateIntervalString,
  getTimeIconColour,
} from "../../utils/mapStatistics"

export interface MapPopupProps {
  image?: string
  address: string
  capacity: number
  currentUsage: number
  imageUrl?: string
  lastUpdatedAt: string
  name: string
}

const MapPopup: React.FC<MapPopupProps> = ({
  address,
  capacity,
  currentUsage,
  image,
  lastUpdatedAt,
  name,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box
        className={classes.image}
        style={{
          backgroundColor: "#e9e9e9",
          border: "1px solid #bbb",
          backgroundImage: `url("${image}")`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box className={classes.content}>
        <Typography color="textPrimary" variant="h6">
          {name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          <LocationOn className={classes.inlineIcon} fontSize="small" />
          {address}
        </Typography>
      </Box>
      <Box className={classes.statistics}>
        <Box className={classes.statItem}>
          <Typography color="textSecondary" variant="caption">
            {generateIntervalString(lastUpdatedAt)}
          </Typography>
          <WifiTethering
            className={classes.icon}
            fontSize="small"
            htmlColor={getTimeIconColour(lastUpdatedAt)}
          />
        </Box>
        <Box className={classes.statItem}>
          <Typography color="textSecondary" variant="caption">
            {capacity}
          </Typography>
          <DirectionsCar
            className={classes.icon}
            fontSize="small"
            htmlColor={theme.palette.text.secondary}
          />
        </Box>
        <Box className={classes.statItem}>
          <Typography color="textSecondary" variant="caption">{`${Math.round(
            currentUsage
          )}%`}</Typography>
          <CurrentUsageBar
            className={classes.icon}
            currentUsage={currentUsage}
          />
        </Box>
      </Box>
      <Box className={classes.arrow}>
        <ArrowForwardIos
          fontSize="small"
          htmlColor={theme.palette.text.secondary}
        />
      </Box>
    </Box>
  )
}

export default MapPopup

import {createStyles, makeStyles, Theme} from "@material-ui/core"

export default makeStyles((theme: Theme) =>
  createStyles({
    listContainer: {
      borderRadius: 0,
      borderTop: `1px solid ${theme.palette.border.main}`,
      boxSizing: "border-box",
      maxHeight: "100%",
      overflowY: "auto",
    },
    listItem: {
      padding: 0,
    },
  })
)

import * as d3 from "d3"
import moment from "moment"

const draw = (rawData, selector, options) => {
  d3.select(selector).selectAll("svg").remove()

  const margin = {top: 0, right: 80, bottom: 40, left: 80}

  const width = options.componentWidth - margin.left - margin.right
  const height = options.componentHeight - margin.top - margin.bottom
  const y = d3.scaleBand().rangeRound([0, height]).padding(0.3)
  const x = d3.scaleLinear().rangeRound([0, width])
  const color = d3
    .scaleOrdinal()
    .range(["#BDBCEC", "#85A0EB", "#5278E4", "#335DE0", "#1F3989"])
    .domain(["Motorcycle", "Car", "LCV", "HCV", "PSV"])
  const svg = d3
    .select(selector)
    .append("svg")
    .attr("width", options.componentWidth)
    .attr("height", options.componentHeight)
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`)
  const rowMax = rawData[0].total

  const data = rawData.map(function (d) {
    const scale = d.total / rowMax
    var x0 = 0

    return {
      visitorType: d.visitorType,
      total: d.total,
      Motorcycle: ((d.Motorcycle * 100) / d.total) * scale,
      Car: ((d.Car * 100) / d.total) * scale,
      HCV: ((d.HCV * 100) / d.total) * scale,
      LCV: ((d.LCV * 100) / d.total) * scale,
      PSV: ((d.PSV * 100) / d.total) * scale,
      boxes: color.domain().map((name) => {
        return {
          name,
          value: d[name],
          x0,
          x1: (x0 += d[name]),
        }
      }),
    }
  })

  const maxVal = d3.max(data, (d) => {
    return d.boxes[4].x1
  })

  x.domain([0, maxVal]).nice()
  y.domain(
    data.map(function (d) {
      return d.visitorType
    })
  )

  const vakken = svg
    .selectAll(".question")
    .data(data)
    .enter()
    .append("g")
    .attr("class", "bar")
    .attr("transform", function (d) {
      const yw = (width / rowMax) * d.total
      const os = (width - yw) / 2
      return `translate(${os},${y(d.visitorType)})`
    })

  const bars = vakken
    .selectAll("rect")
    .data(function (d) {
      return d.boxes
    })
    .enter()
    .append("g")

  bars
    .append("rect")
    .attr("height", y.bandwidth())
    .attr("x", function (d) {
      return x(d.x0)
    })
    .attr("width", function (d) {
      return x(d.x1) - x(d.x0)
    })
    .style("fill", function (d) {
      return color(d.name)
    })
    .on("mouseenter", (d, i, el) => {
      if (!options || !options.tooltipRef) return

      const containerXY = svg.node().getBoundingClientRect()
      const rectXY = el[i].getBoundingClientRect()

      options.tooltipRef.current.setData({
        shouldShow: true,
        value: {
          rows: [
            {text: moment(new Date()).format("MMM, D YYYY"), icon: "date"},
            {text: `${d.value} ${d.name} Vehicles`, icon: "car"},
          ],
          trend: {
            adjustment: 5,
            since: "last week",
          },
        },
        x: rectXY.x - containerXY.x + rectXY.width / 2 + 4,
        y: rectXY.y - containerXY.y + 40,
      })
    })
    .on("mouseleave", (d, i) => {
      if (!options || !options.tooltipRef) return
      options.tooltipRef.current.setData({shouldShow: false})
    })

  vakken
    .append("text")
    .attr("class", "label")
    .text(function (d) {
      return d.visitorType
    })
    .attr("transform", () => {
      return `translate(-60, ${y.bandwidth() / 2 - 5})`
    })

  vakken
    .append("text")
    .attr("class", "label")
    .html(function (d) {
      const extra = {
        "First-time": "visits",
        Returning: "(2-5 visits)",
        Frequent: "(5-20 visits)",
        Super: "(20+ visits)",
      }
      return extra[d.visitorType]
    })
    .attr("transform", () => {
      return `translate(-60, ${y.bandwidth() / 2 + 15})`
    })

  vakken
    .append("text")
    .attr("class", "label")
    .text(function (d) {
      return d3.format(",.2r")(d.total)
    })
    .attr("transform", (d) => {
      const yw = (width / rowMax) * d.total
      const os = width - yw

      return `translate(${width - os + 5}, ${y.bandwidth() / 2 + 5})`
    })

  var startp = svg
    .append("g")
    .attr("class", "legendbox")
    .attr("id", "mylegendbox")

  var legend = startp
    .selectAll(".legend")
    .data(color.domain().slice())
    .enter()
    .append("g")
    .attr("class", "legend")
    .attr("transform", function (d, i) {
      return "translate(" + i * 100 + ",-45)"
    })

  legend
    .append("circle")
    .attr("cx", 0)
    .attr("cy", 10)
    .attr("r", 7)
    .style("fill", color)

  legend
    .append("text")
    .attr("x", 22)
    .attr("y", 9)
    .attr("dy", ".35em")
    .attr("class", "label")
    .style("text-anchor", "begin")
    .text((d) => d)

  d3.select(selector)
    .selectAll(".legendbox")
    .attr("transform", `translate(0,${height + 50})`)
}

export default draw

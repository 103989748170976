import * as d3 from "d3"
import {ChartWithAxesOptions} from "../../../../components/graphs/types"
import {getMargins} from "../../../../components/graphs/utils"
import {DateGroupedOccupancyValues} from "./types"
import theme, {COLORS} from "../../../../styles/theme"
import moment from "moment"

d3.selection.prototype.moveToFront = function () {
  return this.each(function () {
    this.parentNode.appendChild(this)
  })
}

const draw = (
  selection,
  data: DateGroupedOccupancyValues,
  container,
  options: ChartWithAxesOptions
) => {
  const currentHour = moment().format("HH:00")
  const margins = getMargins(options)
  const width = container.width - margins.left - margins.right
  const height = container.height - margins.top - margins.bottom - 30

  const xDomain: string[] = data.today.map((d) => d.hour)
  const yDomain: [number, number] = d3.extent([
    ...data.today.map((d) => d.occupancy),
    ...data.lastWeek.map((d) => d.occupancy),
    ...data.lastMonth.map((d) => d.occupancy),
  ])

  const x = d3
    .scaleBand()
    .domain(xDomain)
    .range([margins.left, margins.left + width])
    .paddingInner(0.2)

  const y = d3
    .scaleLinear()
    .domain(yDomain)
    .range([height, margins.bottom])
    .nice()

  const xAxis = d3
    .axisBottom(x)
    .tickSize(0)
    .tickPadding((options && options.xAxisTickPadding) || 13)

  const yAxis = d3
    .axisLeft(y)
    .tickSize(0)
    .tickPadding((options && options.yAxisTickPadding) || 17)

  const yAxisGrid = d3
    .axisLeft(y)
    .tickFormat(() => "")
    .tickSize(-width)

  selection.selectAll("g.axis").remove()
  selection.selectAll("g.axis-grid").remove()
  selection.selectAll("text.x-label").remove()
  selection.selectAll("text.y-label").remove()

  // x axis
  selection
    .append("g")
    .style("font", `12px ${theme.typography.fontFamily}`)
    .style("color", theme.palette.text.secondary)
    .attr("class", "x axis")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis)

  // y axis
  selection
    .append("g")
    .style("font", `12px ${theme.typography.fontFamily}`)
    .style("color", theme.palette.text.secondary)
    .attr("class", "y axis")
    .attr("transform", "translate(" + margins.left + ",0)")
    .call(yAxis)

  // last week lines
  selection
    .append("path")
    .datum(data.lastWeek)
    .attr("fill", "none")
    .attr("stroke", "#CCCCCC")
    .attr("stroke-width", 2)
    .attr(
      "d",
      d3
        .line()
        .x((d: any) => x(d.hour))
        .y((d: any) => y(d.occupancy))
        .curve(d3.curveMonotoneX)
    )

  // lst month lines
  selection
    .append("path")
    .datum(data.lastMonth)
    .attr("fill", "none")
    .attr("stroke", "#86889e")
    .attr("stroke-width", 1.5)
    .attr(
      "d",
      d3
        .line()
        .x((d: any) => x(d.hour))
        .y((d: any) => y(d.occupancy))
        .curve(d3.curveMonotoneX)
    )

  // append the rectangles for the bar chart
  selection
    .selectAll(".bar")
    .data(data.today)
    .enter()
    .append("rect")
    .attr("class", "bar")
    .attr("fill", (d) => (d.hour === currentHour ? COLORS.aqua : COLORS.blue))
    .attr("x", (d) => x(d.hour))
    .attr("rx", 0)
    .attr("width", x.bandwidth())
    .attr("y", (d) => y(d.occupancy))
    .attr("height", (d) => height - y(d.occupancy))
    .on("mouseover", (d, i, el) => {
      if (!options || !options.tooltipRef) return

      const containerXY = selection.node().getBoundingClientRect()
      const rectXY = (el[i] as Element).getBoundingClientRect()

      options.tooltipRef.current.setData({
        shouldShow: true,
        value: {
          rows: [
            {text: moment(new Date()).format("MMM, D YYYY"), icon: "date"},
            {text: moment(d.hour, "H").format("hA"), icon: "time"},
            {text: `${d.occupancy} Vehicles`, icon: "car"},
          ],
          trend: {
            adjustment: 5,
            since: "last week",
          },
        },
        x: rectXY.x - containerXY.x + 8,
        y: rectXY.y - containerXY.y + 20,
      })
    })
    .on("mouseout", () => {
      if (!options || !options.tooltipRef) return
      options.tooltipRef.current.setData({shouldShow: false})
    })

  selection.selectAll(".bar").moveToFront()

  // y axis grid lines
  selection
    .append("g")
    .style("color", theme.palette.border.main)
    .lower() // render the grid first so that everything else is painted above
    .attr("class", "y axis-grid")
    .attr("transform", "translate(" + margins.left + ",0)")
    .call(yAxisGrid)

  selection.selectAll(".domain").style("stroke", theme.palette.border.main)

  selection
    .append("text")
    .attr("class", "x-label")
    .attr("text-anchor", "middle")
    .attr("x", width / 2 + margins.left)
    .attr("y", height + margins.bottom)
    .text(options.xAxisLabel)
    .style("font", `12px ${theme.typography.fontFamily}`)
    .style("color", theme.palette.text.secondary)

  selection
    .append("text")
    .attr("class", "y-label")
    .attr("text-anchor", "middle")
    .attr("y", margins.left - 45)
    .attr("x", -height / 2)
    .attr("transform", "rotate(-90)")
    .text(options.yAxisLabel)
    .style("font", `12px ${theme.typography.fontFamily}`)
    .style("color", theme.palette.text.secondary)

  // LEGEND
  const legend = selection.append("g")
  const legendY = height + margins.bottom + margins.top
  legend
    .append("circle")
    .attr("cx", margins.left)
    .attr("cy", legendY)
    .attr("r", 6)
    .style("fill", COLORS.blue)
  legend
    .append("text")
    .attr("x", margins.left + 20)
    .attr("y", legendY)
    .text("Today")
    .style("font", `12px ${theme.typography.fontFamily}`)
    .style("color", theme.palette.text.secondary)
    .attr("alignment-baseline", "middle")

  legend
    .append("circle")
    .attr("cx", margins.left + 100)
    .attr("cy", legendY)
    .attr("r", 6)
    .style("fill", COLORS.aqua)
  legend
    .append("text")
    .attr("x", margins.left + 120)
    .attr("y", legendY)
    .text("Now")
    .style("font", `12px ${theme.typography.fontFamily}`)
    .style("color", theme.palette.text.secondary)
    .attr("alignment-baseline", "middle")

  legend
    .append("rect")
    .attr("x", margins.left + 200)
    .attr("y", legendY - 2)
    .attr("width", 15)
    .attr("height", 2)
    .style("fill", "#CCCCCC")
  legend
    .append("text")
    .attr("x", margins.left + 220)
    .attr("y", legendY)
    .text("Last Week")
    .style("font", `12px ${theme.typography.fontFamily}`)
    .style("color", theme.palette.text.secondary)
    .attr("alignment-baseline", "middle")

  legend
    .append("rect")
    .attr("x", margins.left + 300)
    .attr("y", legendY - 2)
    .attr("width", 15)
    .attr("height", 2)
    .style("fill", "#86889e")
  legend
    .append("text")
    .attr("x", margins.left + 320)
    .attr("y", legendY)
    .text("Last Month")
    .style("font", `12px ${theme.typography.fontFamily}`)
    .style("color", theme.palette.text.secondary)
    .attr("alignment-baseline", "middle")
}

export default draw

import React, {useState, useEffect} from "react"
import {Box, Typography} from "@material-ui/core"
import {WifiTethering} from "@material-ui/icons"
import styled from "styled-components"

import {
  generateIntervalString,
  getTimeIconColour,
} from "../../utils/mapStatistics"
import theme from "../../styles/theme"

export interface LiveDataBadgeProps {
  lastUpdatedAt: string
}

export interface LiveDataBadgeState {
  interval: string
  iconColour: string
}

export const LiveIcon = styled(WifiTethering)`
  margin-left: ${theme.spacing(1)}px;
`

const LiveDataBadge: React.FC<LiveDataBadgeProps> = ({lastUpdatedAt}) => {
  const [state, setState] = useState<LiveDataBadgeState>({
    interval: generateIntervalString(lastUpdatedAt),
    iconColour: getTimeIconColour(lastUpdatedAt),
  })

  // age data unless it is updated
  useEffect(() => {
    const interval = setInterval(() => {
      setState({
        interval: generateIntervalString(lastUpdatedAt),
        iconColour: getTimeIconColour(lastUpdatedAt),
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [lastUpdatedAt])

  return (
    <Box alignItems="center" display="flex">
      <Typography color="textSecondary" variant="caption">
        {state.interval}
      </Typography>
      <LiveIcon fontSize="small" htmlColor={state.iconColour} />
    </Box>
  )
}

export default LiveDataBadge

import React from "react"
import D3Wrapper from "../../../../components/graphs/common/D3Wrapper"
import draw from "./draw"
import {AirIndexGraphProps} from "./types"

const AirIndexGraph: React.FC<AirIndexGraphProps> = ({
  airIndex,
  tooltipRef,
}) => {
  // converts raw occupancy entries into projected data for today, last week and last month
  return (
    <D3Wrapper
      draw={draw}
      data={airIndex}
      options={{
        margin: {top: 20, right: 20, bottom: 50, left: 60},
        tooltipRef,
        tooltipIcon: "time",
        xAxisLabel: "Time",
        yAxisLabel: "NOxmg/km",
      }}
    />
  )
}

export default AirIndexGraph

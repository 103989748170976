import React from "react"
import {
  Toolbar,
  InputBase,
  Button,
  Menu,
  MenuItem,
  Avatar,
  Hidden,
  Box,
} from "@material-ui/core"
import {
  Tune,
  CloudDownload,
  MenuOutlined,
  Search,
  ExpandMore,
  FormatAlignLeft,
} from "@material-ui/icons"

import {
  StyledAppBar,
  SearchBox,
  ProfileDropdownContainer,
  RightAlign,
  Username,
} from "./styles"
import DropdownMenuButton from "../DropdownMenuButton/DropdownMenuButton"

const PROFILE_MENU = [
  {
    text: "Profile",
    onClick: undefined,
  },
  {
    text: "My Account",
    onClick: undefined,
  },
  {
    text: "Logout",
    // onClick: () => {
    //   Router.push(`/api/auth/signout`)
    // },
    onClick: undefined,
  },
]

function ProfileDropdown({username}: {username: string}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <ProfileDropdownContainer>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar src="https://placehold.it/64x64" />
        <Username variant="body1" color="textPrimary">
          {username}
        </Username>
        <ExpandMore fontSize="small" />
      </Button>
      <Menu
        id="simple-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {PROFILE_MENU.map(({text, onClick}) => (
          <MenuItem key={text} onClick={onClick || handleClose}>
            {text}
          </MenuItem>
        ))}
      </Menu>
    </ProfileDropdownContainer>
  )
}

export interface NavBarProps {
  closedDrawerWidth: number
  drawerOpen: boolean
  drawerWidth: number
  height: number
  username: string
}

const NavBar: React.FC<NavBarProps> = ({
  username,
  closedDrawerWidth,
  drawerWidth,
  drawerOpen,
  height,
}) => {
  const filterDataMenuItems = [
    {
      text: "A filter",
      onClick: () => {},
    },
    {
      text: "Another filter",
      onClick: () => {},
    },
    {
      text: "A third filter",
      onClick: () => {},
    },
  ]
  const exportMenuItems = [
    {
      text: "Download as CSV",
      onClick: () => {},
    },
  ]

  return (
    <StyledAppBar
      color="secondary"
      closedDrawerWidth={closedDrawerWidth}
      drawerWidth={drawerWidth}
      drawerOpen={drawerOpen}
      elevation={0}
      position="static"
      height={height}
    >
      <Toolbar>
        <SearchBox>
          <Box mr={2}>
            <Search />
          </Box>
          <InputBase
            placeholder="Search…"
            inputProps={{"aria-label": "search"}}
          />
        </SearchBox>
        <RightAlign>
          <Hidden smDown>
            <Toolbar>
              <DropdownMenuButton
                text="Filter Data"
                icon={Tune}
                menuItems={filterDataMenuItems}
                hasBorder
              />
              <DropdownMenuButton
                text="Export"
                icon={CloudDownload}
                menuItems={exportMenuItems}
                hasBorder
              />
              <ProfileDropdown username={username} />
              <FormatAlignLeft />
            </Toolbar>
          </Hidden>
          <Hidden mdUp>
            <DropdownMenuButton
              icon={MenuOutlined}
              menuItems={PROFILE_MENU}
              noExpandIcon
            />
          </Hidden>
        </RightAlign>
      </Toolbar>
    </StyledAppBar>
  )
}

export default NavBar

import React, {useState} from "react"
import {Box} from "@material-ui/core"
import midnightFormat from "../../utils/midnightFormat"
import useSWR from "swr"

import Layout from "../Layout"
import MapCard from "../../components/MapCard/MapCard"
import DateSelector from "../../components/DateSelector/DateSelector"
import LocationList from "../../components/LocationList/LocationList"
import OverviewSection from "./OverviewSection"
import theme from "../../styles/theme"
import {Loader} from "../../components/Loader"
const {PUBLIC_URL} = process.env

const MapPage = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())

  let {data: carparkResponse, error: carparkError} = useSWR(
    [`${PUBLIC_URL}/api/carparks/all`, midnightFormat(selectedDate)],
    (url: string, selectedDate) => {
      return fetch(`${url}?date=${selectedDate}`).then((r) => r.json())
    }
  )
  let {data: totalsResponse, error: totalsError} = useSWR(
    [`${PUBLIC_URL}/api/carparks/totals`, midnightFormat(selectedDate)],
    (url: string, selectedDate) => {
      return fetch(`${url}?date=${selectedDate}`).then((r) => r.json())
    }
  )
  if (carparkError || totalsError) return <p>An error occured</p>
  if (!carparkResponse || !totalsResponse) return <Loader />

  const carparks = carparkResponse.data.map((carPark: any) => ({
    image: carPark.image,
    address: carPark.siteName,
    capacity: carPark.capacity,
    carParkId: carPark.carParkId,
    currentUsage: (carPark.currentOccupancy / carPark.capacity) * 100,
    latitude: carPark.location[1],
    longitude: carPark.location[0],
    name: carPark.carParkName,
    lastUpdatedAt: new Date(carPark.lastUpdatedAt).toISOString(),
  }))

  const {totalVisits, totalTime} = totalsResponse.data
  const totals = {
    totalVisits: totalVisits,
    averageStay: totalTime / totalVisits / 3600, // seconds to hours
    revenue: totalTime / 3600,
  }

  return (
    <Layout currentPage="Home">
      <Box
        height="calc(100vh - 100px)" // Layout.header height
        display="grid"
        gridTemplateColumns="minmax(400px, 1fr) minmax(0, 2fr)"
        gridTemplateRows="120px minmax(0, 1fr)"
        gridTemplateAreas='"date stats" "carparks map"'
        justifyItems="stretch"
        alignItems="stretch"
      >
        <Box
          bgcolor="#fef6e5"
          gridArea="carparks"
          maxHeight="100%"
          overflow="auto"
        >
          <LocationList locations={carparks} />
        </Box>
        <Box display="flex" gridArea="stats">
          <OverviewSection totals={totals} />
        </Box>
        <Box
          gridArea="map"
          borderLeft={`1px solid ${theme.palette.border.main}`}
          borderTop={`1px solid ${theme.palette.border.main}`}
        >
          <MapCard
            initialLatitude={51.67}
            initialLongitude={-1.64}
            initialZoom={7}
            locations={carparks}
          />
        </Box>
      </Box>
    </Layout>
  )
}

export default MapPage

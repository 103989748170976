import React, {useRef} from "react"
import moment from "moment"

import LineChart from "../../../../components/graphs/LineChart/LineChart"
import {COLORS} from "../../../../styles/theme"
import revenueData from "./revenueData"
import {RichTooltip} from "../../../../components/graphs"

export default () => {
  const tooltipRef = useRef()

  return (
    <>
      <RichTooltip ref={tooltipRef} />
      <LineChart
        currency
        data={revenueData}
        id="revenue"
        options={{
          legend: [
            {colour: COLORS.blue, text: "Today", type: "dot"},
            {colour: "#CCCCCC", text: "Last week", type: "dot"},
            {colour: "#86889E", text: "Last month", type: "dot"},
          ],
          margin: {top: 20},
          setTooltipValue: (d) => ({
            rows: [
              {text: moment(new Date()).format("MMM, D YYYY"), icon: "date"},
              {text: moment(d.date, "H").format("hA"), icon: "time"},
              {text: `£${d.value[0].value} revenue`, icon: "money"},
            ],
            trend: {
              adjustment: 5,
              since: "last week",
            },
          }),
          tooltipRef,
          xAxisLabel: "Time",
          yAxisLabel: "Pounds (£, 1000s)",
        }}
        tooltipIcon="money"
      />
    </>
  )
}

import * as d3 from "d3"

import theme, {COLORS} from "../../../styles/theme"
import {BarChartOptions, UnfetteredDrawFunction} from "../types"
import {getMargins} from "../utils"

const moveToFront = function (selection) {
  return selection.each(function () {
    this.parentNode.appendChild(this)
  })
}

const drawBarchart: UnfetteredDrawFunction = (
  selection,
  data,
  container,
  options: BarChartOptions
) => {
  selection.selectAll("*").remove()
  const margins = getMargins(options),
    width = container.width - margins.left - margins.right,
    height =
      container.height -
      margins.top -
      margins.bottom -
      (options.showTrendedLegend ? 40 : 0)

  const xDomain = (options.dataTrends ? data.today : data).map((d) => d.label)
  const yDomain = options.dataTrends
    ? d3.extent([
        ...data.today.map((d) => d.value),
        ...data.lastWeek.map((d) => d.value),
        ...data.lastMonth.map((d) => d.value),
      ])
    : [
        Math.min(...data.map((d) => d.value)),
        Math.max(...data.map((d) => d.value)),
      ]

  const getBarColour = (d: any): string => {
    if (!options || !options.shouldHighlightBarFunction) return COLORS.blue
    return options.shouldHighlightBarFunction(d) ? COLORS.aqua : COLORS.blue
  }

  const regularX = d3
    .scaleBand()
    .domain(xDomain)
    .range([margins.left, margins.left + width])
    .paddingInner(0.2)

  const skinnyX = d3
    .scaleBand()
    .domain(xDomain)
    .range([margins.left, margins.left + width])
    .padding(0.7)

  const x = options && options.skinnyBars ? skinnyX : regularX

  const y = d3
    .scaleLinear()
    .domain(yDomain)
    .range([height, margins.bottom])
    .nice()

  const xAxis = d3
    .axisBottom(x)
    .tickSize(0)
    .tickPadding((options && options.xAxisTickPadding) || 13)
  const yAxis = d3
    .axisLeft(y)
    .tickSize(0)
    .tickPadding((options && options.yAxisTickPadding) || 17)
  const yAxisGrid = d3
    .axisLeft(y)
    .tickFormat(() => "")
    .tickSize(-width)

  selection.selectAll("g.axis").remove()
  selection.selectAll("g.axis-grid").remove()
  selection.selectAll("text.x-label").remove()
  selection.selectAll("text.y-label").remove()

  // x axis
  selection
    .append("g")
    .style("font", `12px ${theme.typography.fontFamily}`)
    .style("color", theme.palette.text.secondary)
    .attr("class", "x axis")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis)

  // y axis
  selection
    .append("g")
    .style("font", `12px ${theme.typography.fontFamily}`)
    .style("color", theme.palette.text.secondary)
    .attr("class", "y axis")
    .attr("transform", "translate(" + margins.left + ",0)")
    .call(yAxis)

  // append the rectangles for the bar chart
  selection
    .selectAll(".bar")
    .data(options.dataTrends ? data.today : data)
    .enter()
    .append("rect")
    .attr("class", "bar")
    .attr("fill", (d) => getBarColour(d))
    .attr("x", (d: any) => x(d.label) || NaN)
    .attr("rx", options && options.skinnyBars ? theme.spacing(1) : 0)
    .attr("width", x.bandwidth())
    .attr("y", (d: any) => y(d.value))
    .attr("height", (d: any) => height - y(d.value))
    .on("mouseover", (d: any, i: any, el: any) => {
      if (!options || !options.tooltipRef) return

      const containerXY = selection.node().getBoundingClientRect()
      const rectXY = (el[i] as Element).getBoundingClientRect()

      options.tooltipRef.current.setData({
        shouldShow: true,
        value: {
          rows: [{text: `${d.value} Vehicles`, icon: "car"}],
          trend: {
            adjustment: 5,
            since: "last week",
          },
        },
        x: rectXY.x - containerXY.x - 8,
        y: rectXY.y - containerXY.y + 10,
      })
    })
    .on("mouseout", () => {
      if (!options || !options.tooltipRef) return
      options.tooltipRef.current.setData({shouldShow: false})
    })

  // y axis grid lines
  selection
    .append("g")
    .style("color", theme.palette.border.main)
    .lower() // render the grid first so that everything else is painted above
    .attr("class", "y axis-grid")
    .attr("transform", "translate(" + margins.left + ",0)")
    .call(yAxisGrid)

  selection.selectAll(".domain").style("stroke", theme.palette.border.main)

  options.dataTrends &&
    selection
      .append("path")
      .datum(data.lastWeek)
      .attr("fill", "none")
      .attr("stroke", "#CCCCCC")
      .attr("stroke-width", 2)
      .attr(
        "d",
        d3
          .line()
          .x((d: any) => x(d.label))
          .y((d: any) => y(d.value))
      )

  options.dataTrends &&
    selection
      .append("path")
      .datum(data.lastMonth)
      .attr("fill", "none")
      .attr("stroke", "#86889e")
      .attr("stroke-width", 1.5)
      .attr(
        "d",
        d3
          .line()
          .x((d: any) => x(d.label))
          .y((d: any) => y(d.value))
      )

  options &&
    options.xAxisLabel &&
    selection
      .append("text")
      .attr("class", "x-label")
      .attr("text-anchor", "middle")
      .attr("x", width / 2 + margins.left)
      .attr("y", height + margins.bottom)
      .text(options.xAxisLabel)
      .style("font", `12px ${theme.typography.fontFamily}`)
      .style("color", theme.palette.text.secondary)

  options &&
    options.yAxisLabel &&
    selection
      .append("text")
      .attr("class", "y-label")
      .attr("text-anchor", "middle")
      .attr("y", margins.left - 45)
      .attr("x", -height / 2)
      .attr("transform", "rotate(-90)")
      .text(options.yAxisLabel)
      .style("font", `12px ${theme.typography.fontFamily}`)
      .style("color", theme.palette.text.secondary)

  if (options?.xTicksPerLabel && options?.xTicksPerLabel > 1) {
    d3.selectAll(".x.axis .tick text").each(function (_, i) {
      if (i % options.xTicksPerLabel !== 0) d3.select(this).remove()
    })
  }

  if (options.showTrendedLegend) {
    const legend = selection.append("g")
    const legendY = height + margins.bottom + margins.top
    legend
      .append("circle")
      .attr("cx", margins.left)
      .attr("cy", legendY)
      .attr("r", 6)
      .style("fill", COLORS.blue)
    legend
      .append("text")
      .attr("x", margins.left + 20)
      .attr("y", legendY)
      .text("Today")
      .style("font", `12px ${theme.typography.fontFamily}`)
      .style("color", theme.palette.text.secondary)
      .attr("alignment-baseline", "middle")

    legend
      .append("rect")
      .attr("x", margins.left + 100)
      .attr("y", legendY - 2)
      .attr("width", 15)
      .attr("height", 2)
      .style("fill", "#CCCCCC")
    legend
      .append("text")
      .attr("x", margins.left + 120)
      .attr("y", legendY)
      .text("Last Week")
      .style("font", `12px ${theme.typography.fontFamily}`)
      .style("color", theme.palette.text.secondary)
      .attr("alignment-baseline", "middle")

    legend
      .append("rect")
      .attr("x", margins.left + 200)
      .attr("y", legendY - 2)
      .attr("width", 15)
      .attr("height", 2)
      .style("fill", "#86889e")
    legend
      .append("text")
      .attr("x", margins.left + 220)
      .attr("y", legendY)
      .text("Last Month")
      .style("font", `12px ${theme.typography.fontFamily}`)
      .style("color", theme.palette.text.secondary)
      .attr("alignment-baseline", "middle")
  }

  moveToFront(selection.selectAll(".bar"))
}

export default drawBarchart

import styled from "styled-components"

export const Container = styled.div<{paddingLeft: number}>`
  width: calc(100% - 25px);
  height: 100%;
  padding-left: 10px;
  svg.graph {
    width: 100%;
    height: 100%;
  }
`

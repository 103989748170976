import DriveEtaIcon from "@material-ui/icons/DriveEta"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"
import AvTimerIcon from "@material-ui/icons/AvTimer"
import AssessmentIcon from "@material-ui/icons/Assessment"
import EventIcon from "@material-ui/icons/Event"
import EcoIcon from "@material-ui/icons/Eco"
import React from "react"
import {SupportedTooltipIcons} from "../graphs/types"

interface TooltipIconProps {
  icon?: SupportedTooltipIcons
  compact?: boolean
}

const TooltipIcon: React.FC<TooltipIconProps> = ({icon, compact}) =>
  icon === "car" ? (
    <DriveEtaIcon fontSize={compact ? "small" : "default"} />
  ) : icon === "time" ? (
    <AccessTimeIcon fontSize={compact ? "small" : "default"} />
  ) : icon === "money" ? (
    <AccountBalanceWalletIcon fontSize={compact ? "small" : "default"} />
  ) : icon === "assessment" ? (
    <AssessmentIcon fontSize={compact ? "small" : "default"} />
  ) : icon === "eco" ? (
    <EcoIcon fontSize={compact ? "small" : "default"} />
  ) : icon === "index" ? (
    <AvTimerIcon fontSize={compact ? "small" : "default"} />
  ) : icon === "date" ? (
    <EventIcon fontSize={compact ? "small" : "default"} />
  ) : (
    <DriveEtaIcon fontSize={compact ? "small" : "default"} />
  ) /*this is the fallback, ahould be improved*/

export default TooltipIcon

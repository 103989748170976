import * as d3 from "d3"

import {ChartWithAxesOptions} from "../types"
import theme from "../../../styles/theme"
import moment from "moment"

const drawHeatmap = (
  selection: d3.Selection<SVGElement, any, any, any>,
  data: {hour: Date; value: number}[],
  container: {width: number; height: number},
  options?: ChartWithAxesOptions
) => {
  const margin = {top: 10, right: 20, bottom: 50, left: 70}
  const height = container.height - margin.top - margin.bottom
  const width = container.width - margin.left - margin.right

  const xDates = d3
    .scaleTime()
    .domain([data[0].hour, data.slice(-1)[0].hour])
    .range([margin.left, container.width - margin.right])

  const xAxis = (g: any) =>
    g
      .attr("transform", `translate(0,${height + margin.top})`)
      .style("font", `12px ${theme.typography.fontFamily}`)
      .style("color", theme.palette.text.secondary)
      .call(d3.axisBottom(xDates))

  const yHours = d3
    .scaleTime()
    .domain([new Date(2020, 0, 1), new Date(2020, 0, 0)])
    .range([container.height - margin.bottom, margin.top])

  const yAxis = (g: any) =>
    g
      .attr("transform", `translate(${margin.left},0)`)
      .style("font", `12px ${theme.typography.fontFamily}`)
      .style("color", theme.palette.text.secondary)
      .call(d3.axisLeft(yHours).tickFormat(d3.utcFormat("%I %p") as any))

  const rows = 24
  const columns = 60
  const cellWidth = width / (columns + 1)
  const cellHeight = height / rows
  const maxValue = data
    .map((d) => d.value)
    .reduce((pv, v) => Math.max(pv, v), 0)

  selection
    .selectAll("rect")
    .data(data)
    .join("rect")
    .attr("x", (d, i) => Math.floor(i / rows) * cellWidth + margin.left)
    .attr("y", (d, i) => (i % rows) * cellHeight + margin.top)
    .style("fill", (d, i) => {
      const v = d.value / maxValue
      return `hsl(${40.1 - v * 30},${68.6 + v * 12}%,${94.8 - v * 52}%)`
    })
    .attr("width", cellWidth - 1)
    .attr("height", cellHeight - 1)
    .on("mouseenter", (d, i, el) => {
      if (!options || !options.tooltipRef) return

      const containerXY = selection.node().getBoundingClientRect()
      const rectXY = (el[i] as Element).getBoundingClientRect()

      options.tooltipRef.current.setData({
        shouldShow: true,
        value: {
          rows: [
            {text: moment(d.hour).format("MMM, D YYYY"), icon: "date"},
            {text: moment(d.hour).format("hA"), icon: "time"},
            {text: `${d.value} Vehicles`, icon: "car"},
          ],
          trend: {
            adjustment: 5,
            since: "last week",
          },
        },
        x: rectXY.x - containerXY.x - 8,
        y: rectXY.y - containerXY.y + 30,
      })
    })
    .on("mouseleave", (d, i) => {
      if (!options || !options.tooltipRef) return
      options.tooltipRef.current.setData({shouldShow: false})
    })

  selection.selectAll("g.axis").remove()
  selection.selectAll("g.axis").remove()
  selection.selectAll("text.x-label").remove()
  selection.selectAll("text.y-label").remove()

  selection.append("g").attr("class", "axis").call(xAxis)
  selection.append("g").attr("class", "axis").call(yAxis)

  options &&
    options.xAxisLabel &&
    selection
      .append("text")
      .attr("class", "x-label")
      .attr("text-anchor", "middle")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom)
      .text(options.xAxisLabel)
      .style("font", `12px ${theme.typography.fontFamily}`)
      .style("color", theme.palette.text.secondary)

  options &&
    options.yAxisLabel &&
    selection
      .append("text")
      .attr("class", "y-label")
      .attr("text-anchor", "middle")
      .attr("y", margin.left - 55)
      .attr("x", -height / 2)
      .attr("transform", "rotate(-90)")
      .text(options.yAxisLabel)
      .style("font", `12px ${theme.typography.fontFamily}`)
      .style("color", theme.palette.text.secondary)
}

export default drawHeatmap

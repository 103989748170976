import React from "react"

import D3Wrapper from "../common/D3Wrapper"
import {DoubleDountChartOptions} from "../types"
import draw from "./draw"

export interface DoubleDonutChartProps {
  innerData: any[]
  outerData: any[]
  options: DoubleDountChartOptions
}

const DoubleDonutChart: React.FC<DoubleDonutChartProps> = ({
  innerData,
  outerData,
  options,
}) => {
  const data = {
    inner: innerData,
    outer: outerData,
  }
  return <D3Wrapper draw={draw} data={data} options={options} />
}

export default DoubleDonutChart
